var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.approvalType
    ? _c(
        "div",
        [
          !_vm.failedToLoad
            ? _c(
                "model-loading-overlay",
                { attrs: { model: _vm.model } },
                [
                  _vm.selected
                    ? _c(
                        "b-form-group",
                        {
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "label",
                                fn: function () {
                                  return [
                                    _vm._v("Do you approve this "),
                                    _c("strong", [
                                      _vm._v(_vm._s(_vm.approvalType)),
                                    ]),
                                    _vm._v(" schedule?"),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            269781366
                          ),
                        },
                        [
                          _vm._v(" "),
                          _c("b-form-radio-group", {
                            attrs: {
                              stacked: "",
                              options: _vm.approvalOptions,
                            },
                            on: {
                              change: function ($event) {
                                return _vm.patchSingleField("approved")
                              },
                            },
                            model: {
                              value: _vm.selected.approved,
                              callback: function ($$v) {
                                _vm.$set(_vm.selected, "approved", $$v)
                              },
                              expression: "selected.approved",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.selected
                    ? _c(
                        "b-form-group",
                        {
                          attrs: {
                            label:
                              "If no, what changes would you like to have?",
                          },
                        },
                        [
                          _c("b-textarea", {
                            attrs: { disabled: _vm.selected.approved !== "no" },
                            on: {
                              blur: function ($event) {
                                return _vm.patchSingleField("comments")
                              },
                            },
                            model: {
                              value: _vm.comments,
                              callback: function ($$v) {
                                _vm.comments = $$v
                              },
                              expression: "comments",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("span", { staticClass: "small text-muted" }, [
                    _vm._v("Last edited: " + _vm._s(_vm.lastEdited)),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.failedToLoad
            ? _c("div", [
                _c("span", { staticClass: "text-muted font-italic" }, [
                  _vm._v(_vm._s(_vm.SCHEDULE_APPROVAL_FAIL_TO_LOAD)),
                ]),
              ])
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }