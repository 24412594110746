var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "p0" },
    [
      _c(
        "b-input-group",
        [
          _c(
            "b-input-group-prepend",
            [
              _c(
                "b-input-group-text",
                [_c("b-icon", { attrs: { icon: "search" } })],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("b-form-input", {
            attrs: { type: "text", debounce: "500" },
            on: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.onSearch.apply(null, arguments)
              },
            },
            model: {
              value: _vm.value,
              callback: function ($$v) {
                _vm.value = $$v
              },
              expression: "value",
            },
          }),
          _vm._v(" "),
          _c(
            "b-input-group-append",
            [
              _c(
                "b-button",
                { attrs: { variant: "primary" }, on: { click: _vm.onSearch } },
                [_vm._v("Search")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-button",
        {
          directives: [
            {
              name: "b-toggle",
              rawName: "v-b-toggle.advanced-search",
              modifiers: { "advanced-search": true },
            },
          ],
          staticClass: "mb-2 mt-2",
          attrs: { variant: "primary" },
        },
        [_vm._v("Advanced Search")]
      ),
      _vm._v(" "),
      _c(
        "b-collapse",
        { attrs: { id: "advanced-search" } },
        [
          _c(
            "b-tabs",
            { attrs: { "content-class": "mt-3", fill: "" } },
            [
              _c(
                "b-tab",
                { attrs: { title: "Advanced" } },
                [
                  _c("vue-query-builder", {
                    attrs: { rules: _vm.rules, maxDepth: 2 },
                    model: {
                      value: _vm.query,
                      callback: function ($$v) {
                        _vm.query = $$v
                      },
                      expression: "query",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "b-button",
                    {
                      attrs: { variant: "primary" },
                      on: { click: _vm.onQuerySearch },
                    },
                    [_vm._v("Search")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm._t("alternate-search-tab"),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }