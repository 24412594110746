var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "admin scrollable" }, [
    _c(
      "h1",
      [_vm._v("Admin stuff goes here. "), _c("b-icon-minecart-loaded")],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "accordion", attrs: { role: "tablist" } },
      [
        _c(
          "admin-accordion",
          { attrs: { id: "add-user-accordion", title: "Add User" } },
          [_c("person-add")],
          1
        ),
        _vm._v(" "),
        _c(
          "admin-accordion",
          { attrs: { id: "import-users-accordion", title: "Import Users" } },
          [
            _c("sheet-importer-vue", {
              attrs: {
                title: "Import People",
                "import-url": "/person/import",
                "example-url": "/examples/people_import.xlsx",
              },
              scopedSlots: _vm._u([
                {
                  key: "import-details",
                  fn: function (result) {
                    return [
                      _vm._v(
                        "\n          Imported: " +
                          _vm._s(result.importMessage.imported) +
                          " Skipped " +
                          _vm._s(result.importMessage.skipped)
                      ),
                      _c("br"),
                      _vm._v(
                        "\n          Malformed email: " +
                          _vm._s(result.importMessage.bad_email)
                      ),
                      _c("br"),
                      _vm._v(
                        "\n          Duplicate email: " +
                          _vm._s(result.importMessage.duplicate_email)
                      ),
                      _c("br"),
                      _vm._v(
                        "\n          No name: " +
                          _vm._s(result.importMessage.noname) +
                          "\n        "
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "admin-accordion",
          {
            attrs: {
              id: "edit-roles-accordion",
              title: "Assign Convention Class",
            },
            on: { show: _vm.showPeopleRoles },
          },
          [
            _c("change-user-convention-roles", {
              ref: "role-manager",
              attrs: { model: "person" },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "admin-accordion",
          {
            attrs: {
              id: "import-sessions-accordion",
              title: "Import Sessions",
            },
          },
          [
            _c("sheet-importer-vue", {
              attrs: {
                title: "Import Sessions",
                "import-url": "/session/import",
                "example-url": "/examples/session_import.xlsx",
              },
              scopedSlots: _vm._u([
                {
                  key: "import-details",
                  fn: function (result) {
                    return [
                      _vm._v(
                        "\n          Imported: " +
                          _vm._s(result.importMessage.imported) +
                          " Skipped " +
                          _vm._s(result.importMessage.skipped)
                      ),
                      _c("br"),
                      _vm._v(
                        "\n          No Title: " +
                          _vm._s(result.importMessage.no_title)
                      ),
                      _c("br"),
                      _vm._v(
                        "\n          Duplicate Session: " +
                          _vm._s(result.importMessage.duplicate_session)
                      ),
                      _c("br"),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "admin-accordion",
          {
            attrs: { id: "mailings-accordion", title: "Mailings" },
            on: { show: _vm.showMailings },
          },
          [
            _c("mailings-manager", {
              ref: "mailing-manager",
              attrs: { model: "mailing" },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "admin-accordion",
          {
            attrs: {
              id: "schedule-settings-accordion",
              title: "Schedule Settings",
            },
          },
          [_c("schedule-settings")],
          1
        ),
        _vm._v(" "),
        _c(
          "admin-accordion",
          {
            attrs: { id: "event-settings-accordion", title: "Event Settings" },
            on: { show: _vm.showConfigs },
          },
          [
            _c("configurations-manager", {
              ref: "configurations-manager",
              attrs: { model: "parameter_name" },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "admin-accordion",
          {
            attrs: {
              id: "integration-accordion",
              title: "Integration Settings",
            },
          },
          [_c("integration-settings")],
          1
        ),
        _vm._v(" "),
        _c(
          "admin-accordion",
          {
            attrs: { id: "agreements-accordion", title: "Agreements" },
            on: { show: _vm.showAgreements },
          },
          [
            _c("agreement-manager", {
              ref: "agreements-manager",
              attrs: { model: "agreement" },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }