var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.survey
        ? _c("sidebar-vue", {
            attrs: { model: "survey" },
            scopedSlots: _vm._u(
              [
                {
                  key: "header",
                  fn: function () {
                    return [
                      _c("h3", [_vm._v("Survey Details")]),
                      _vm._v(" "),
                      _c("small", { staticClass: "text-muted d-block" }, [
                        _vm._v("Last updated:"),
                      ]),
                      _vm._v(" "),
                      _c("small", { staticClass: "text-muted d-block" }, [
                        _vm._v(" by "),
                        _c("em", [
                          _c("strong", [
                            _vm._v(
                              _vm._s(
                                _vm.survey.updated_by &&
                                  _vm.survey.updated_by.name
                              )
                            ),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("small", { staticClass: "text-muted d-block" }, [
                        _vm._v(" on "),
                        _c("em", [
                          _c("strong", [
                            _vm._v(
                              _vm._s(
                                new Date(_vm.survey.updated_at).toLocaleString()
                              )
                            ),
                          ]),
                        ]),
                      ]),
                    ]
                  },
                  proxy: true,
                },
                _vm.survey
                  ? {
                      key: "content",
                      fn: function () {
                        return [
                          _c(
                            "b-row",
                            [
                              _c("b-col", [
                                _c("h2", { staticClass: "font-weight-light" }, [
                                  _vm._v(_vm._s(_vm.survey.name)),
                                ]),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-row",
                            [
                              _c("b-col", [
                                _c("p", [
                                  _c("strong", [_vm._v("Short Description:")]),
                                  _vm._v(" " + _vm._s(_vm.survey.description)),
                                ]),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "float-right d-flex justify-content-end",
                            },
                            [
                              _c("icon-button", {
                                attrs: {
                                  title: "Survey Link",
                                  href: _vm.surveyLink,
                                  target: "_blank",
                                  icon: "globe2",
                                },
                              }),
                              _vm._v(" "),
                              _c("icon-button", {
                                attrs: {
                                  title: "Preview Survey",
                                  href: _vm.previewLink,
                                  target: "_blank",
                                  icon: "eye-fill",
                                },
                              }),
                              _vm._v(" "),
                              _c("icon-button", {
                                attrs: {
                                  title: "Edit Survey",
                                  to: _vm.editLink,
                                  disabled: _vm.survey.public,
                                  disabledTooltip: _vm.SURVEY_PUBLIC_NO_EDIT,
                                  icon: "pencil",
                                },
                              }),
                              _vm._v(" "),
                              _c("icon-button", {
                                attrs: {
                                  icon: "envelope",
                                  disabled: "",
                                  disabledTooltip: "Send Survey",
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "b-dropdown",
                                {
                                  staticClass: "ml-1",
                                  attrs: {
                                    variant: "primary",
                                    right: "",
                                    "no-caret": "",
                                    size: "sm",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "button-content",
                                        fn: function () {
                                          return [_c("b-icon-three-dots")]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    3360187004
                                  ),
                                },
                                [
                                  _vm._v(" "),
                                  _c(
                                    "b-dd-item",
                                    { on: { click: _vm.responses } },
                                    [_vm._v("View Responses")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-dd-item",
                                    {
                                      directives: [
                                        {
                                          name: "b-modal",
                                          rawName:
                                            "v-b-modal.confirmClearResponses",
                                          modifiers: {
                                            confirmClearResponses: true,
                                          },
                                        },
                                      ],
                                    },
                                    [_vm._v("Clear Responses")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-dd-item",
                                    {
                                      on: { click: _vm.toggleSubmissionEdits },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.survey.allow_submission_edits
                                            ? "Freeze"
                                            : "Unfreeze"
                                        ) + " Response Edits"
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("b-dd-divider"),
                                  _vm._v(" "),
                                  _c(
                                    "b-dd-item",
                                    { on: { click: _vm.duplicate } },
                                    [_vm._v("Duplicate")]
                                  ),
                                  _vm._v(" "),
                                  _c("b-dd-item", { attrs: { disabled: "" } }, [
                                    _vm._v("Export"),
                                  ]),
                                  _vm._v(" "),
                                  _c("b-dd-item", { attrs: { disabled: "" } }, [
                                    _vm._v("Make Template"),
                                  ]),
                                  _vm._v(" "),
                                  _c("b-dd-item", { attrs: { disabled: "" } }, [
                                    _vm._v("Freeze Survey Edits"),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "b-dd-item",
                                    {
                                      directives: [
                                        {
                                          name: "b-modal",
                                          rawName: "v-b-modal.confirmDelete",
                                          modifiers: { confirmDelete: true },
                                        },
                                        {
                                          name: "b-tooltip",
                                          rawName: "v-b-tooltip.left",
                                          modifiers: { left: true },
                                        },
                                      ],
                                      attrs: {
                                        title: _vm.deleteTitle,
                                        disabled: _vm.survey.public,
                                      },
                                    },
                                    [_vm._v("Delete")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-tabs",
                            {
                              attrs: {
                                "content-class": "mt-3",
                                "nav-class": "border-0",
                                "nav-wrapper-class": "border-bottom",
                              },
                            },
                            [
                              _c(
                                "b-tab",
                                { attrs: { title: "Questions", active: "" } },
                                _vm._l(_vm.questions, function (q) {
                                  return _c("survey-question", {
                                    key: q.id,
                                    attrs: { question: q },
                                  })
                                }),
                                1
                              ),
                              _vm._v(" "),
                              _c("survey-settings-tab"),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    }
                  : null,
              ],
              null,
              true
            ),
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            id: "confirmClearResponses",
            "ok-title": "Yes",
            "cancel-variant": "link",
          },
          on: { ok: _vm.clearResponses },
        },
        [_c("p", [_vm._v(_vm._s(_vm.SURVEY_RESULTS_CLEAR_CONFIRM))])]
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            id: "confirmDelete",
            "ok-title": "Yes",
            "cancel-variant": "link",
            title: "Delete survey?",
          },
          on: { ok: _vm.destroy },
        },
        [_c("p", [_vm._v(_vm._s(_vm.SURVEY_CONFIRM_DELETE))])]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }