var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("table-vue", {
        ref: "mailings-table",
        attrs: {
          defaultSortBy: "updated_at",
          defaultSortDesc: true,
          model: _vm.model,
          columns: _vm.columns,
          defaultFilter: _vm.defaultFilter,
          "show-search": false,
          "show-add": false,
          "show-settings": false,
          "show-refresh": true,
          "show-clone": true,
          "show-view": true,
        },
        on: {
          view: function ($event) {
            return _vm.$emit("view")
          },
          clone: function ($event) {
            return _vm.$emit("clone")
          },
        },
        scopedSlots: _vm._u([
          {
            key: "cell(content)",
            fn: function (ref) {
              var item = ref.item
              return [
                _c("tooltip-overflow", { attrs: { title: item.content } }, [
                  _c("div", { domProps: { innerHTML: _vm._s(item.content) } }),
                ]),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }