var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-select", {
    staticClass: "d-inline next-page",
    attrs: { id: _vm.id, value: _vm.value, options: _vm.nextPageOptions },
    on: {
      input: function ($event) {
        return _vm.$emit("input", $event)
      },
      change: function ($event) {
        return _vm.$emit("change", $event)
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }