var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    _vm._g(
      _vm._b(
        {
          attrs: {
            "cancel-variant": "link",
            "button-size": "sm",
            centered: "",
            scrollable: "",
            id: _vm.id,
          },
          scopedSlots: _vm._u(
            [
              _vm._l(_vm.$scopedSlots, function (_, name) {
                return {
                  key: name,
                  fn: function (slotData) {
                    return [_vm._t(name, null, null, slotData)]
                  },
                }
              }),
            ],
            null,
            true
          ),
        },
        "b-modal",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      _vm._l(_vm.$slots, function (_, name) {
        return _vm._t(name, null, { slot: name })
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }