var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-input-group",
        [
          _c("b-form-input", {
            attrs: { type: "text", debounce: "500" },
            model: {
              value: _vm.term,
              callback: function ($$v) {
                _vm.term = $$v
              },
              expression: "term",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-overlay",
        { attrs: { show: _vm.loading, rounded: "sm" } },
        [
          _c("b-form-select", {
            staticClass: "mt-1",
            attrs: {
              options: _vm.filtered_options,
              "select-size": _vm.selectSize,
            },
            on: { change: _vm.onChange },
            model: {
              value: _vm.selectedOption,
              callback: function ($$v) {
                _vm.selectedOption = $$v
              },
              expression: "selectedOption",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }