var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("b-button", { attrs: { variant: "link" }, on: { click: _vm.back } }, [
        _vm._v("Back"),
      ]),
      _vm._v(" "),
      _c("session-summary"),
      _vm._v(" "),
      _vm.session
        ? _c(
            "b-tabs",
            {
              attrs: { "content-class": "mt-3" },
              on: { "activate-tab": _vm.handleTabActivation },
            },
            [
              _c(
                "b-tab",
                { attrs: { title: "General", active: _vm.tab === "edit" } },
                [_c("session-edit")],
                1
              ),
              _vm._v(" "),
              _c(
                "b-tab",
                {
                  attrs: {
                    title: "Participant Assignment",
                    active: _vm.tab === "assignment",
                    lazy: "",
                  },
                },
                [
                  _c("assign-participants", {
                    attrs: {
                      defaultSortBy:
                        "session_assignments.interest_ranking, people.published_name",
                      defaultSortDesc: false,
                      perPage: 200,
                      defaultFilter: _vm.assignmentFilter,
                      model: _vm.sessionAssignmentModel,
                      nullsFirst: "false",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-tab",
                {
                  attrs: {
                    title: "Schedule",
                    active: _vm.tab === "schedule",
                    lazy: "",
                  },
                },
                [_c("session-schedule")],
                1
              ),
              _vm._v(" "),
              _c(
                "b-tab",
                {
                  attrs: {
                    title: "Conflicts",
                    active: _vm.tab === "conflicts",
                    lazy: "",
                  },
                },
                [
                  _c("session-conflicts", {
                    ref: "conflict-reporting",
                    attrs: {
                      model: _vm.sessionConflictModel,
                      sessionId: _vm.id,
                      displaySessionInfo: false,
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-tab",
                { attrs: { title: "Notes", active: _vm.tab === "notes" } },
                [_c("session-notes")],
                1
              ),
              _vm._v(" "),
              _vm.currentUserIsAdmin
                ? _c(
                    "b-tab",
                    {
                      attrs: {
                        title: "Integrations",
                        active: _vm.tab === "integrations",
                      },
                    },
                    [
                      _c("pre", [
                        _vm._v(
                          _vm._s(
                            JSON.stringify(
                              _vm.published_session.integrations,
                              undefined,
                              2
                            )
                          )
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }