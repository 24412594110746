var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mb-4 mr-3" },
    [
      _c(
        "div",
        {
          staticClass:
            "row mb-4 pr-1 sticky-top bg-white border-bottom border-dark",
        },
        [
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticClass: "col" }, [
            _c("h6", [_vm._v("\n        Current Counts\n      ")]),
            _vm._v("\n      Rank 1: "),
            _c("b", [_vm._v(_vm._s(_vm.rank1_total))]),
            _c("br"),
            _vm._v("\n      Rank 2: "),
            _c("b", [_vm._v(_vm._s(_vm.rank2_total))]),
            _c("br"),
            _vm._v("\n      Rank 3: "),
            _c("b", [_vm._v(_vm._s(_vm.rank3_total))]),
            _c("br"),
            _vm._v("\n      Unranked: "),
            _c("b", [_vm._v(_vm._s(_vm.other_total))]),
            _c("br"),
          ]),
        ]
      ),
      _vm._v(" "),
      _vm._l(_vm.sortedCollection, function (item) {
        return _c("div", { key: item.id, staticClass: "row mb-4" }, [
          _c(
            "div",
            { staticClass: "col-8" },
            [
              _c("h4", [_vm._v(_vm._s(item.session.title))]),
              _vm._v(" "),
              _c("p", {
                domProps: { innerHTML: _vm._s(item.session.description) },
              }),
              _vm._v(" "),
              item.session.format
                ? _c("div", [
                    _vm._v("\n          Format: "),
                    _c(
                      "span",
                      { staticClass: "badge badge-pill badge-info mr-1" },
                      [_vm._v(_vm._s(item.session.format.name))]
                    ),
                    _c("br"),
                  ])
                : _vm._e(),
              _vm._v(" "),
              item.session.area_list && item.session.area_list.length
                ? _c(
                    "div",
                    [
                      _vm._v("\n          Area(s): "),
                      _vm._l(item.session.area_list, function (area) {
                        return _c(
                          "span",
                          {
                            key: area,
                            staticClass: "badge badge-pill badge-primary mr-1",
                          },
                          [_vm._v(_vm._s(area))]
                        )
                      }),
                    ],
                    2
                  )
                : _vm._e(),
              _vm._v(" "),
              item.session.tag_list && item.session.tag_list.length
                ? _c(
                    "div",
                    [
                      _vm._v("\n        Tag(s): "),
                      _vm._l(item.session.tag_list, function (tag) {
                        return _c(
                          "span",
                          {
                            key: tag,
                            staticClass: "badge badge-pill badge-warning mr-1",
                          },
                          [_vm._v(_vm._s(_vm.tagFormatter(tag)))]
                        )
                      }),
                    ],
                    2
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              item.session.instructions_for_interest
                ? _c("div", { staticClass: "mt-3" }, [
                    _vm._v("Instructions for potential panelists:"),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", {
                staticClass: "panelist-instructions",
                domProps: {
                  innerHTML: _vm._s(item.session.instructions_for_interest),
                },
              }),
              _vm._v(" "),
              _c("b-textarea", {
                on: {
                  blur: function ($event) {
                    return _vm.changeAssignment(item)
                  },
                },
                model: {
                  value: item.interest_notes,
                  callback: function ($$v) {
                    _vm.$set(item, "interest_notes", $$v)
                  },
                  expression: "item.interest_notes",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col pt-4" },
            [
              _c("session-assignment-monitor", {
                attrs: { assignment: item, model: "session_assignment" },
                on: { change: _vm.changeAssignment },
              }),
              _vm._v(" "),
              _c(
                "b-form-group",
                { attrs: { label: "Rank" } },
                [
                  _c("b-form-select", {
                    attrs: {
                      state: _vm.errored(item),
                      options: _vm.rankOptions,
                    },
                    model: {
                      value: item.interest_ranking,
                      callback: function ($$v) {
                        _vm.$set(item, "interest_ranking", $$v)
                      },
                      expression: "item.interest_ranking",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label:
                      "Override default moderating preferences for this session only?",
                  },
                },
                [
                  _c("b-form-select", {
                    attrs: { options: _vm.moderatorOptions },
                    model: {
                      value: item.interest_role,
                      callback: function ($$v) {
                        _vm.$set(item, "interest_role", $$v)
                      },
                      expression: "item.interest_role",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  attrs: { variant: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.notInterested(item)
                    },
                  },
                },
                [_c("b-icon-trash")],
                1
              ),
            ],
            1
          ),
        ])
      }),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          ref: "unexpress-interest-modal",
          attrs: { title: "Confirm Not Interested" },
          on: { hidden: _vm.stillInterested, ok: _vm.okNotInterested },
        },
        [
          _c("p", { staticClass: "my-4" }, [
            _vm._v(
              "\n       Confirm that you are no longer interested in that session.\n     "
            ),
          ]),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-8" }, [
      _c("small", [
        _c("p", [
          _vm._v(
            "\n        Once you have selected sessions you are interested in,\n        use this page to tell us your level of interest and what you would contribute to the topic.\n      "
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n        Rank each session from one to three using the following scale:\n        "
          ),
          _c("ol", [
            _c("li", [
              _vm._v("I really want to be on this panel ("),
              _c("b", [_vm._v("limit of 5")]),
              _vm._v(")"),
            ]),
            _vm._v(" "),
            _c("li", [
              _vm._v("I have meaningful contributions to this panel ("),
              _c("b", [_vm._v("limit of 5")]),
              _vm._v(")"),
            ]),
            _vm._v(" "),
            _c("li", [
              _vm._v("I am willing to be on this panel ("),
              _c("b", [_vm._v("unlimited")]),
              _vm._v(")"),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            '\n        Use the text box to tell us why you are a good choice for this panel and what you would contribute.\n        We will have hundreds of program participants, do not assume that we will just\n        "know" what your strengths are - tell us about them!\n      '
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }