import { render, staticRenderFns } from "./agreement_editor.vue?vue&type=template&id=64762302&"
import script from "./agreement_editor.vue?vue&type=script&lang=js&"
export * from "./agreement_editor.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/planorama/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('64762302')) {
      api.createRecord('64762302', component.options)
    } else {
      api.reload('64762302', component.options)
    }
    module.hot.accept("./agreement_editor.vue?vue&type=template&id=64762302&", function () {
      api.rerender('64762302', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "app/javascript/agreements/agreement_editor.vue"
export default component.exports