var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid" },
    [
      _vm.sortedCollection.length == 0
        ? _c("div", { staticClass: "row mb-4" }, [_vm._m(0)])
        : _vm._e(),
      _vm._v(" "),
      _vm.sortedCollection.length > 0
        ? _c("div", { staticClass: "row mb-4" }, [
            _c("div", { staticClass: "col-3" }, [
              _c("span", [
                _vm._v("\n        Assignment State\n        "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary btn-sm",
                    on: { click: _vm.reorder },
                  },
                  [_vm._v("Re-order")]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-9" }, [
              _vm._v("\n      Potential Participants\n    "),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.sortedCollection, function (item) {
        return _c(
          "div",
          { key: item.id, staticClass: "row mb-4" },
          [
            _c(
              "div",
              { staticClass: "col-2" },
              [
                _c("assignment-state", {
                  attrs: { "session-assignment": item },
                  on: { input: _vm.saveAssignment },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("assignee", { attrs: { assignment: item } }),
          ],
          1
        )
      }),
      _vm._v(" "),
      _c("participant-search", {
        staticClass: "border-top border-dark pt-3",
        attrs: {
          defaultFilter: _vm.peopleFilter,
          defaultSortBy: "people.published_name",
          session: _vm.session,
          model: _vm.personModel,
        },
        on: { change: _vm.refreshAssignments },
      }),
      _vm._v(" "),
      _c("person-details", { attrs: { model: "person" } }),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-12" }, [
      _c("h5", [
        _vm._v("No participants have expressed interest in this session"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }