export const venue_columns = [
  {
    key: 'name',
    label: 'Name',
    type: "text",
    sortable: true
  },
  {
    key: 'address',
    label: 'Address',
    type: "text",
    sortable: true,
  },
];
