var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("mailing-preview", { attrs: { mailing: _vm.mailing } }),
      _vm._v(" "),
      _c(
        "plano-modal",
        {
          attrs: {
            title: "Save Mailing",
            id: "save-mailing-modal",
            "ok-title": "Save",
          },
          on: {
            close: _vm.onClose,
            cancel: _vm.onClose,
            ok: _vm.onConfirmedSave,
          },
        },
        [
          _c(
            "b-form",
            [
              _c("model-field", {
                attrs: { label: "Name", type: "text", stateless: "" },
                model: {
                  value: _vm.mailing.title,
                  callback: function ($$v) {
                    _vm.$set(_vm.mailing, "title", $$v)
                  },
                  expression: "mailing.title",
                },
              }),
              _vm._v(" "),
              _c("model-field", {
                attrs: {
                  label: "Description",
                  type: "textarea",
                  stateless: "",
                },
                model: {
                  value: _vm.mailing.description,
                  callback: function ($$v) {
                    _vm.$set(_vm.mailing, "description", $$v)
                  },
                  expression: "mailing.description",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "d-flex " }, [
        _c("div", { staticClass: "p-2" }, [_vm._v(_vm._s(_vm.mailing.title))]),
        _vm._v(" "),
        _c("div", { staticClass: "ml-auto p-2" }, [_vm._t("controls")], 2),
      ]),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c(
        "b-form-group",
        {
          attrs: {
            "label-cols-sm": "3",
            "label-cols-lg": "2",
            "content-cols-sm": "9",
            "content-cols-lg": "10",
            label: "Add Names:",
            "label-for": "email-addresses",
          },
        },
        [
          _c("email-list-input", {
            attrs: { id: "email-addresses", disabled: _vm.readOnly },
            model: {
              value: _vm.mailing.emails,
              callback: function ($$v) {
                _vm.$set(_vm.mailing, "emails", $$v)
              },
              expression: "mailing.emails",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-form-group",
        [
          _c(
            "b-form-checkbox",
            {
              attrs: { disabled: _vm.readOnly },
              model: {
                value: _vm.has_survey,
                callback: function ($$v) {
                  _vm.has_survey = $$v
                },
                expression: "has_survey",
              },
            },
            [_vm._v("\n      This mailing has a survey attached\n    ")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-form-group",
        { attrs: { "label-cols-sm": "1", "label-cols-lg": "1" } },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                "label-cols-sm": "3",
                "label-cols-lg": "2",
                "content-cols-sm": "9",
                "content-cols-lg": "10",
                label: "Select Survey:",
                "label-for": "email-survey",
              },
            },
            [
              _c("model-select", {
                attrs: {
                  model: "survey",
                  filter: '{"op":"all","queries":[["public", "=", "true"]]}',
                  field: "name",
                  disabled: _vm.has_survey == false,
                },
                model: {
                  value: _vm.mailing.survey_id,
                  callback: function ($$v) {
                    _vm.$set(_vm.mailing, "survey_id", $$v)
                  },
                  expression: "mailing.survey_id",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-form-group",
        {
          attrs: {
            "label-cols-sm": "3",
            "label-cols-lg": "2",
            "content-cols-sm": "9",
            "content-cols-lg": "10",
            label: "Subject:",
            "label-for": "email-subject",
          },
        },
        [
          _c("b-form-input", {
            attrs: { id: "email-subject", disabled: _vm.readOnly },
            model: {
              value: _vm.mailing.subject,
              callback: function ($$v) {
                _vm.$set(_vm.mailing, "subject", $$v)
              },
              expression: "mailing.subject",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("plano-editor", {
        attrs: {
          type: "classic",
          "show-mail-merge": true,
          disabled: _vm.readOnly,
        },
        model: {
          value: _vm.mailing.content,
          callback: function ($$v) {
            _vm.$set(_vm.mailing, "content", $$v)
          },
          expression: "mailing.content",
        },
      }),
      _vm._v(" "),
      !_vm.readOnly
        ? _c(
            "div",
            { staticClass: "d-flex justify-content-end" },
            [
              _c(
                "b-button",
                {
                  staticClass: "m-1 btn-sm",
                  attrs: { variant: "primary" },
                  on: { click: _vm.onSave },
                },
                [_vm._v("Save Mailing")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.readOnly
        ? _c(
            "div",
            { staticClass: "d-flex justify-content-end" },
            [
              _c(
                "b-button",
                {
                  staticClass: "m-1",
                  attrs: { variant: "primary" },
                  on: { click: _vm.onSendTest },
                },
                [_vm._v("Send test to self")]
              ),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  staticClass: "m-1",
                  attrs: { variant: "primary" },
                  on: { click: _vm.onPreview },
                },
                [_vm._v("Preview")]
              ),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  staticClass: "m-1",
                  attrs: { variant: "danger" },
                  on: { click: _vm.onSend },
                },
                [_vm._v("Send")]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }