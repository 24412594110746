var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("dt", [_vm._v(_vm._s(_vm.sessionRoleLabel || _vm.sessionRoleName))]),
      _vm._v(" "),
      !_vm.myAssignments.length
        ? _c("dd", { staticClass: "text-muted font-italic ml-2" }, [
            _vm._v("None Assigned"),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.myAssignments, function (ref) {
        var person = ref.person
        return _c(
          "dd",
          { key: person.id, staticClass: "ml-2" },
          [
            _c("router-link", { attrs: { to: "/people/edit/" + person.id } }, [
              _vm._v(_vm._s(person.published_name)),
            ]),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }