var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-form",
    { ref: "add-person-form" },
    [
      _c("model-field", {
        attrs: { label: "Name", type: "text", stateless: "" },
        model: {
          value: _vm.person.name,
          callback: function ($$v) {
            _vm.$set(_vm.person, "name", $$v)
          },
          expression: "person.name",
        },
      }),
      _vm._v(" "),
      _c("email-field", {
        attrs: { label: "Email", id: "new-user-email" },
        model: {
          value: _vm.email,
          callback: function ($$v) {
            _vm.email = $$v
          },
          expression: "email",
        },
      }),
      _vm._v(" "),
      _vm.showButtons
        ? _c(
            "div",
            { staticClass: "d-flex justify-content-end" },
            [
              _c(
                "b-button",
                { attrs: { variant: "link" }, on: { click: _vm.clear } },
                [_vm._v("Cancel")]
              ),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  attrs: { variant: "primary" },
                  on: { click: _vm.savePerson },
                },
                [_vm._v("Save")]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }