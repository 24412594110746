var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("validation-provider", {
    attrs: { rules: _vm.rules, name: _vm.label },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (ref) {
          var errors = ref.errors
          var dirty = ref.dirty
          var validated = ref.validated
          var valid = ref.valid
          var classes = ref.classes
          return [
            _c(
              "b-form-group",
              {
                staticClass: "mt-2",
                attrs: {
                  label: _vm.label,
                  "label-for": _vm.id,
                  id: _vm.groupId,
                },
              },
              [
                _c(
                  "b-input-group",
                  {
                    class: classes,
                    scopedSlots: _vm._u(
                      [
                        _vm.prepend || _vm.$slots.prepend
                          ? {
                              key: "prepend",
                              fn: function () {
                                return [
                                  _vm._t("prepend", function () {
                                    return [
                                      _c("b-input-group-text", [
                                        _vm._v(_vm._s(_vm.prepend)),
                                      ]),
                                    ]
                                  }),
                                ]
                              },
                              proxy: true,
                            }
                          : null,
                      ],
                      null,
                      true
                    ),
                  },
                  [
                    _vm._v(" "),
                    _c(
                      "b-form-input",
                      _vm._g(
                        {
                          attrs: {
                            type: "text",
                            name: _vm.label,
                            value: _vm.value,
                            disabled: _vm.disabled,
                            state: _vm.rules
                              ? dirty || validated
                                ? valid
                                  ? null
                                  : false
                                : null
                              : null,
                            "aria-describedby": _vm.feedbackId,
                          },
                        },
                        _vm.$listeners
                      )
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-form-invalid-feedback",
                  { attrs: { id: _vm.feedbackId } },
                  [_vm._v(_vm._s(errors[0]))]
                ),
              ],
              1
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }