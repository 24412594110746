var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("person-schedule-display", {
    attrs: {
      sessions: _vm.sessions,
      title: "Draft Schedule",
      approvalType: "draft",
      noSidebar: _vm.noSidebar,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }