var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "all-scheduable-session" },
    [
      _c("schedule-session-search", {
        attrs: { value: _vm.filter },
        on: { change: _vm.onSearchChanged },
      }),
      _vm._v(" "),
      _c("b-overlay", { attrs: { show: _vm.tableBusy, rounded: "sm" } }, [
        _c(
          "div",
          { staticClass: "all-scheduable-session-list" },
          _vm._l(_vm.sortedCollection, function (session) {
            return _c(
              "div",
              {
                key: session.id,
                staticClass: "scheduable-session mb-1",
                attrs: { draggable: "true" },
                on: {
                  dragstart: function ($event) {
                    return _vm.onEventDragStart($event, session)
                  },
                  click: function ($event) {
                    return _vm.onSelectEvent($event, session)
                  },
                },
              },
              [
                _c("strong", [_vm._v(_vm._s(session.title))]),
                _vm._v(
                  "\n        (" +
                    _vm._s(
                      session.duration
                        ? session.duration + " min"
                        : "no duration"
                    ) +
                    ")\n      "
                ),
              ]
            )
          }),
          0
        ),
      ]),
      _vm._v(" "),
      _c("b-pagination", {
        staticClass: "d-flex",
        attrs: {
          "total-rows": _vm.totalRows,
          "per-page": _vm.perPage,
          size: "sm",
          align: "center",
        },
        model: {
          value: _vm.currentPage,
          callback: function ($$v) {
            _vm.currentPage = $$v
          },
          expression: "currentPage",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }