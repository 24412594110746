var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "pb-4" },
      [_c("RoomsTable", { ref: "rooms-table", attrs: { defaultFilter: "" } })],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "border p-2 mb-2" },
      [_c("RoomEditor", { ref: "roomEditor", on: { saved: _vm.init } })],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }