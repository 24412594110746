var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !!_vm.localResponse
    ? _c(
        "div",
        { staticClass: "survey-question mt-3 pl-1" },
        [
          !_vm.formatting && !_vm.socialmedia
            ? _c("b-form-group", {
                scopedSlots: _vm._u(
                  [
                    {
                      key: "label",
                      fn: function () {
                        return [
                          _c("span", {
                            domProps: { innerHTML: _vm._s(_vm.questionText) },
                          }),
                          _vm._v(" "),
                          _c("mandatory-star", {
                            attrs: { mandatory: _vm.question.mandatory },
                          }),
                          _vm._v(" "),
                          _c("linked-field-icon", {
                            attrs: { linked_field: _vm.question.linked_field },
                          }),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "default",
                      fn: function (ref) {
                        var ariaDescribedBy = ref.ariaDescribedBy
                        return [
                          !_vm.email
                            ? _c("validation-provider", {
                                attrs: {
                                  mode: "eager",
                                  rules: _vm.rules,
                                  name: _vm.question.question,
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var valid = ref.valid
                                        var errors = ref.errors
                                        return [
                                          _vm.textbox
                                            ? _c(
                                                "b-form-textarea",
                                                {
                                                  class: {
                                                    "w-50": _vm.answerable,
                                                  },
                                                  attrs: {
                                                    "aria-describedBy":
                                                      ariaDescribedBy,
                                                    disabled: !_vm.answerable,
                                                    state: _vm.calcValid(
                                                      errors,
                                                      valid
                                                    ),
                                                  },
                                                  on: {
                                                    blur: function ($event) {
                                                      return _vm.saveResponse(
                                                        _vm.localResponse,
                                                        _vm.selectedSubmission
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.localResponse.response
                                                        .text,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.localResponse
                                                          .response,
                                                        "text",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "localResponse.response.text",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.localResponse.response
                                                        .text
                                                    )
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.textfield
                                            ? _c("b-form-input", {
                                                class: {
                                                  "w-50": _vm.answerable,
                                                },
                                                attrs: {
                                                  "aria-describedBy":
                                                    ariaDescribedBy,
                                                  disabled: !_vm.answerable,
                                                  state: _vm.calcValid(
                                                    errors,
                                                    valid
                                                  ),
                                                },
                                                on: {
                                                  blur: function ($event) {
                                                    return _vm.saveResponse(
                                                      _vm.localResponse,
                                                      _vm.selectedSubmission
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value:
                                                    _vm.localResponse.response
                                                      .text,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.localResponse
                                                        .response,
                                                      "text",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "localResponse.response.text",
                                                },
                                              })
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.yesnomaybe
                                            ? _c(
                                                "b-form-radio-group",
                                                {
                                                  class: {
                                                    "w-50": _vm.answerable,
                                                  },
                                                  attrs: {
                                                    stacked: "",
                                                    "aria-describedBy":
                                                      ariaDescribedBy,
                                                    state: _vm.calcValid(
                                                      errors,
                                                      valid
                                                    ),
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.saveResponse(
                                                        _vm.localResponse,
                                                        _vm.selectedSubmission
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.radioButtonResponse,
                                                    callback: function ($$v) {
                                                      _vm.radioButtonResponse =
                                                        $$v
                                                    },
                                                    expression:
                                                      "radioButtonResponse",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "b-form-radio",
                                                    {
                                                      attrs: {
                                                        disabled:
                                                          !_vm.answerable,
                                                        value:
                                                          _vm.yesLabel.value,
                                                        state: _vm.calcValid(
                                                          errors,
                                                          valid
                                                        ),
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.yesLabel.label
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "b-form-radio",
                                                    {
                                                      attrs: {
                                                        disabled:
                                                          !_vm.answerable,
                                                        value:
                                                          _vm.noLabel.value,
                                                        state: _vm.calcValid(
                                                          errors,
                                                          valid
                                                        ),
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.noLabel.label
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "b-form-radio",
                                                    {
                                                      attrs: {
                                                        disabled:
                                                          !_vm.answerable,
                                                        value:
                                                          _vm.maybeLabel.value,
                                                        state: _vm.calcValid(
                                                          errors,
                                                          valid
                                                        ),
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.maybeLabel.label
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "ml-4 mt-1 mb-3",
                                                    },
                                                    [
                                                      _c("b-form-textarea", {
                                                        attrs: {
                                                          placeholder:
                                                            _vm.SURVEY_YESNOMAYBE_PLACEHOLDER,
                                                          disabled:
                                                            !_vm.answerable ||
                                                            _vm.radioButtonResponse !==
                                                              _vm.maybeLabel
                                                                .value,
                                                        },
                                                        on: {
                                                          blur: function (
                                                            $event
                                                          ) {
                                                            return _vm.saveResponse(
                                                              _vm.localResponse,
                                                              _vm.selectedSubmission
                                                            )
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.localResponse
                                                              .response.text,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.localResponse
                                                                .response,
                                                              "text",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "localResponse.response.text",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.boolean
                                            ? _c(
                                                "b-form-radio-group",
                                                {
                                                  class: {
                                                    "w-50": _vm.answerable,
                                                  },
                                                  attrs: {
                                                    stacked: "",
                                                    "aria-describedBy":
                                                      ariaDescribedBy,
                                                    state: _vm.calcValid(
                                                      errors,
                                                      valid
                                                    ),
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.saveResponse(
                                                        _vm.localResponse,
                                                        _vm.selectedSubmission
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.radioButtonResponse,
                                                    callback: function ($$v) {
                                                      _vm.radioButtonResponse =
                                                        $$v
                                                    },
                                                    expression:
                                                      "radioButtonResponse",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "b-form-radio",
                                                    {
                                                      attrs: {
                                                        disabled:
                                                          !_vm.answerable,
                                                        value:
                                                          _vm.bYesLabel.value,
                                                        state: _vm.calcValid(
                                                          errors,
                                                          valid
                                                        ),
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.bYesLabel.label
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "b-form-radio",
                                                    {
                                                      attrs: {
                                                        disabled:
                                                          !_vm.answerable,
                                                        value:
                                                          _vm.bNoLabel.value,
                                                        state: _vm.calcValid(
                                                          errors,
                                                          valid
                                                        ),
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.bNoLabel.label
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.attendance_type
                                            ? _c(
                                                "b-form-radio-group",
                                                {
                                                  class: {
                                                    "w-50": _vm.answerable,
                                                  },
                                                  attrs: {
                                                    stacked: "",
                                                    "aria-describedBy":
                                                      ariaDescribedBy,
                                                    state: _vm.calcValid(
                                                      errors,
                                                      valid
                                                    ),
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.saveResponse(
                                                        _vm.localResponse,
                                                        _vm.selectedSubmission
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.radioButtonResponse,
                                                    callback: function ($$v) {
                                                      _vm.radioButtonResponse =
                                                        $$v
                                                    },
                                                    expression:
                                                      "radioButtonResponse",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "b-form-radio",
                                                    {
                                                      attrs: {
                                                        disabled:
                                                          !_vm.answerable,
                                                        value:
                                                          _vm.inPersonLabel
                                                            .value,
                                                        state: _vm.calcValid(
                                                          errors,
                                                          valid
                                                        ),
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.inPersonLabel
                                                            .label
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "b-form-radio",
                                                    {
                                                      attrs: {
                                                        disabled:
                                                          !_vm.answerable,
                                                        value:
                                                          _vm.virtualLabel
                                                            .value,
                                                        state: _vm.calcValid(
                                                          errors,
                                                          valid
                                                        ),
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.virtualLabel.label
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "b-form-radio",
                                                    {
                                                      attrs: {
                                                        disabled:
                                                          !_vm.answerable,
                                                        value:
                                                          _vm.hybridLabel.value,
                                                        state: _vm.calcValid(
                                                          errors,
                                                          valid
                                                        ),
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.hybridLabel.label
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.singlechoice
                                            ? _c(
                                                "b-form-radio-group",
                                                {
                                                  class: {
                                                    "w-50": _vm.answerable,
                                                  },
                                                  attrs: {
                                                    stacked: "",
                                                    "aria-describedBy":
                                                      ariaDescribedBy,
                                                    state: _vm.calcValid(
                                                      errors,
                                                      valid
                                                    ),
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.saveResponse(
                                                        _vm.localResponse,
                                                        _vm.selectedSubmission
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.radioButtonResponse,
                                                    callback: function ($$v) {
                                                      _vm.radioButtonResponse =
                                                        $$v
                                                    },
                                                    expression:
                                                      "radioButtonResponse",
                                                  },
                                                },
                                                [
                                                  _vm._l(
                                                    _vm.choices.filter(
                                                      function (a) {
                                                        return !a.other
                                                      }
                                                    ),
                                                    function (choice) {
                                                      return _c(
                                                        "b-form-radio",
                                                        {
                                                          key: choice.id,
                                                          attrs: {
                                                            value:
                                                              _vm.choiceValue(
                                                                choice
                                                              ),
                                                            disabled:
                                                              !_vm.answerable,
                                                            state:
                                                              _vm.calcValid(
                                                                errors,
                                                                valid
                                                              ),
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              return _vm.changeNextPage(
                                                                $event,
                                                                choice
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("span", {
                                                            domProps: {
                                                              innerHTML: _vm._s(
                                                                choice.answer
                                                              ),
                                                            },
                                                          }),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  _vm._v(" "),
                                                  _vm.otherFromQuestion
                                                    ? _c(
                                                        "b-form-radio",
                                                        {
                                                          staticClass: "mt-2",
                                                          attrs: {
                                                            value:
                                                              _vm.choiceValue(
                                                                _vm.otherFromQuestion
                                                              ),
                                                            disabled:
                                                              !_vm.answerable,
                                                            state:
                                                              _vm.calcValid(
                                                                errors,
                                                                valid
                                                              ),
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              return _vm.changeNextPage(
                                                                $event,
                                                                _vm.otherFromQuestion
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.otherChecked,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.otherChecked =
                                                                $$v
                                                            },
                                                            expression:
                                                              "otherChecked",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "b-form-group",
                                                            {
                                                              attrs: {
                                                                label: "Other",
                                                                "label-cols":
                                                                  "2",
                                                                "label-for":
                                                                  "other-" +
                                                                  _vm.question
                                                                    .id,
                                                                "label-class":
                                                                  "mt-n2",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  staticClass:
                                                                    "mt-n2",
                                                                  attrs: {
                                                                    id:
                                                                      "other-" +
                                                                      _vm
                                                                        .question
                                                                        .id,
                                                                    type: "text",
                                                                    disabled:
                                                                      !_vm.answerable ||
                                                                      !_vm.otherChecked,
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .localResponse
                                                                        .response
                                                                        .text,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm
                                                                            .localResponse
                                                                            .response,
                                                                          "text",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "localResponse.response.text",
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ],
                                                2
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.multiplechoice
                                            ? _c(
                                                "b-form-checkbox-group",
                                                {
                                                  class: {
                                                    "w-50": _vm.answerable,
                                                  },
                                                  attrs: {
                                                    stacked: "",
                                                    "aria-describedBy":
                                                      ariaDescribedBy,
                                                    state: _vm.calcValid(
                                                      errors,
                                                      valid
                                                    ),
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.saveResponse(
                                                        _vm.localResponse,
                                                        _vm.selectedSubmission
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.localResponse.response
                                                        .answers,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.localResponse
                                                          .response,
                                                        "answers",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "localResponse.response.answers",
                                                  },
                                                },
                                                [
                                                  _vm._l(
                                                    _vm.choices.filter(
                                                      function (a) {
                                                        return !a.other
                                                      }
                                                    ),
                                                    function (choice) {
                                                      return _c(
                                                        "b-form-checkbox",
                                                        {
                                                          key: choice.id,
                                                          attrs: {
                                                            value:
                                                              _vm.choiceValue(
                                                                choice
                                                              ),
                                                            state:
                                                              _vm.calcValid(
                                                                errors,
                                                                valid
                                                              ),
                                                            disabled:
                                                              !_vm.answerable,
                                                          },
                                                        },
                                                        [
                                                          _c("span", {
                                                            domProps: {
                                                              innerHTML: _vm._s(
                                                                choice.answer
                                                              ),
                                                            },
                                                          }),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  _vm._v(" "),
                                                  _vm.otherFromQuestion
                                                    ? _c(
                                                        "b-form-checkbox",
                                                        {
                                                          staticClass: "mt-2",
                                                          attrs: {
                                                            value:
                                                              _vm.choiceValue(
                                                                _vm.otherFromQuestion
                                                              ),
                                                            state:
                                                              _vm.calcValid(
                                                                errors,
                                                                valid
                                                              ),
                                                            disabled:
                                                              !_vm.answerable,
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.otherChecked,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.otherChecked =
                                                                $$v
                                                            },
                                                            expression:
                                                              "otherChecked",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "b-form-group",
                                                            {
                                                              attrs: {
                                                                label: "Other",
                                                                "label-cols":
                                                                  "2",
                                                                "label-for":
                                                                  "other-" +
                                                                  _vm.question
                                                                    .id,
                                                                "label-class":
                                                                  "mt-n2",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  staticClass:
                                                                    "mt-n2",
                                                                  attrs: {
                                                                    id:
                                                                      "other-" +
                                                                      _vm
                                                                        .question
                                                                        .id,
                                                                    type: "text",
                                                                    disabled:
                                                                      !_vm.answerable ||
                                                                      !_vm.otherChecked,
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .localResponse
                                                                        .response
                                                                        .text,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm
                                                                            .localResponse
                                                                            .response,
                                                                          "text",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "localResponse.response.text",
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ],
                                                2
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.dropdown
                                            ? _c(
                                                "b-form-select",
                                                {
                                                  class: {
                                                    "w-50": _vm.answerable,
                                                  },
                                                  attrs: {
                                                    state: _vm.calcValid(
                                                      errors,
                                                      valid
                                                    ),
                                                    "aria-describedby":
                                                      ariaDescribedBy,
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.saveResponse(
                                                        _vm.localResponse,
                                                        _vm.selectedSubmission
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.localResponse.response
                                                        .text,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.localResponse
                                                          .response,
                                                        "text",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "localResponse.response.text",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.choices,
                                                  function (choice) {
                                                    return _c(
                                                      "b-form-select-option",
                                                      {
                                                        key: choice.id,
                                                        attrs: {
                                                          value: choice.answer,
                                                          disabled:
                                                            !_vm.answerable,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(choice.answer)
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                1
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c(
                                            "b-form-invalid-feedback",
                                            {
                                              attrs: {
                                                "force-show": !_vm.calcValid(
                                                  errors,
                                                  valid
                                                ),
                                              },
                                            },
                                            [_vm._v(_vm._s(errors[0]))]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.email
                            ? _c("email-field-veevalidate", {
                                attrs: {
                                  answerable: _vm.answerable,
                                  "label-sr-only": "",
                                  disabled: !_vm.answerable,
                                  "aria-describedBy": ariaDescribedBy,
                                },
                                on: {
                                  blur: function ($event) {
                                    return _vm.saveResponse(
                                      _vm.localResponse,
                                      _vm.selectedSubmission
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.localResponse.response.text,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.localResponse.response,
                                      "text",
                                      $$v
                                    )
                                  },
                                  expression: "localResponse.response.text",
                                },
                              })
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3375258461
                ),
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.textonly
            ? _c("div", {
                domProps: { innerHTML: _vm._s(_vm.question.question) },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.hr ? _c("hr") : _vm._e(),
          _vm._v(" "),
          _vm.socialmedia
            ? _c(
                "div",
                [
                  _c("span", {
                    domProps: { innerHTML: _vm._s(_vm.questionText) },
                  }),
                  _c("mandatory-star", {
                    attrs: { mandatory: _vm.question.mandatory },
                  }),
                  _vm._v(" "),
                  _c("linked-field-icon", {
                    attrs: { linked_field: _vm.question.linked_field },
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { class: ["row", "ml-0", { "w-50": _vm.answerable }] },
                    [
                      _c(
                        "div",
                        { staticClass: "col-12 px-0" },
                        [
                          _c("simple-social", {
                            attrs: {
                              label: "Facebook",
                              disabled: !_vm.answerable,
                              id: _vm.formId("socials-facebook"),
                            },
                            on: {
                              blur: function ($event) {
                                return _vm.saveResponse(
                                  _vm.localResponse,
                                  _vm.selectedSubmission
                                )
                              },
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "prepend",
                                  fn: function () {
                                    return [
                                      _c("b-input-group-text", [
                                        _vm._v("facebook.com/"),
                                      ]),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              2833846945
                            ),
                            model: {
                              value:
                                _vm.localResponse.response.socialmedia.facebook,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.localResponse.response.socialmedia,
                                  "facebook",
                                  $$v
                                )
                              },
                              expression:
                                "localResponse.response.socialmedia.facebook",
                            },
                          }),
                          _vm._v(" "),
                          _c("simple-social", {
                            attrs: {
                              label: "Website",
                              prepend: "url",
                              disabled: !_vm.answerable,
                              id: _vm.formId("socials-website"),
                            },
                            on: {
                              blur: function ($event) {
                                return _vm.saveResponse(
                                  _vm.localResponse,
                                  _vm.selectedSubmission
                                )
                              },
                            },
                            model: {
                              value:
                                _vm.localResponse.response.socialmedia.website,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.localResponse.response.socialmedia,
                                  "website",
                                  $$v
                                )
                              },
                              expression:
                                "localResponse.response.socialmedia.website",
                            },
                          }),
                          _vm._v(" "),
                          _c("simple-social", {
                            attrs: {
                              label: "Instagram",
                              disabled: !_vm.answerable,
                              id: _vm.formId("socials-insta"),
                            },
                            on: {
                              blur: function ($event) {
                                return _vm.saveResponse(
                                  _vm.localResponse,
                                  _vm.selectedSubmission
                                )
                              },
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "prepend",
                                  fn: function () {
                                    return [
                                      _c("b-input-group-text", [
                                        _vm._v("instagram.com/"),
                                      ]),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              3272662257
                            ),
                            model: {
                              value:
                                _vm.localResponse.response.socialmedia
                                  .instagram,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.localResponse.response.socialmedia,
                                  "instagram",
                                  $$v
                                )
                              },
                              expression:
                                "localResponse.response.socialmedia.instagram",
                            },
                          }),
                          _vm._v(" "),
                          _c("simple-social", {
                            attrs: {
                              label: "Twitch",
                              disabled: !_vm.answerable,
                              id: _vm.formId("socials-twitch"),
                            },
                            on: {
                              blur: function ($event) {
                                return _vm.saveResponse(
                                  _vm.localResponse,
                                  _vm.selectedSubmission
                                )
                              },
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "prepend",
                                  fn: function () {
                                    return [
                                      _c("b-input-group-text", [
                                        _vm._v("twitch.tv/"),
                                      ]),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              804443775
                            ),
                            model: {
                              value:
                                _vm.localResponse.response.socialmedia.twitch,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.localResponse.response.socialmedia,
                                  "twitch",
                                  $$v
                                )
                              },
                              expression:
                                "localResponse.response.socialmedia.twitch",
                            },
                          }),
                          _vm._v(" "),
                          _c("simple-social", {
                            attrs: {
                              label: "YouTube",
                              disabled: !_vm.answerable,
                              id: _vm.formId("socials-youtube"),
                            },
                            on: {
                              blur: function ($event) {
                                return _vm.saveResponse(
                                  _vm.localResponse,
                                  _vm.selectedSubmission
                                )
                              },
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "prepend",
                                  fn: function () {
                                    return [
                                      _c("b-input-group-text", [
                                        _vm._v("youtube.com/"),
                                      ]),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              558140620
                            ),
                            model: {
                              value:
                                _vm.localResponse.response.socialmedia.youtube,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.localResponse.response.socialmedia,
                                  "youtube",
                                  $$v
                                )
                              },
                              expression:
                                "localResponse.response.socialmedia.youtube",
                            },
                          }),
                          _vm._v(" "),
                          _c("simple-social", {
                            attrs: {
                              label: "TikTok",
                              prepend: "@",
                              disabled: !_vm.answerable,
                              id: _vm.formId("socials-tiktok"),
                            },
                            on: {
                              blur: function ($event) {
                                return _vm.saveResponse(
                                  _vm.localResponse,
                                  _vm.selectedSubmission
                                )
                              },
                            },
                            model: {
                              value:
                                _vm.localResponse.response.socialmedia.tiktok,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.localResponse.response.socialmedia,
                                  "tiktok",
                                  $$v
                                )
                              },
                              expression:
                                "localResponse.response.socialmedia.tiktok",
                            },
                          }),
                          _vm._v(" "),
                          _c("simple-social", {
                            attrs: {
                              label: "LinkedIn",
                              disabled: !_vm.answerable,
                              id: _vm.formId("socials-linkedin"),
                            },
                            on: {
                              blur: function ($event) {
                                return _vm.saveResponse(
                                  _vm.localResponse,
                                  _vm.selectedSubmission
                                )
                              },
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "prepend",
                                  fn: function () {
                                    return [
                                      _c("b-input-group-text", [
                                        _vm._v("linkedin.com/in/"),
                                      ]),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              901077351
                            ),
                            model: {
                              value:
                                _vm.localResponse.response.socialmedia.linkedin,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.localResponse.response.socialmedia,
                                  "linkedin",
                                  $$v
                                )
                              },
                              expression:
                                "localResponse.response.socialmedia.linkedin",
                            },
                          }),
                          _vm._v(" "),
                          _c("simple-social", {
                            attrs: {
                              label: "Bluesky",
                              prepend: "@",
                              disabled: !_vm.answerable,
                              id: _vm.formId("socials-bsky"),
                            },
                            on: {
                              blur: function ($event) {
                                return _vm.saveResponse(
                                  _vm.localResponse,
                                  _vm.selectedSubmission
                                )
                              },
                            },
                            model: {
                              value:
                                _vm.localResponse.response.socialmedia.bsky,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.localResponse.response.socialmedia,
                                  "bsky",
                                  $$v
                                )
                              },
                              expression:
                                "localResponse.response.socialmedia.bsky",
                            },
                          }),
                          _vm._v(" "),
                          _c("simple-social", {
                            attrs: {
                              label: "Fediverse",
                              prepend: "url",
                              disabled: !_vm.answerable,
                              id: _vm.formId("socials-fediverse"),
                            },
                            on: {
                              blur: function ($event) {
                                return _vm.saveResponse(
                                  _vm.localResponse,
                                  _vm.selectedSubmission
                                )
                              },
                            },
                            model: {
                              value:
                                _vm.localResponse.response.socialmedia
                                  .fediverse,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.localResponse.response.socialmedia,
                                  "fediverse",
                                  $$v
                                )
                              },
                              expression:
                                "localResponse.response.socialmedia.fediverse",
                            },
                          }),
                          _vm._v(" "),
                          _c("simple-social", {
                            attrs: {
                              label: "X (formerly Twitter)",
                              prepend: "@",
                              disabled: !_vm.answerable,
                              id: _vm.formId("socials-twitter"),
                            },
                            on: {
                              blur: function ($event) {
                                return _vm.saveResponse(
                                  _vm.localResponse,
                                  _vm.selectedSubmission
                                )
                              },
                            },
                            model: {
                              value:
                                _vm.localResponse.response.socialmedia.twitter,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.localResponse.response.socialmedia,
                                  "twitter",
                                  $$v
                                )
                              },
                              expression:
                                "localResponse.response.socialmedia.twitter",
                            },
                          }),
                          _vm._v(" "),
                          _c("simple-social", {
                            attrs: {
                              label: "Other",
                              disabled: !_vm.answerable,
                              id: _vm.formId("socials-other"),
                            },
                            on: {
                              blur: function ($event) {
                                return _vm.saveResponse(
                                  _vm.localResponse,
                                  _vm.selectedSubmission
                                )
                              },
                            },
                            model: {
                              value:
                                _vm.localResponse.response.socialmedia.other,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.localResponse.response.socialmedia,
                                  "other",
                                  $$v
                                )
                              },
                              expression:
                                "localResponse.response.socialmedia.other",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }