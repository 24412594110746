var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.regData
        ? _c(
            "div",
            [
              _vm._t("heading"),
              _vm._v(" "),
              _c("div", [_vm._v("Full Name: " + _vm._s(_vm.regData.name))]),
              _vm._v(" "),
              _c("div", [_vm._v("Email: " + _vm._s(_vm.regData.email))]),
              _vm._v(" "),
              _c("div", { staticClass: "mb-2" }, [
                _vm._v(
                  "Registration ID: " + _vm._s(_vm.regData.registration_number)
                ),
              ]),
              _vm._v(" "),
              _c("div", [
                _vm._v("Preferred Name: " + _vm._s(_vm.regData.preferred_name)),
              ]),
              _vm._v(" "),
              _c("div", [
                _vm._v("Badge Name: " + _vm._s(_vm.regData.badge_name)),
              ]),
              _vm._v(" "),
              _c("div", [
                _vm._v(
                  "Alternate Email: " + _vm._s(_vm.regData.alternative_email)
                ),
              ]),
              _vm._v(" "),
              _c("div", [
                _vm._v("Product: " + _vm._s(_vm.regData.raw_info.product)),
              ]),
              _vm._v(" "),
              _c("div", [
                _vm._v(
                  "Attending Status: " +
                    _vm._s(_vm.regData.raw_info.attending_status)
                ),
              ]),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.regData ? _vm._t("empty") : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }