var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-button",
    _vm._g(_vm._b({}, "b-button", _vm.$attrs, false), _vm.$listeners),
    [
      _vm.loading
        ? _c("b-spinner", { attrs: { label: "Loading...", small: "" } })
        : _vm._e(),
      _vm._v(" "),
      !_vm.loading || _vm.showButtonTextWhileLoading
        ? _vm._t("default")
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }