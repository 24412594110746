var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "d-flex w-100 p-3" }, [
    _c(
      "div",
      { staticClass: "d-flex flex-column w-100" },
      [
        _c("label", { attrs: { for: _vm.idA } }, [
          _vm._v(_vm._s(_vm.notSelectedLabel)),
        ]),
        _vm._v(" "),
        _c("b-select", {
          staticClass: "w-100",
          attrs: {
            id: _vm.idA,
            multiple: "",
            "select-size": 7,
            options: _vm.optionsA,
          },
          model: {
            value: _vm.selectedA,
            callback: function ($$v) {
              _vm.selectedA = $$v
            },
            expression: "selectedA",
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "d-flex flex-column justify-content-center p-2 mt-4" },
      [
        _c(
          "button",
          {
            staticClass: "btn btn-primary m-2",
            attrs: { disabled: !_vm.selectedA.length },
            on: {
              click: function ($event) {
                return _vm.moveToB()
              },
            },
          },
          [_c("b-icon-chevron-right")],
          1
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-primary m-2",
            attrs: { disabled: !_vm.selectedB.length },
            on: {
              click: function ($event) {
                return _vm.moveToA()
              },
            },
          },
          [_c("b-icon-chevron-left")],
          1
        ),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "d-flex flex-column w-100" },
      [
        _c("label", { attrs: { for: _vm.idB } }, [
          _vm._v(_vm._s(_vm.selectedLabel)),
        ]),
        _vm._v(" "),
        _c("b-select", {
          staticClass: "w-100",
          attrs: {
            id: _vm.idB,
            multiple: "",
            "select-size": 7,
            options: _vm.optionsB,
          },
          model: {
            value: _vm.selectedB,
            callback: function ($$v) {
              _vm.selectedB = $$v
            },
            expression: "selectedB",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }