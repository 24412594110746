var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    {
      directives: [
        {
          name: "b-tooltip",
          rawName: "v-b-tooltip.html",
          value: { customClass: "truncated-tooltip" },
          expression: "{customClass: 'truncated-tooltip'}",
          modifiers: { html: true },
        },
      ],
      staticClass: "text-truncate truncated-span",
      attrs: { title: _vm.title },
    },
    [
      _vm._t("default", function () {
        return [_vm._v(_vm._s(_vm.title))]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }