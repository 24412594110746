var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    _vm._l(_vm.days, function (day) {
      return _c(
        "div",
        { key: day },
        [
          _c(
            "b-row",
            { staticClass: "my-1" },
            [
              _c(
                "b-col",
                { staticClass: "sm-2" },
                [
                  _c("session-limit-editor", {
                    attrs: {
                      day: day,
                      timezone: _vm.timezone,
                      model: "session_limit",
                    },
                    model: {
                      value: _vm.person,
                      callback: function ($$v) {
                        _vm.person = $$v
                      },
                      expression: "person",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("b-col", { staticClass: "sm-10" }, [
                _c("label", { attrs: { for: "input-none" } }, [
                  _vm._v(_vm._s(_vm.formatDate(day, { weekday: "long" }))),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }