var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.selected
    ? _c(
        "div",
        { class: ["container-fluid", { "my-3": !_vm.readOnly }] },
        [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-8" }, [
              _c(
                "h3",
                [
                  _vm._v(_vm._s(_vm.selected.published_name)),
                  !_vm.readOnly
                    ? _c("edit-button", {
                        directives: [
                          {
                            name: "b-modal",
                            rawName: "v-b-modal.person-top-modal",
                            modifiers: { "person-top-modal": true },
                          },
                        ],
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", [
                _vm._v("\n        Pronouns: "),
                _c("em", [_vm._v(_vm._s(_vm.selected.pronouns))]),
              ]),
              _vm._v(" "),
              _c("div", [
                _vm._v("\n        Willing to moderate sessions: "),
                _c("em", [_vm._v(_vm._s(_vm.willing_to_moderate))]),
              ]),
              _vm._v(" "),
              _c("div", [
                _vm._v("\n        Primary email: "),
                _vm.selected.primary_email
                  ? _c("em", [_vm._v(_vm._s(_vm.selected.primary_email.email))])
                  : _vm._e(),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "d-flex flex-column col-4 align-items-end" },
              [
                _c("small", [
                  _vm._v(
                    "Last Login: " +
                      _vm._s(
                        _vm.formatLocaleDate(_vm.selected.current_sign_in_at)
                      )
                  ),
                ]),
                _vm._v(" "),
                _vm.currentUserIsAdmin && _vm.airmeetEnabled
                  ? _c(
                      "b-button",
                      {
                        staticClass: "mt-2",
                        attrs: {
                          variant: "warning",
                          disabled: !_vm.selected.integrations.airmeet,
                        },
                        on: { click: _vm.resyncAirmeet },
                      },
                      [_vm._v("Airmeet re-sync completed")]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("person-edit-modal", {
            attrs: {
              id: "person-top-modal",
              "body-class": "formscroll",
              person: _vm.selected,
              data: _vm.editData,
              validate: true,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "modal-title",
                  fn: function () {
                    return [
                      _vm._v(
                        "Edit Profile - " + _vm._s(_vm.selected.published_name)
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "default",
                  fn: function (ref) {
                    var fields = ref.fields
                    return [
                      _c("validation-provider", {
                        attrs: { name: "Name", rules: "required" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var errors = ref.errors
                                var dirty = ref.dirty
                                var validated = ref.validated
                                var valid = ref.valid
                                return [
                                  _c(
                                    "b-form-group",
                                    { attrs: { label: "Name" } },
                                    [
                                      _c("b-form-input", {
                                        attrs: {
                                          type: "text",
                                          "aria-describedby":
                                            "name-invalid-feedback",
                                          state:
                                            dirty || validated
                                              ? valid
                                                ? null
                                                : false
                                              : null,
                                          required: "",
                                        },
                                        model: {
                                          value: fields.name,
                                          callback: function ($$v) {
                                            _vm.$set(fields, "name", $$v)
                                          },
                                          expression: "fields.name",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "b-form-invalid-feedback",
                                        {
                                          attrs: {
                                            id: "name-invalid-feedback",
                                          },
                                        },
                                        [_vm._v(_vm._s(errors[0]))]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      }),
                      _vm._v(" "),
                      _c(
                        "validation-provider",
                        { attrs: { name: "Pseudonym" } },
                        [
                          _c(
                            "b-form-group",
                            { attrs: { label: "Pseudonym" } },
                            [
                              _c("b-form-input", {
                                attrs: { type: "text" },
                                model: {
                                  value: fields.pseudonym,
                                  callback: function ($$v) {
                                    _vm.$set(fields, "pseudonym", $$v)
                                  },
                                  expression: "fields.pseudonym",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "validation-provider",
                        { attrs: { name: "Pronouns" } },
                        [
                          _c(
                            "b-form-group",
                            { attrs: { label: "Pronouns" } },
                            [
                              _c("b-form-input", {
                                attrs: { type: "text" },
                                model: {
                                  value: fields.pronouns,
                                  callback: function ($$v) {
                                    _vm.$set(fields, "pronouns", $$v)
                                  },
                                  expression: "fields.pronouns",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "validation-provider",
                        { attrs: { name: "Willing to moderate" } },
                        [
                          _c(
                            "b-form-group",
                            [
                              _c(
                                "b-form-checkbox",
                                {
                                  attrs: { switch: "" },
                                  model: {
                                    value: fields.willing_to_moderate,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        fields,
                                        "willing_to_moderate",
                                        $$v
                                      )
                                    },
                                    expression: "fields.willing_to_moderate",
                                  },
                                },
                                [_vm._v("Willing to moderate session")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ],
              null,
              false,
              1816274143
            ),
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }