var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        size: "xl",
        title: _vm.title,
        id: "mailing-preview",
        scrollable: "",
        "ok-title": "Close",
        "ok-only": "",
      },
      on: { show: _vm.onShow },
    },
    [
      _c(
        "div",
        [
          _c(
            "b-button",
            {
              attrs: { variant: "primary", disabled: !_vm.posn },
              on: { click: _vm.onFirst },
            },
            [_vm._v("<<")]
          ),
          _vm._v(" "),
          _c(
            "b-button",
            {
              attrs: { variant: "primary", disabled: !_vm.posn },
              on: { click: _vm.onPrev },
            },
            [_vm._v("<")]
          ),
          _vm._v(" "),
          _vm.mailing.emails.length > 0
            ? _c("div", { staticClass: "d-inline" }, [
                _vm._v(
                  "\n      " + _vm._s(_vm.mailing.emails[_vm.posn]) + "\n    "
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "b-button",
            {
              attrs: {
                variant: "primary",
                disabled: _vm.posn === _vm.mailing.emails.length - 1,
              },
              on: { click: _vm.onNext },
            },
            [_vm._v(">")]
          ),
          _vm._v(" "),
          _c(
            "b-button",
            {
              attrs: {
                variant: "primary",
                disabled: _vm.posn === _vm.mailing.emails.length - 1,
              },
              on: { click: _vm.onLast },
            },
            [_vm._v(">>")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.preview
        ? _c("div", { staticClass: "mt-3" }, [
            _vm._v("\n    To: " + _vm._s(_vm.preview.to)),
            _c("br"),
            _vm._v("\n    Subject: " + _vm._s(_vm.preview.subject)),
            _c("br"),
            _vm._v(" "),
            _c("p", { domProps: { innerHTML: _vm._s(_vm.preview.content) } }),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }