var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("p", [
        _vm._v("In order to protect your account, make sure your password:"),
      ]),
      _vm._v(" "),
      _c("ul", [
        _c("li", [_vm._v("Is 6 or more characters")]),
        _vm._v(" "),
        _c("li", [_vm._v('Does not contain the word "password"')]),
        _vm._v(" "),
        _c("li", [_vm._v("Does not contain your email address")]),
        _vm._v(" "),
        _c("li", [_vm._v("Is not one of your most recent passwords")]),
        _vm._v(" "),
        _c("li", [
          _vm._v(
            "\n      Password must not be a commonly used password or easily guessable.\n      Planorama makes use of the service\n      "
          ),
          _c(
            "a",
            {
              attrs: { href: "https://haveibeenpwned.com/", target: "_blank" },
            },
            [_vm._v("haveibeenpwned.com")]
          ),
          _vm._v("\n      which is provided under the "),
          _c(
            "a",
            {
              attrs: {
                href: "https://creativecommons.org/licenses/by/4.0/",
                target: "_blank",
              },
            },
            [_vm._v("CC BY 4.0")]
          ),
          _vm._v(" license.\n    "),
        ]),
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v("\n    Optionally, we recommend you follow the\n    "),
        _c(
          "a",
          { attrs: { href: "https://xkcd.com/936/", target: "_blank" } },
          [_vm._v("Correct Horse Battery Staple")]
        ),
        _vm._v("\n    paradigm.\n  "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }