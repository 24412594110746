var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "new-password" },
    [
      _c("h3", [_vm._v("Reset Password")]),
      _vm._v(" "),
      _c("pw-requirements"),
      _vm._v(" "),
      _c(
        "b-form",
        { on: { submit: _vm.onSubmit } },
        [
          _c("b-alert", {
            attrs: { show: _vm.error.visible, variant: "danger" },
            domProps: { innerHTML: _vm._s(_vm.error.text) },
          }),
          _vm._v(" "),
          _c("login-password-field", {
            attrs: {
              "new-password": true,
              validateNow: _vm.form.password.validate,
            },
            on: {
              validated: function ($event) {
                _vm.form.password.valid = $event
              },
            },
            model: {
              value: _vm.person.password,
              callback: function ($$v) {
                _vm.$set(_vm.person, "password", $$v)
              },
              expression: "person.password",
            },
          }),
          _vm._v(" "),
          _c("login-password-field", {
            attrs: {
              confirmation: true,
              "must-match": _vm.person.password,
              validateNow: _vm.form.passwordConfirmation.validate,
            },
            on: {
              validated: function ($event) {
                _vm.form.passwordConfirmation.valid = $event
              },
            },
            model: {
              value: _vm.person.password_confirmation,
              callback: function ($$v) {
                _vm.$set(_vm.person, "password_confirmation", $$v)
              },
              expression: "person.password_confirmation",
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "d-flex flex-row-reverse" },
            [
              _c(
                "b-button",
                {
                  staticClass: "px-5",
                  attrs: { type: "submit", variant: "primary" },
                },
                [_vm._v("Change Password")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }