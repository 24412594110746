var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.declinedRejected
    ? _c(
        "div",
        [
          _c(
            "b-overlay",
            {
              attrs: {
                show: !_vm.results,
                "spinner-variant": "primary",
                variant: "white",
                opacity: "1",
              },
            },
            [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary mb-2",
                  on: { click: _vm.copy },
                },
                [_vm._v("Copy to Clipboard")]
              ),
              _vm.copySuccess
                ? _c("span", { staticClass: "text-success ml-2" }, [
                    _vm._v("Copied!"),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { ref: "copybox" },
                _vm._l(_vm.results, function (sessions, id) {
                  return _c("div", { key: id }, [
                    _c("h6", [_vm._v(_vm._s(id))]),
                    _vm._v(" "),
                    _c(
                      "ul",
                      [
                        !sessions.length
                          ? _c(
                              "span",
                              { staticClass: "font-italic text-muted" },
                              [_vm._v("No Sessions")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm._l(sessions, function (session) {
                          return _c("li", { key: session }, [
                            _vm._v(_vm._s(session)),
                          ])
                        }),
                      ],
                      2
                    ),
                  ])
                }),
                0
              ),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }