var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.showControls
        ? _c(
            "div",
            { staticClass: "d-flex justify-content-between my-3" },
            [
              _vm.showSearch
                ? _c("search-vue", {
                    ref: "table-search",
                    staticClass: "w-75",
                    attrs: {
                      value: _vm.filter,
                      columns: _vm.columns,
                      stateName: _vm.stateName,
                    },
                    on: { change: _vm.onSearchChanged },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "alternate-search-tab",
                          fn: function () {
                            return [
                              _vm.$slots["alternate-search"]
                                ? _c(
                                    "b-tab",
                                    {
                                      ref: "alternate-search-tab",
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "title",
                                            fn: function () {
                                              return [
                                                _vm._t(
                                                  "alternate-search-title",
                                                  function () {
                                                    return [
                                                      _vm._v(
                                                        "\n              Alternate Search\n            "
                                                      ),
                                                    ]
                                                  }
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    },
                                    [_vm._v(" "), _vm._t("alternate-search")],
                                    2
                                  )
                                : _vm._e(),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      true
                    ),
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.showSearch ? _c("div", { staticClass: "w-75" }) : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "d-flex justify-content-end" }, [
                _vm.showClone
                  ? _c(
                      "div",
                      {
                        staticClass: "d-inline mx-1",
                        attrs: { title: "clone" },
                      },
                      [
                        _c(
                          "b-button",
                          {
                            attrs: {
                              variant: "primary",
                              title: "Duplicate",
                              disabled: _vm.selected_items.length === 0,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.$emit("clone")
                              },
                            },
                          },
                          [_c("b-icon-files")],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.showRefresh
                  ? _c(
                      "div",
                      {
                        staticClass: "d-inline mx-1",
                        attrs: { title: "refresh" },
                      },
                      [
                        _c(
                          "b-button",
                          {
                            attrs: { variant: "primary", title: "Refresh" },
                            on: { click: _vm.onRefresh },
                          },
                          [_c("b-icon-arrow-repeat")],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.showAdd
                  ? _c(
                      "div",
                      {
                        staticClass: "d-inline mx-1",
                        attrs: { title: "newval" },
                      },
                      [
                        _c(
                          "b-button",
                          {
                            attrs: { variant: "primary", title: "New" },
                            on: {
                              click: function ($event) {
                                return _vm.$emit("new")
                              },
                            },
                          },
                          [_c("b-icon-plus")],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.showView
                  ? _c(
                      "div",
                      {
                        staticClass: "d-inline mx-1",
                        attrs: { title: "show" },
                      },
                      [
                        _c(
                          "b-button",
                          {
                            attrs: { variant: "primary", title: "View" },
                            on: {
                              click: function ($event) {
                                return _vm.$emit("view")
                              },
                            },
                          },
                          [_c("b-icon-eye")],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.showSettings
                  ? _c(
                      "div",
                      { staticClass: "d-inline", attrs: { title: "Settings" } },
                      [
                        _c(
                          "b-button",
                          { attrs: { disabled: "" } },
                          [_c("b-icon-gear-fill")],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "d-flex align-items-center" },
        [
          _vm._t("left-controls", null, {
            editableIds: _vm.editableIds,
            total: _vm.fullTotalRows,
          }),
          _vm._v(" "),
          _c("b-pagination", {
            staticClass: "mb-0 mr-3 ml-auto",
            attrs: {
              "total-rows": _vm.totalRows,
              "per-page": _vm.perPage,
              "first-text": "First",
              "last-text": "Last",
              "prev-text": "Prev",
              "next-text": "Next",
            },
            model: {
              value: _vm.currentPage,
              callback: function ($$v) {
                _vm.currentPage = $$v
              },
              expression: "currentPage",
            },
          }),
          _vm._v(" "),
          _c(
            "b-form",
            {
              attrs: { inline: "" },
              on: {
                submit: function ($event) {
                  return _vm.setCurrentPage()
                },
              },
            },
            [
              _c(
                "b-form-group",
                { attrs: { label: "Go to page", "label-cols": "auto" } },
                [
                  _c("b-input", {
                    staticStyle: { width: "5.5rem" },
                    attrs: { type: "number" },
                    model: {
                      value: _vm.tempCurrentPage,
                      callback: function ($$v) {
                        _vm.tempCurrentPage = $$v
                      },
                      expression: "tempCurrentPage",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  staticClass: "ml-1",
                  attrs: { type: "submit", variant: "primary" },
                },
                [_vm._v("Go")]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c("div", { staticClass: "d-flex mb-1" }, [
        _vm.totalRows != _vm.fullTotalRows
          ? _c("span", [_vm._v("Search Results: " + _vm._s(_vm.totalRows))])
          : _vm._e(),
        _vm._v(" "),
        _c("span", { staticClass: "ml-auto" }, [
          _vm._v(_vm._s(_vm.countCaption)),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "b-table",
        {
          ref: "table",
          attrs: {
            hover: "",
            bordered: "",
            responsive: "",
            selectable: "",
            small: "",
            striped: "",
            "sticky-header": _vm.stickyHeader,
            fields: _vm.tableColumns,
            "selected-variant": "primary",
            "select-mode": _vm.useSelectMode,
            items: _vm.sortedCollection,
            "no-local-sorting": true,
            "sort-by": _vm.sortBy,
            "sort-desc": _vm.sortDesc,
            busy: _vm.tableBusy,
          },
          on: {
            "row-selected": _vm.onRowSelected,
            "sort-changed": _vm.onSortChanged,
          },
          scopedSlots: _vm._u(
            [
              {
                key: "table-busy",
                fn: function () {
                  return [
                    _c(
                      "div",
                      { staticClass: "d-flex justify-content-center" },
                      [_c("b-spinner", { attrs: { variant: "primary" } })],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "head(selected)",
                fn: function () {
                  return [
                    _c("b-form-checkbox", {
                      attrs: {
                        name: "select-all-checkbox",
                        value: "select_all",
                        "unchecked-value": "select_none",
                        checked: _vm.isSelectAll,
                      },
                      on: { change: _vm.onSelectAll },
                    }),
                  ]
                },
                proxy: true,
              },
              {
                key: "cell(selected)",
                fn: function (row) {
                  return [
                    row.item
                      ? _c("b-form-checkbox", {
                          attrs: {
                            name: "select-row-checkbox",
                            value: "select:" + row.item.id,
                            "unchecked-value": "unselect:" + row.item.id,
                            checked: _vm.isChecked(row.item.id),
                          },
                          on: { change: _vm.onSelectRow },
                        })
                      : _vm._e(),
                  ]
                },
              },
              _vm._l(_vm.$scopedSlots, function (_, name) {
                return {
                  key: name,
                  fn: function (slotData) {
                    return [_vm._t(name, null, null, slotData)]
                  },
                }
              }),
            ],
            null,
            true
          ),
        },
        [
          _vm._v(" "),
          _vm._v(" "),
          _vm._v(" "),
          _vm._l(_vm.$slots, function (_, name) {
            return _vm._t(name, null, { slot: name })
          }),
        ],
        2
      ),
      _vm._v(" "),
      _vm.showBottomControls
        ? _c("div", { staticClass: "d-flex mb-1" }, [
            _vm.totalRows != _vm.fullTotalRows
              ? _c("span", [_vm._v("Search Results: " + _vm._s(_vm.totalRows))])
              : _vm._e(),
            _vm._v(" "),
            _c("span", { staticClass: "ml-auto" }, [
              _vm._v(_vm._s(_vm.countCaption)),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.showBottomControls
        ? _c("b-pagination", {
            staticClass: "d-flex justify-content-end",
            attrs: {
              "total-rows": _vm.totalRows,
              "per-page": _vm.perPage,
              "first-text": "First",
              "last-text": "Last",
              "prev-text": "Prev",
              "next-text": "Next",
            },
            model: {
              value: _vm.currentPage,
              callback: function ($$v) {
                _vm.currentPage = $$v
              },
              expression: "currentPage",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showBottomControls
        ? _c(
            "div",
            { staticClass: "d-flex justify-content-end" },
            [
              _c(
                "b-form-group",
                {
                  staticClass: "mb-0",
                  attrs: { label: "Number of Records", "label-cols": "auto" },
                },
                [
                  _c("b-form-select", {
                    attrs: { options: [10, 20, 50] },
                    model: {
                      value: _vm.perPage,
                      callback: function ($$v) {
                        _vm.perPage = $$v
                      },
                      expression: "perPage",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }