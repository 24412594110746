var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid scrollable" },
    [
      _c("h1", [_vm._v("Registration Sync Management")]),
      _vm._v(" "),
      _c(
        "b-button",
        {
          directives: [
            {
              name: "b-modal",
              rawName: "v-b-modal.confirm-reg-sync",
              modifiers: { "confirm-reg-sync": true },
            },
          ],
          attrs: { variant: "primary", size: "sm" },
        },
        [_vm._v("Registration Synchronize")]
      ),
      _vm._v(" "),
      _c("person-sync-table"),
      _vm._v(" "),
      _c("plano-modal", {
        attrs: { id: "confirm-reg-sync" },
        on: {
          ok: function ($event) {
            return _vm.synchronizeSchedule()
          },
        },
        scopedSlots: _vm._u([
          {
            key: "modal-title",
            fn: function () {
              return [_vm._v("Synchonize Registration Info")]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }