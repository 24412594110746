var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-form-checkbox",
        {
          attrs: { disabled: !_vm.canLinkField },
          on: { change: _vm.unlinkIfLinked },
          model: {
            value: _vm.enabled,
            callback: function ($$v) {
              _vm.enabled = $$v
            },
            expression: "enabled",
          },
        },
        [_vm._v("Linked")]
      ),
      _vm._v(" "),
      _c("b-form-select", {
        attrs: {
          disabled: !_vm.enabled,
          "select-size": 4,
          options: _vm.linkedFieldsOptions,
        },
        on: { change: _vm.linkField },
        model: {
          value: _vm.question.linked_field,
          callback: function ($$v) {
            _vm.$set(_vm.question, "linked_field", $$v)
          },
          expression: "question.linked_field",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }