var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "session-conflicts" },
    [
      _c("b-overlay", { attrs: { show: _vm.loading, rounded: "sm" } }, [
        _c("div", { staticClass: "session-conflicts-list" }, [
          _vm.displaySessionInfo && _vm.sessionId
            ? _c("div", [
                _c("strong", [_vm._v(_vm._s(_vm.sessionTitle))]),
                _c("br"),
                _vm._v("\n        " + _vm._s(_vm.sessionTime) + "\n      "),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.conflicts.length > 0 || _vm.conflicts_with.length > 0
            ? _c(
                "div",
                { staticClass: "ml-2" },
                [
                  _vm._l(_vm.conflicts, function (conflict) {
                    return _c(
                      "div",
                      {
                        key: conflict.id,
                        staticClass: "session-conflict mb-1",
                      },
                      [
                        _c("div", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.conflict_type_string(conflict)
                            ),
                          },
                        }),
                        _vm._v(" "),
                        _c("ignore-button", {
                          on: {
                            click: function ($event) {
                              return _vm.ignore(conflict)
                            },
                          },
                        }),
                      ],
                      1
                    )
                  }),
                  _vm._v(" "),
                  _vm._l(_vm.conflicts_with, function (conflict) {
                    return _c(
                      "div",
                      {
                        key: conflict.id,
                        staticClass: "session-conflict mb-1",
                      },
                      [
                        _c("div", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.conflict_type_string(conflict, true)
                            ),
                          },
                        }),
                        _vm._v(" "),
                        _c("ignore-button", {
                          on: {
                            click: function ($event) {
                              return _vm.ignore(conflict)
                            },
                          },
                        }),
                      ],
                      1
                    )
                  }),
                ],
                2
              )
            : _vm.sessionId &&
              _vm.conflicts.length === 0 &&
              _vm.conflicts_with.length === 0
            ? _c("div", { staticClass: "ml-2 text-muted font-italic" }, [
                _vm._v(
                  "\n        There are no conflicts for this session\n      "
                ),
              ])
            : _vm._e(),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }