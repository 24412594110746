var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid scrollable" },
    [
      _c("h1", [_vm._v("Preview features")]),
      _vm._v(" "),
      _c("h2", [_vm._v("Registration Info (from Reg/clyde)")]),
      _vm._v(" "),
      _c("person-sync-table"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }