var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "col-12 px-0" },
    [
      _c(
        "b-navbar",
        {
          class: [
            "justify-content-between",
            { "not-production": _vm.currentSettings.env !== "production" },
          ],
          attrs: { id: "top-navbar", toggleable: "lg", type: "light" },
        },
        [
          _c(
            "b-navbar-brand",
            {
              class: {
                "text-warning": _vm.currentSettings.env !== "production",
              },
              attrs: { to: "/" },
            },
            [
              _vm._v("Planorama"),
              _vm.currentSettings.env !== "production"
                ? _c("span", { staticClass: "h1" }, [
                    _vm._v(" - " + _vm._s(_vm.currentSettings.env)),
                  ])
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "d-flex flex-column h-100" },
            [
              _c("div", { staticClass: "text-light mb-2" }, [
                _vm._v("Logged in as: " + _vm._s(_vm.currentUserDisplay)),
              ]),
              _vm._v(" "),
              _c(
                "b-navbar-nav",
                { staticClass: "ml-auto" },
                [
                  _c(
                    "b-nav-form",
                    [
                      _c("help-link"),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass:
                            "btn btn-link text-light my-2 my-sm-0 mx-2",
                          attrs: {
                            href: "https://planoramaevents.github.io/planorama",
                            target: "_blank",
                          },
                        },
                        [_vm._v("About")]
                      ),
                      _vm._v(" "),
                      _vm.loggedIn
                        ? _c(
                            "b-button",
                            {
                              attrs: { variant: "primary" },
                              on: { click: _vm.logout },
                            },
                            [_vm._v("Logout")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("b-toaster", { attrs: { id: "planotoast", name: "planotoast" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }