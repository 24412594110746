var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-form",
    { ref: "add-agreement-form" },
    [
      _c("model-field", {
        attrs: { label: "Title", type: "text", stateless: "" },
        model: {
          value: _vm.agreementData.title,
          callback: function ($$v) {
            _vm.$set(_vm.agreementData, "title", $$v)
          },
          expression: "agreementData.title",
        },
      }),
      _vm._v(" "),
      _c("label", [_vm._v("Body")]),
      _vm._v(" "),
      _c("plano-editor", {
        attrs: { type: "classic", disabled: _vm.readOnly },
        model: {
          value: _vm.agreementData.terms,
          callback: function ($$v) {
            _vm.$set(_vm.agreementData, "terms", $$v)
          },
          expression: "agreementData.terms",
        },
      }),
      _vm._v(" "),
      _c("label", [_vm._v("Agreement Type:")]),
      _vm._v(" "),
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.agreementData.agreement_type,
              expression: "agreementData.agreement_type",
            },
          ],
          staticStyle: { "padding-right": "15px" },
          on: {
            change: function ($event) {
              var $$selectedVal = Array.prototype.filter
                .call($event.target.options, function (o) {
                  return o.selected
                })
                .map(function (o) {
                  var val = "_value" in o ? o._value : o.value
                  return val
                })
              _vm.$set(
                _vm.agreementData,
                "agreement_type",
                $event.target.multiple ? $$selectedVal : $$selectedVal[0]
              )
            },
          },
        },
        _vm._l(_vm.currentSettings.agreement_types, function (type) {
          return _c(
            "option",
            { domProps: { selected: type === _vm.selected_agreement_type } },
            [_vm._v(_vm._s(type))]
          )
        }),
        0
      ),
      _vm._v(" "),
      _c("label", [_vm._v("Target:")]),
      _vm._v(" "),
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.agreementData.target,
              expression: "agreementData.target",
            },
          ],
          on: {
            change: function ($event) {
              var $$selectedVal = Array.prototype.filter
                .call($event.target.options, function (o) {
                  return o.selected
                })
                .map(function (o) {
                  var val = "_value" in o ? o._value : o.value
                  return val
                })
              _vm.$set(
                _vm.agreementData,
                "target",
                $event.target.multiple ? $$selectedVal : $$selectedVal[0]
              )
            },
          },
        },
        _vm._l(_vm.agreement_enums, function (role) {
          return _c(
            "option",
            { domProps: { selected: role === _vm.selected_target } },
            [_vm._v(_vm._s(role))]
          )
        }),
        0
      ),
      _vm._v(" "),
      _vm.showButtons
        ? _c(
            "div",
            { staticClass: "d-flex justify-content-end" },
            [
              _c(
                "b-button",
                { attrs: { variant: "link" }, on: { click: _vm.clear } },
                [_vm._v("Cancel")]
              ),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  attrs: { variant: "primary" },
                  on: { click: _vm.saveAgreement },
                },
                [_vm._v("Save")]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }