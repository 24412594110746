var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-tab", { attrs: { title: "Settings" } }, [
    _vm.survey
      ? _c(
          "div",
          { staticClass: "container" },
          [
            _c(
              "b-row",
              [
                _c(
                  "b-col",
                  [
                    _c("span", { staticClass: "mr-2" }, [_vm._v("Closed")]),
                    _vm._v(" "),
                    _c("b-form-checkbox", {
                      directives: [
                        {
                          name: "b-modal",
                          rawName: "v-b-modal.confirmPublish",
                          modifiers: { confirmPublish: true },
                        },
                      ],
                      staticClass: "mr-0",
                      attrs: { inline: "", switch: "", size: "lg" },
                      model: {
                        value: _vm.publishVal,
                        callback: function ($$v) {
                          _vm.publishVal = $$v
                        },
                        expression: "publishVal",
                      },
                    }),
                    _vm._v("\n        Published "),
                    _vm.survey.public
                      ? _c("span", [
                          _vm._v(
                            "on " +
                              _vm._s(
                                new Date(
                                  _vm.survey.published_on
                                ).toLocaleString()
                              )
                          ),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "survey-setting",
              {
                attrs: { bool: "", field: "unassigned" },
                model: {
                  value: _vm.survey.unassigned,
                  callback: function ($$v) {
                    _vm.$set(_vm.survey, "unassigned", $$v)
                  },
                  expression: "survey.unassigned",
                },
              },
              [
                _vm._v(
                  "\n      Allow this survey to be taken without assignment\n    "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "survey-setting",
              {
                attrs: { bool: "", field: "mandatory_star" },
                model: {
                  value: _vm.survey.mandatory_star,
                  callback: function ($$v) {
                    _vm.$set(_vm.survey, "mandatory_star", $$v)
                  },
                  expression: "survey.mandatory_star",
                },
              },
              [
                _vm._v("\n      Show star for required questions "),
                _c("small", [
                  _vm._v("(What is your name? "),
                  _c(
                    "span",
                    {
                      staticClass: "text-danger",
                      attrs: { title: "required" },
                    },
                    [_vm._v("*")]
                  ),
                  _vm._v(" )"),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "survey-setting",
              {
                attrs: { bool: "", disabled: "", field: "captcha" },
                model: {
                  value: _vm.survey.captcha,
                  callback: function ($$v) {
                    _vm.$set(_vm.survey, "captcha", $$v)
                  },
                  expression: "survey.captcha",
                },
              },
              [_vm._v("\n      Use CAPTCHA\n    ")]
            ),
            _vm._v(" "),
            _c(
              "survey-setting",
              {
                attrs: { bool: "", disabled: "", field: "numbered_questions" },
                model: {
                  value: _vm.survey.numbered_questions,
                  callback: function ($$v) {
                    _vm.$set(_vm.survey, "numbered_questions", $$v)
                  },
                  expression: "survey.numbered_questions",
                },
              },
              [_vm._v("\n      Show numbers on questions\n    ")]
            ),
            _vm._v(" "),
            _c(
              "survey-setting",
              {
                attrs: { bool: "", disabled: "", field: "branded" },
                model: {
                  value: _vm.survey.branded,
                  callback: function ($$v) {
                    _vm.$set(_vm.survey, "branded", $$v)
                  },
                  expression: "survey.branded",
                },
              },
              [_vm._v("\n      Show logo\n    ")]
            ),
            _vm._v(" "),
            _c(
              "survey-setting",
              {
                attrs: { text: "", field: "submit_string" },
                model: {
                  value: _vm.survey.submit_string,
                  callback: function ($$v) {
                    _vm.$set(_vm.survey, "submit_string", $$v)
                  },
                  expression: "survey.submit_string",
                },
              },
              [_vm._v("\n      Text for submit button\n    ")]
            ),
            _vm._v(" "),
            _c(
              "survey-setting",
              {
                attrs: { text: "", field: "thank_you" },
                model: {
                  value: _vm.survey.thank_you,
                  callback: function ($$v) {
                    _vm.$set(_vm.survey, "thank_you", $$v)
                  },
                  expression: "survey.thank_you",
                },
              },
              [_vm._v("\n      Confirmation message\n    ")]
            ),
            _vm._v(" "),
            _vm.survey
              ? _c(
                  "b-modal",
                  {
                    attrs: {
                      id: "confirmPublish",
                      "ok-title": "Yes",
                      "cancel-variant": "link",
                    },
                    on: { ok: _vm.togglePublish, cancel: _vm.cancelPublish },
                  },
                  [
                    _vm.survey.public
                      ? _c("p", [_vm._v(_vm._s(_vm.SURVEY_CONFIRM_CLOSE))])
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.survey.public
                      ? _c("p", [_vm._v(_vm._s(_vm.SURVEY_CONFIRM_PUBLISH))])
                      : _vm._e(),
                  ]
                )
              : _vm._e(),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    !_vm.survey ? _c("div", [_c("h3", [_vm._v("Loading...")])]) : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }