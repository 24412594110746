var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.exampleUrl
        ? _c("div", [
            _vm._v("\n    Example: "),
            _c("a", { attrs: { target: "_blank", href: _vm.exampleUrl } }, [
              _vm._v(_vm._s(_vm.exampleUrl)),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("label", [
        _vm._v("File\n    "),
        _c("input", {
          ref: "files",
          attrs: { type: "file", id: "files" },
          on: {
            change: function ($event) {
              return _vm.handleFilesUpload()
            },
          },
        }),
      ]),
      _vm._v(" "),
      _c(
        "b-form-checkbox",
        {
          model: {
            value: _vm.ignoreFirstLine,
            callback: function ($$v) {
              _vm.ignoreFirstLine = $$v
            },
            expression: "ignoreFirstLine",
          },
        },
        [_vm._v("\n    This file has a header\n  ")]
      ),
      _vm._v(" "),
      _vm.importMessage
        ? _c("div", [
            _c(
              "div",
              { staticClass: "border border-secondary d-flex p-2" },
              [
                _vm._t(
                  "import-details",
                  function () {
                    return [
                      _vm._v(
                        "\n        " + _vm._s(_vm.importMessage) + "\n      "
                      ),
                    ]
                  },
                  { importMessage: _vm.importMessage }
                ),
              ],
              2
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        [
          _c("b-table", {
            attrs: {
              striped: "",
              "sticky-header": "",
              "thead-class": "hidden_header",
              items: _vm.formattedSheetData,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "d-flex justify-content-end" },
        [
          _c(
            "b-button",
            { attrs: { variant: "link" }, on: { click: _vm.clear } },
            [_vm._v("Cancel")]
          ),
          _vm._v(" "),
          _c(
            "b-button",
            { attrs: { variant: "primary" }, on: { click: _vm.submitData } },
            [_vm._v("Save")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }