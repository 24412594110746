export const parameterNameModel = 'parameter_name';

export const parameterNameEndpoints = {
  [parameterNameModel]: 'parameter_name'
}

export const parameterNameStore = {
  selected: {
    [parameterNameModel]: undefined
  },
  getters: {
  }
}
