var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-inline-block" },
    [
      _c(
        "span",
        {
          staticClass: "d-inline-block",
          attrs: { id: _vm.spanId, tabindex: _vm.disabled ? 0 : -1 },
        },
        [_vm._t("default")],
        2
      ),
      _vm._v(" "),
      _vm.disabled
        ? _c(
            "b-tooltip",
            { attrs: { target: _vm.spanId, placement: "bottom" } },
            [_vm._v(_vm._s(_vm.disabledTooltip))]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }