var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "scrollable" },
    [
      _c("h1", [_vm._v("Configurations")]),
      _vm._v(" "),
      _c(
        "b-tabs",
        { attrs: { "content-class": "mt-3 container-fluid" } },
        [
          _c(
            "b-tab",
            { attrs: { title: "Page Content Editor" } },
            [_c("page-content-editor")],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }