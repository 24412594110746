var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("validation-provider", {
    attrs: {
      mode: "eager",
      name: _vm.label,
      rules: {
        regex: /.+@.+\..+/,
        required: _vm.required,
      },
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (ref) {
          var errors = ref.errors
          return [
            _c(
              "b-form-group",
              {
                class: { "w-50": _vm.answerable },
                attrs: {
                  id: _vm.groupId,
                  label: _vm.label,
                  "label-for": _vm.id,
                  "invalid-feedback": errors[0],
                  state: _vm.calcValid(errors),
                  "label-sr-only": _vm.labelSrOnly,
                },
              },
              [
                _c("b-form-input", {
                  attrs: {
                    id: _vm.id,
                    value: _vm.value,
                    type: "text",
                    state: _vm.calcValid(errors),
                    disabled: _vm.disabled,
                    "aria-describedBy": _vm.ariaDescribedBy || _vm.groupId,
                  },
                  on: {
                    input: function ($event) {
                      return _vm.$emit("input", $event)
                    },
                    blur: function ($event) {
                      return _vm.$emit("blur", $event)
                    },
                  },
                }),
              ],
              1
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }