var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page m-3" },
    [
      _c("div", { staticClass: "page-label" }, [
        _c("span", { class: [_vm.singlePage ? "d-none" : "d-inline-block"] }, [
          _vm._v("Page " + _vm._s(_vm.i + 1) + " of " + _vm._s(_vm.n) + " "),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          class: ["page-title", "p-3", "border", { selected: _vm.isSelected }],
          on: { click: _vm.onPageClick },
        },
        [
          _vm.isSelected
            ? _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.i === 0 ? "Display Title" : "Page Title",
                    "label-for": "page-title-" + _vm.page.id,
                  },
                },
                [
                  _c("b-form-input", {
                    attrs: { id: "page-title-" + _vm.page.id, type: "text" },
                    on: {
                      change: function ($event) {
                        return _vm.savePage(_vm.page)
                      },
                    },
                    model: {
                      value: _vm.page.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.page, "title", $$v)
                      },
                      expression: "page.title",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isSelected && _vm.i !== 0
            ? _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-12 d-flex justify-content-end" },
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "mr-2",
                        attrs: { variant: "info", title: "Merge page up" },
                        on: { click: _vm.mergePageUp },
                      },
                      [_c("b-icon-arrow-up-circle-fill")],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "b-button",
                      {
                        directives: [
                          {
                            name: "b-modal",
                            rawName: "v-b-modal",
                            value: _vm.deleteModalId,
                            expression: "deleteModalId",
                          },
                        ],
                        attrs: { variant: "info", title: "Delete page" },
                      },
                      [_c("b-icon-trash")],
                      1
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          !_vm.isSelected
            ? _c("h3", [_vm._v(_vm._s(_vm.page.title))])
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "draggable",
        {
          attrs: { handle: ".handle" },
          on: { end: _vm.saveQuestionOrder },
          model: {
            value: _vm.questions,
            callback: function ($$v) {
              _vm.questions = $$v
            },
            expression: "questions",
          },
        },
        _vm._l(_vm.questions, function (q) {
          return _c("edit-survey-question", {
            key: q.id,
            attrs: { question: q },
          })
        }),
        1
      ),
      _vm._v(" "),
      !_vm.isLastPage(_vm.page.id)
        ? _c(
            "div",
            { staticClass: "mt-3" },
            [
              _vm._v("\n    After page " + _vm._s(_vm.i + 1) + "\n    "),
              _c("next-page-picker", {
                staticClass: "ml-1",
                attrs: { "for-page": _vm.page.id, id: _vm.pagePickerId },
                on: { change: _vm.updatePage },
                model: {
                  value: _vm.page.next_page_id,
                  callback: function ($$v) {
                    _vm.$set(_vm.page, "next_page_id", $$v)
                  },
                  expression: "page.next_page_id",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isSelected
        ? _c(
            "b-modal",
            {
              attrs: {
                id: _vm.deleteModalId,
                "ok-title": "Yes",
                "cancel-variant": "link",
                title: "Delete page and questions?",
              },
              on: {
                ok: function ($event) {
                  return _vm.deletePage(_vm.page)
                },
              },
            },
            [
              _c("p", [_vm._v(_vm._s(_vm.SURVEY_CONFIRM_DELETE_PAGE_1))]),
              _vm._v(" "),
              _c("p", [_vm._v(_vm._s(_vm.SURVEY_CONFIRM_DELETE_PAGE_2))]),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }