var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ValidationObserver", {
    scopedSlots: _vm._u(
      [
        {
          key: "default",
          fn: function (ref) {
            var handleSubmit = ref.handleSubmit
            var invalid = ref.invalid
            var pristine = ref.pristine
            return [
              _c(
                "edit-modal",
                _vm._g(
                  _vm._b(
                    {
                      attrs: {
                        "ok-disabled": _vm.validate
                          ? invalid || pristine
                          : false,
                      },
                      on: {
                        show: function ($event) {
                          return _vm.mirrorFromPerson()
                        },
                        ok: function ($event) {
                          handleSubmit(_vm.patchPerson())
                        },
                      },
                      scopedSlots: _vm._u(
                        [
                          _vm._l(_vm.$scopedSlots, function (_, name) {
                            return {
                              key: name,
                              fn: function (slotData) {
                                return [
                                  _vm._t(
                                    name,
                                    null,
                                    null,
                                    Object.assign({}, slotData, {
                                      fields: _vm.data,
                                    })
                                  ),
                                ]
                              },
                            }
                          }),
                        ],
                        null,
                        true
                      ),
                    },
                    "edit-modal",
                    _vm.$attrs,
                    false
                  ),
                  _vm.$listeners
                ),
                [
                  _vm._l(_vm.$slots, function (_, name) {
                    return _vm._t(name, null, { slot: name })
                  }),
                ],
                2
              ),
            ]
          },
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }