var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "py-2" }, [
    _c(
      "small",
      [
        _vm._v(_vm._s(_vm.LOGIN_CLICK_TO_AGREE) + " "),
        _c("privacy-policy-link"),
        _vm._v("."),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }