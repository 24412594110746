var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c("div", { staticClass: "d-flex flex-row mt-3" }, [
        _c(
          "div",
          { staticClass: "w-50 mr-2" },
          [
            _c(
              "h5",
              [
                _vm._v("Demographics "),
                _vm.canEditInfo && !_vm.readOnly
                  ? _c("edit-button", {
                      directives: [
                        {
                          name: "b-modal",
                          rawName: "v-b-modal.person-demo-modal",
                          modifiers: { "person-demo-modal": true },
                        },
                      ],
                    })
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _vm.showDemoAndCommunity
              ? _c("dl-person", { attrs: { fields: _vm.demoFields } })
              : _c("div", [_vm._v("Coming Soon")]),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "w-50" },
          [
            _c(
              "h5",
              [
                _vm._v("Community memberships "),
                _vm.canEditInfo && !_vm.readOnly
                  ? _c("edit-button", {
                      directives: [
                        {
                          name: "b-modal",
                          rawName: "v-b-modal.person-community-modal",
                          modifiers: { "person-community-modal": true },
                        },
                      ],
                    })
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _vm.showDemoAndCommunity
              ? _c("dl-person", { attrs: { fields: _vm.communityFields } })
              : _c("div", [_vm._v("Coming Soon")]),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("person-edit-modal", {
        attrs: {
          id: "person-demo-modal",
          person: _vm.selected,
          data: _vm.demoData,
          validate: true,
        },
        scopedSlots: _vm._u([
          {
            key: "modal-title",
            fn: function () {
              return [
                _vm._v(
                  "Edit Demographics - " + _vm._s(_vm.selected.published_name)
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "default",
            fn: function (ref) {
              var fields = ref.fields
              return [
                !_vm.isHidden("ethnicity")
                  ? _c(
                      "validation-provider",
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label: _vm.PROFILE_FIELD_LABELS.ethnicity,
                            },
                          },
                          [
                            _c("b-form-input", {
                              attrs: { type: "text" },
                              model: {
                                value: fields.ethnicity,
                                callback: function ($$v) {
                                  _vm.$set(fields, "ethnicity", $$v)
                                },
                                expression: "fields.ethnicity",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                !_vm.isHidden("gender")
                  ? _c(
                      "validation-provider",
                      [
                        _c(
                          "b-form-group",
                          { attrs: { label: _vm.PROFILE_FIELD_LABELS.gender } },
                          [
                            _c("b-form-input", {
                              attrs: { type: "text" },
                              model: {
                                value: fields.gender,
                                callback: function ($$v) {
                                  _vm.$set(fields, "gender", $$v)
                                },
                                expression: "fields.gender",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                !_vm.isHidden("age_at_convention")
                  ? _c(
                      "validation-provider",
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label: _vm.PROFILE_FIELD_LABELS.age_at_convention,
                            },
                          },
                          [
                            _c("b-form-input", {
                              attrs: { type: "text" },
                              model: {
                                value: fields.age_at_convention,
                                callback: function ($$v) {
                                  _vm.$set(fields, "age_at_convention", $$v)
                                },
                                expression: "fields.age_at_convention",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                !_vm.isHidden("romantic_sexual_orientation")
                  ? _c(
                      "validation-provider",
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label:
                                _vm.PROFILE_FIELD_LABELS
                                  .romantic_sexual_orientation,
                            },
                          },
                          [
                            _c("b-form-textarea", {
                              model: {
                                value: fields.romantic_sexual_orientation,
                                callback: function ($$v) {
                                  _vm.$set(
                                    fields,
                                    "romantic_sexual_orientation",
                                    $$v
                                  )
                                },
                                expression:
                                  "fields.romantic_sexual_orientation",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
      _vm._v(" "),
      _c("person-edit-modal", {
        attrs: {
          id: "person-community-modal",
          person: _vm.selected,
          data: _vm.communityData,
          validate: true,
        },
        scopedSlots: _vm._u([
          {
            key: "modal-title",
            fn: function () {
              return [
                _vm._v(
                  "Edit community memberships - " +
                    _vm._s(_vm.selected.published_name)
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "default",
            fn: function (ref) {
              var fields = ref.fields
              return [
                !_vm.isHidden("othered")
                  ? _c(
                      "validation-provider",
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: { label: _vm.PROFILE_FIELD_LABELS.othered },
                          },
                          [
                            _c("b-form-textarea", {
                              model: {
                                value: fields.othered,
                                callback: function ($$v) {
                                  _vm.$set(fields, "othered", $$v)
                                },
                                expression: "fields.othered",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                !_vm.isHidden("indigenous")
                  ? _c(
                      "validation-provider",
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label: _vm.PROFILE_FIELD_LABELS.indigenous,
                            },
                          },
                          [
                            _c("b-form-textarea", {
                              model: {
                                value: fields.indigenous,
                                callback: function ($$v) {
                                  _vm.$set(fields, "indigenous", $$v)
                                },
                                expression: "fields.indigenous",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                !_vm.isHidden("black_diaspora")
                  ? _c(
                      "validation-provider",
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label: _vm.PROFILE_FIELD_LABELS.black_diaspora,
                            },
                          },
                          [
                            _c("b-form-textarea", {
                              model: {
                                value: fields.black_diaspora,
                                callback: function ($$v) {
                                  _vm.$set(fields, "black_diaspora", $$v)
                                },
                                expression: "fields.black_diaspora",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                !_vm.isHidden("global_diaspora")
                  ? _c(
                      "validation-provider",
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label: _vm.PROFILE_FIELD_LABELS.global_diaspora,
                            },
                          },
                          [
                            _c("b-form-textarea", {
                              model: {
                                value: fields.global_diaspora,
                                callback: function ($$v) {
                                  _vm.$set(fields, "global_diaspora", $$v)
                                },
                                expression: "fields.global_diaspora",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                !_vm.isHidden("non_anglophone")
                  ? _c(
                      "validation-provider",
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label: _vm.PROFILE_FIELD_LABELS.non_anglophone,
                            },
                          },
                          [
                            _c("b-form-textarea", {
                              model: {
                                value: fields.non_anglophone,
                                callback: function ($$v) {
                                  _vm.$set(fields, "non_anglophone", $$v)
                                },
                                expression: "fields.non_anglophone",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                !_vm.isHidden("non_us_centric_perspectives")
                  ? _c(
                      "validation-provider",
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label:
                                _vm.PROFILE_FIELD_LABELS
                                  .non_us_centric_perspectives,
                            },
                          },
                          [
                            _c("b-form-textarea", {
                              model: {
                                value: fields.non_us_centric_perspectives,
                                callback: function ($$v) {
                                  _vm.$set(
                                    fields,
                                    "non_us_centric_perspectives",
                                    $$v
                                  )
                                },
                                expression:
                                  "fields.non_us_centric_perspectives",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                !_vm.isHidden("demographic_categories")
                  ? _c(
                      "validation-provider",
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label:
                                _vm.PROFILE_FIELD_LABELS.demographic_categories,
                            },
                          },
                          [
                            _c("b-form-textarea", {
                              model: {
                                value: fields.demographic_categories,
                                callback: function ($$v) {
                                  _vm.$set(
                                    fields,
                                    "demographic_categories",
                                    $$v
                                  )
                                },
                                expression: "fields.demographic_categories",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }