var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container-fluid" }, [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "column" },
        [
          _c("h4", { staticClass: "mt-3" }, [_vm._v("Configuration")]),
          _vm._v(" "),
          _c(
            "b-form-group",
            {
              staticClass: "configuration enable",
              attrs: { "label-cols": "auto", label: "Enable Airmeet" },
            },
            [
              _c("b-form-checkbox", {
                attrs: { switch: "" },
                on: {
                  change: function ($event) {
                    return _vm.patchAirmeetConfig()
                  },
                },
                model: {
                  value: _vm.airmeet_enabled,
                  callback: function ($$v) {
                    _vm.airmeet_enabled = $$v
                  },
                  expression: "airmeet_enabled",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-form-group",
            { attrs: { label: "Airmeet ID" } },
            [
              _c("b-form-input", {
                attrs: { type: "text" },
                on: {
                  blur: function ($event) {
                    return _vm.patchAirmeetConfig()
                  },
                },
                model: {
                  value: _vm.airmeet_id,
                  callback: function ($$v) {
                    _vm.airmeet_id = $$v
                  },
                  expression: "airmeet_id",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-form-group",
            { attrs: { label: "Airmeet Host Email" } },
            [
              _c("b-form-input", {
                attrs: { type: "text" },
                on: {
                  blur: function ($event) {
                    return _vm.patchAirmeetConfig()
                  },
                },
                model: {
                  value: _vm.airmeet_host,
                  callback: function ($$v) {
                    _vm.airmeet_host = $$v
                  },
                  expression: "airmeet_host",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("h4", { staticClass: "mt-5" }, [_vm._v("Room Hosts Setup")]),
          _vm._v(" "),
          _c(
            "b-form",
            {
              staticClass: "mb-5 mt-3",
              attrs: { inline: "" },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.addAirmeetRoom($event)
                },
              },
            },
            [
              _c("b-form-select", {
                attrs: {
                  name: "room",
                  options: _vm.nonAirmeetRoomOptions,
                  value: null,
                },
              }),
              _vm._v(" "),
              _c("b-input", {
                attrs: {
                  type: "text",
                  name: "roomHostEmail",
                  placeholder: "Room Host Email",
                },
              }),
              _vm._v(" "),
              _c("b-button", { attrs: { type: "submit" } }, [
                _vm._v("Add Airmeet Room"),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "border" },
            [
              _c("b-table", {
                staticClass: "mb-0",
                attrs: {
                  fields: _vm.airmeetRoomFields,
                  items: _vm.airmeetRooms,
                  "sticky-header": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "cell(room_host_email)",
                    fn: function (ref) {
                      var item = ref.item
                      return [
                        _c("b-input", {
                          attrs: { type: "text" },
                          on: {
                            blur: function ($event) {
                              return _vm.save(item)
                            },
                          },
                          model: {
                            value: item.integrations.airmeet.room_host_email,
                            callback: function ($$v) {
                              _vm.$set(
                                item.integrations.airmeet,
                                "room_host_email",
                                $$v
                              )
                            },
                            expression:
                              "item.integrations.airmeet.room_host_email",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }