var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("a", { attrs: { href: _vm.termsOfUseLink, target: "_blank" } }, [
    _vm._v("Terms of Use"),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }