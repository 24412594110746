var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.dayColClass },
    [
      _c("vue-cal", {
        ref: "dayColumn",
        staticClass: "vuecal--full-height-delete",
        staticStyle: { height: "300px" },
        attrs: {
          "hide-view-selector": "",
          xsmall: "",
          "disable-date-prototypes": "",
          "active-view": "day",
          "editable-events": {
            title: false,
            drag: true,
            resize: true,
            delete: false,
            create: true,
          },
          "disable-views": ["years", "year", "month", "week"],
          "time-cell-height": 18,
          "time-from": _vm.startTime,
          "time-step": _vm.timeStep,
          "snap-to-time": _vm.snapToTime,
          "min-date": _vm.day,
          "max-date": _vm.day,
          "selected-date": _vm.day,
          twelveHour: _vm.twelveHour,
          timezone: _vm.timezone,
        },
        on: {
          "event-drag-create": function ($event) {
            return _vm.onCreate($event)
          },
          "event-delete": function ($event) {
            return _vm.onDelete($event)
          },
          "event-duration-change": function ($event) {
            return _vm.onUpdate($event)
          },
          "event-drop": function ($event) {
            return _vm.onUpdate($event)
          },
        },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function (ref) {
              var title = ref.title
              var view = ref.view
              return [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.formatDate(view.selectedDate, { weekday: "long" })
                    )
                ),
                _c("br"),
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.formatDate(view.selectedDate, {
                        day: "numeric",
                        month: "short",
                      })
                    ) +
                    "\n    "
                ),
              ]
            },
          },
          {
            key: "event",
            fn: function (ref) {
              var event = ref.event
              var view = ref.view
              return [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "b-popover",
                        rawName: "v-b-popover.hover.top",
                        value: _vm.hoverText(event),
                        expression: "hoverText(event)",
                        modifiers: { hover: true, top: true },
                      },
                    ],
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "d-flex flex-row" },
                      [
                        _c("small", { staticClass: "vuecal__event-time" }, [
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.formatLocaleJsDate(event.start)) +
                                " - "
                            ),
                          ]),
                          _c("br"),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(_vm._s(_vm.formatLocaleJsDate(event.end))),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("b-icon-trash", {
                          staticClass: "ml-auto mt-1",
                          on: {
                            click: function ($event) {
                              return _vm.onDelete(event)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }