var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mt-3" }, [
    _vm._v("\n  Do not schedule me against these specific  events:"),
    _c("br"),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "d-flex flex-row align-content-around flex-wrap" },
      _vm._l(_vm.currentSettings.exclusions, function (exclusion) {
        return _c(
          "div",
          { staticClass: "w-25" },
          [
            _c(
              "b-form-checkbox",
              {
                attrs: { value: exclusion.id },
                on: { change: _vm.updateExclusions },
                model: {
                  value: _vm.selectedExclusions,
                  callback: function ($$v) {
                    _vm.selectedExclusions = $$v
                  },
                  expression: "selectedExclusions",
                },
              },
              [_c("small", [_vm._v(_vm._s(exclusion.title))])]
            ),
          ],
          1
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }