var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mr-3" },
    [
      _c("b-form-select", {
        staticClass: "w-50 mb-2",
        attrs: { options: _vm.options },
        scopedSlots: _vm._u([
          {
            key: "first",
            fn: function () {
              return [
                _c("option", { domProps: { value: null } }, [
                  _vm._v("Select a content area to edit"),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.name,
          callback: function ($$v) {
            _vm.name = $$v
          },
          expression: "name",
        },
      }),
      _vm._v(" "),
      _c("plano-editor", {
        attrs: { type: "classic" },
        model: {
          value: _vm.content.html,
          callback: function ($$v) {
            _vm.$set(_vm.content, "html", $$v)
          },
          expression: "content.html",
        },
      }),
      _vm._v(" "),
      _c(
        "b-button",
        { attrs: { variant: "primary" }, on: { click: _vm.saveContent } },
        [_vm._v("Save")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }