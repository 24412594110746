var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "footer",
    {
      class: [
        "d-flex",
        "justify-content-center",
        { "not-production": _vm.currentSettings.env !== "production" },
      ],
    },
    [
      _c("terms-of-use-link"),
      _vm._v(" "),
      _c("div", { staticClass: "silly-spacer" }),
      _vm._v(" "),
      _c("privacy-policy-link"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }