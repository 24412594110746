var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("sidebar-vue", {
    attrs: { model: _vm.model },
    scopedSlots: _vm._u(
      [
        _vm.selected
          ? {
              key: "header",
              fn: function () {
                return [_c("person-summary", { attrs: { readOnly: true } })]
              },
              proxy: true,
            }
          : null,
        _vm.selected
          ? {
              key: "content",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "float-right d-flex justify-content-end" },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: {
                            title: "Edit Person",
                            variant: "primary",
                            to: _vm.editLink,
                          },
                        },
                        [_c("b-icon-pencil", { attrs: { variant: "white" } })],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-tabs",
                    {
                      attrs: {
                        "content-class": "mt-3",
                        "nav-class": "border-0",
                        "nav-wrapper-class": "border-bottom",
                      },
                    },
                    [
                      _c(
                        "b-tab",
                        { attrs: { title: "General", active: "", lazy: "" } },
                        [_c("person-details", { attrs: { readOnly: true } })],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-tab",
                        { attrs: { title: "Demographics", lazy: "" } },
                        [
                          _c("person-demographics", {
                            attrs: { readOnly: true },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-tab",
                        { attrs: { title: "Availability", lazy: "" } },
                        [_c("availability-flyout-tab")],
                        1
                      ),
                      _vm._v(" "),
                      _vm.currentUserIsAdmin ||
                      _vm.currentUserIsStaff ||
                      _vm.firmSchedule
                        ? _c(
                            "b-tab",
                            {
                              attrs: { title: _vm.liveScheduleTitle, lazy: "" },
                            },
                            [
                              _c("person-live-schedule", {
                                attrs: { noSidebar: true },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displayDraftSchedule
                        ? _c(
                            "b-tab",
                            { attrs: { title: "Draft Schedule", lazy: "" } },
                            [
                              _c("person-draft-schedule", {
                                attrs: { noSidebar: true },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.currentUserIsAdmin || _vm.currentUserIsStaff
                        ? _c(
                            "b-tab",
                            { attrs: { title: "Surveys", lazy: "" } },
                            [
                              _c("person-surveys", {
                                attrs: { person: _vm.selected },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.currentUserIsAdmin || _vm.currentUserIsStaff
                        ? _c(
                            "b-tab",
                            { attrs: { title: "Emails", lazy: "" } },
                            [_c("person-email-tab")],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.currentUserIsAdmin || _vm.currentUserIsStaff
                        ? _c(
                            "b-tab",
                            { attrs: { title: "Admin" } },
                            [_c("people-admin-tab")],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            }
          : null,
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }