var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._m(0),
      _vm._v(" "),
      _vm.primary
        ? _c(
            "div",
            { staticClass: "mt-3" },
            [
              _vm._v("\n    Primary email (login)\n    "),
              _c("email-address-editor", {
                attrs: {
                  value: _vm.primary,
                  "can-delete": false,
                  disabled: _vm.disabled,
                  radioGroup: _vm.radioGroup,
                },
                on: { input: _vm.onInput },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt-3" },
        [
          _vm._v("\n    Additional Emails\n    "),
          _vm._l(_vm.additional, function (email) {
            return _c(
              "div",
              { key: email.id },
              [
                _c("email-address-editor", {
                  attrs: {
                    value: email,
                    disabled: _vm.disabled,
                    radioGroup: _vm.radioGroup,
                  },
                  on: {
                    delete: function ($event) {
                      return _vm.onDelete(email)
                    },
                    input: function ($event) {
                      return _vm.onInput(email)
                    },
                  },
                }),
              ],
              1
            )
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "b-button",
        {
          ref: "add_email_button",
          staticClass: "mt-2",
          attrs: { variant: "primary", title: "New", size: "sm" },
          on: { click: _vm.onNew },
        },
        [_c("b-icon-plus")],
        1
      ),
      _vm._v(" "),
      _c(
        "plano-modal",
        {
          attrs: {
            id: "primaryEmailConfirm",
            title: "Primary Email Confirmation",
          },
          on: {
            ok: function ($event) {
              return _vm.onConfirmOk()
            },
            cancel: function ($event) {
              return _vm.onConfirmCancel()
            },
            close: function ($event) {
              return _vm.onConfirmCancel()
            },
          },
        },
        [
          _c("p", [
            _vm._v(
              "You are about to change the primary email address associated with this profile. "
            ),
            _c("strong", [
              _vm._v("This will change the login email used for this account."),
            ]),
          ]),
          _vm._v(" "),
          _c("p", [_vm._v("Are you sure you wish to make this change?")]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "d-flex flex-row justify-content-between w-100" },
      [
        _c("div", { staticClass: "mt-2 mr-3" }, [_c("h5", [_vm._v("Email")])]),
        _vm._v(" "),
        _c("div", { staticClass: "mt-2 pt-1 mr-3 pr-2" }, [
          _vm._v("\n      Make Primary\n    "),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }