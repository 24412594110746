var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container-fluid" }, [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-12" },
        [
          _c(
            "b-alert",
            {
              staticClass: "alert-bright d-flex align-items-center",
              attrs: { variant: "warning", show: _vm.scheduleDisabled },
            },
            [
              _c("b-icon-exclamation-triangle", {
                staticClass: "h3 mb-0 mr-3",
              }),
              _vm._v(
                "\n        " + _vm._s(_vm.SESSION_MUST_UNDROP) + "\n      "
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("h5", [_vm._v("Space")]),
          _vm._v(" "),
          _c("room-picker", {
            attrs: {
              value: _vm.selected.room_id,
              disabled: _vm.scheduleDisabled,
            },
            on: {
              change: function ($event) {
                return _vm.patchSelected({ room_id: $event })
              },
            },
          }),
          _vm._v(" "),
          _c("datetime-picker", {
            attrs: {
              value: _vm.selected.start_time,
              disabled: _vm.scheduleDisabled,
            },
            on: {
              input: function ($event) {
                return _vm.patchSelected({ start_time: $event })
              },
            },
          }),
          _vm._v(" "),
          _c(
            "b-form-group",
            {
              staticClass: "pl-2",
              attrs: {
                label: "Duration",
                "label-cols": "12",
                "label-cols-md": "1",
              },
            },
            [
              _c("ValidationProvider", {
                staticClass: "form-row h-100",
                attrs: { rules: "min_value:10" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (validationCtx) {
                      return [
                        _c("b-form-input", {
                          staticClass: "col-2 mr-2",
                          attrs: {
                            type: "number",
                            size: "sm",
                            min: 10,
                            state: _vm.getValidationState(validationCtx),
                            placeholder: "60",
                          },
                          on: {
                            blur: function ($event) {
                              return _vm.validatedPatchSelected(
                                { duration: _vm.tempDuration },
                                validationCtx
                              )
                            },
                          },
                          model: {
                            value: _vm.duration,
                            callback: function ($$v) {
                              _vm.duration = $$v
                            },
                            expression: "duration",
                          },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "align-self-center" }, [
                          _vm._v("minutes"),
                        ]),
                        _vm._v(" "),
                        _c("b-form-invalid-feedback", [
                          _vm._v(_vm._s(validationCtx.errors[0])),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }