var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.currentSettings
    ? _c(
        "div",
        [
          _vm.parameter
            ? _c("config-editor", {
                attrs: { model: "configuration", parameter: _vm.parameter },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (ref) {
                        var config = ref.config
                        var onChange = ref.onChange
                        return [
                          _vm._t("default", null, null, {
                            config: config,
                            onChange: onChange,
                          }),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }