var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.collection.length > 0
      ? _c(
          "div",
          _vm._l(_vm.collection, function (content) {
            return _c("div", {
              key: content.id,
              domProps: { innerHTML: _vm._s(content.html) },
            })
          }),
          0
        )
      : _c("div", [_vm._t("default")], 2),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }