var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "edit-modal",
        _vm._b(
          {
            attrs: {
              id: _vm.id,
              title: _vm.title,
              "ok-title": "Confirm",
              "no-stacking": "",
            },
            on: {
              cancel: function ($event) {
                return _vm.$emit("cancel", $event)
              },
              close: function ($event) {
                return _vm.$emit("close", $event)
              },
              ok: _vm.confirm,
            },
            scopedSlots: _vm._u(
              [
                _vm._l(_vm.modalScopedSlots, function (_, name) {
                  return {
                    key: name,
                    fn: function (slotData) {
                      return [_vm._t(name, null, null, slotData)]
                    },
                  }
                }),
              ],
              null,
              true
            ),
          },
          "edit-modal",
          _vm.$attrs,
          false
        ),
        [
          _vm._l(_vm.modalSlots, function (_, name) {
            return _vm._t(name, null, { slot: name })
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "edit-modal",
        _vm._g(
          {
            attrs: { id: _vm.confirmId, title: _vm.confirmTitle },
            on: {
              cancel: function ($event) {
                return _vm.$emit("cancel", $event)
              },
              close: function ($event) {
                return _vm.$emit("close", $event)
              },
            },
            scopedSlots: _vm._u(
              [
                _vm._l(_vm.confirmScopedSlots, function (_, name) {
                  return {
                    key: name,
                    fn: function (slotData) {
                      return [_vm._t("confirm-" + name, null, null, slotData)]
                    },
                  }
                }),
              ],
              null,
              true
            ),
          },
          _vm.$listeners
        ),
        [
          _vm._l(_vm.confirmSlots, function (_, name) {
            return _vm._t("confirm-" + name, null, { slot: name })
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }