var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "b-row",
        { attrs: { "align-v": "center" } },
        [
          _c(
            "b-col",
            { attrs: { cols: "2" } },
            [
              _c(
                "b-form-group",
                { attrs: { label: "Area" } },
                [
                  _c("model-select", {
                    attrs: {
                      model: "area",
                      field: "name",
                      "unselected-display": "Any Area",
                    },
                    model: {
                      value: _vm.area_id,
                      callback: function ($$v) {
                        _vm.area_id = $$v
                      },
                      expression: "area_id",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { attrs: { cols: "2" } },
            [
              _c(
                "b-form-group",
                { attrs: { label: "Tag" } },
                [
                  _c("model-tags", {
                    attrs: {
                      model: "tag",
                      field: "name",
                      filter:
                        '{"op":"all","queries":[["taggings.taggable_type", "=", "Session"], ["taggings.context", "=", "tags"]]}',
                      formatter: _vm.tagFormatter,
                    },
                    model: {
                      value: _vm.tags,
                      callback: function ($$v) {
                        _vm.tags = $$v
                      },
                      expression: "tags",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { attrs: { cols: "4" } },
            [
              _c(
                "b-form-group",
                { attrs: { label: "Title or Description" } },
                [
                  _c("b-form-input", {
                    attrs: { type: "text" },
                    model: {
                      value: _vm.title_desc,
                      callback: function ($$v) {
                        _vm.title_desc = $$v
                      },
                      expression: "title_desc",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { attrs: { cols: "2 align-self-end" } },
            [
              _c(
                "b-form-group",
                [
                  _c(
                    "b-form-radio",
                    {
                      attrs: { name: "search-match", value: "any" },
                      model: {
                        value: _vm.match,
                        callback: function ($$v) {
                          _vm.match = $$v
                        },
                        expression: "match",
                      },
                    },
                    [_vm._v("Match Any")]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-form-radio",
                    {
                      attrs: { name: "search-match", value: "all" },
                      model: {
                        value: _vm.match,
                        callback: function ($$v) {
                          _vm.match = $$v
                        },
                        expression: "match",
                      },
                    },
                    [_vm._v("Match All")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { attrs: { cols: "2 align-self-end" } },
            [
              _c(
                "b-button",
                {
                  staticClass: "search-button",
                  attrs: { variant: "primary" },
                  on: { click: _vm.onSearch },
                },
                [_vm._v("Search")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }