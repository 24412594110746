var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container-fluid" }, [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-12" },
        [
          _c(
            "b-form-group",
            { attrs: { label: "Notes", "label-for": "session-notes" } },
            [
              _c("b-form-textarea", {
                attrs: { id: "session-notes", rows: "10" },
                on: {
                  blur: function ($event) {
                    return _vm.patchSelected(
                      { item_notes: _vm.selected.item_notes },
                      true
                    )
                  },
                },
                model: {
                  value: _vm.selected.item_notes,
                  callback: function ($$v) {
                    _vm.$set(_vm.selected, "item_notes", $$v)
                  },
                  expression: "selected.item_notes",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }