import { render, staticRenderFns } from "./not-implemented.vue?vue&type=template&id=2a02c034&"
import script from "./not-implemented.vue?vue&type=script&lang=js&"
export * from "./not-implemented.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/planorama/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2a02c034')) {
      api.createRecord('2a02c034', component.options)
    } else {
      api.reload('2a02c034', component.options)
    }
    module.hot.accept("./not-implemented.vue?vue&type=template&id=2a02c034&", function () {
      api.rerender('2a02c034', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "app/javascript/shared/not-implemented.vue"
export default component.exports