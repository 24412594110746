var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("vue-cal", {
    ref: "dayRoomGrid",
    staticClass: "plano-sched-day vuecal--full-height-delete",
    attrs: {
      "hide-view-selector": "",
      xsmall: "",
      "disable-date-prototypes": "",
      "time-from": _vm.startTime,
      "time-step": _vm.timeStep,
      "snap-to-time": _vm.snapToTime,
      "selected-date": _vm.selectedDate,
      "sticky-split-labels": true,
      minSplitWidth: 150,
      "split-days": _vm.roomHeading,
      "disable-views": ["years", "year", "month", "week"],
      "time-cell-height": 80,
      "editable-events": {
        title: false,
        drag: true,
        resize: false,
        delete: false,
        create: false,
      },
      events: _vm.events,
      "on-event-click": _vm.onEventClick,
    },
    on: { "event-drop": _vm.onEventDrop },
    scopedSlots: _vm._u([
      {
        key: "title",
        fn: function (ref) {
          var title = ref.title
          var view = ref.view
          return [
            _vm._v(
              "\n    " +
                _vm._s(_vm.formatDate(view.selectedDate, { weekday: "long" })) +
                ",\n    " +
                _vm._s(
                  _vm.formatDate(view.selectedDate, {
                    day: "numeric",
                    month: "short",
                  })
                ) +
                "\n  "
            ),
          ]
        },
      },
      {
        key: "event",
        fn: function (ref) {
          var event = ref.event
          var view = ref.view
          return [
            _c(
              "div",
              {
                staticClass: "d-flex flex-column",
                class: {
                  "selected-event": _vm.selected && _vm.selected.id == event.id,
                  "event-with-conflicts":
                    event.has_conflicts &&
                    !(_vm.selected && _vm.selected.id == event.id),
                },
                staticStyle: { height: "100%" },
              },
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "b-popover",
                        rawName: "v-b-popover.hover.top",
                        value: _vm.hoverText(event),
                        expression: "hoverText(event)",
                        modifiers: { hover: true, top: true },
                      },
                    ],
                    attrs: { title: event.title },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex flex-row p-1 justify-content-between",
                      },
                      [
                        event.has_conflicts
                          ? _c(
                              "b-iconstack",
                              {
                                on: {
                                  mouseup: function ($event) {
                                    return _vm.conflictsForSession(
                                      $event,
                                      event.id
                                    )
                                  },
                                },
                              },
                              [
                                _c("b-icon-exclamation-triangle-fill", {
                                  attrs: { stacked: "", variant: "warning" },
                                }),
                                _vm._v(" "),
                                _c("b-icon-exclamation-triangle", {
                                  attrs: { stacked: "" },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        event.actual_start && event.actual_end
                          ? _c("small", { staticClass: "event-time" }, [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.formatDatetime(event.actual_start)
                                  ) +
                                  " - " +
                                  _vm._s(_vm.formatDatetime(event.actual_end)) +
                                  "\n          "
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("b-icon-x", {
                          on: {
                            click: function ($event) {
                              return _vm.onDelete($event, event)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("small", [
                      _vm._v(
                        "\n          " + _vm._s(event.title) + "\n        "
                      ),
                    ]),
                  ]
                ),
              ]
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }