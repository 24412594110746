var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.mobile
        ? _c(
            "b-button",
            {
              directives: [
                {
                  name: "b-modal",
                  rawName: "v-b-modal",
                  value: _vm.modalId,
                  expression: "modalId",
                },
              ],
              staticClass: "text-light",
              attrs: { variant: "link" },
            },
            [_vm._v("Help")]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.mobile
        ? _c(
            "b-nav-item",
            {
              directives: [
                {
                  name: "b-modal",
                  rawName: "v-b-modal",
                  value: _vm.modalId,
                  expression: "modalId",
                },
              ],
            },
            [
              _c("b-icon-question-circle", { staticClass: "mr-2" }),
              _vm._v("Help"),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            id: _vm.modalId,
            title: "Help",
            "modal-class": "help-modal",
            "ok-only": "",
          },
        },
        [
          _c("p", [
            _vm._v("If you need help, you can email "),
            _c("br"),
            _vm._v(" "),
            _c("a", { attrs: { href: _vm.mailto, target: "_blank" } }, [
              _vm._v(_vm._s(_vm.email)),
            ]),
            _vm._v(".\n    "),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }