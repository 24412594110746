var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-button",
        {
          directives: [
            {
              name: "b-modal",
              rawName: "v-b-modal.select-person-modal",
              modifiers: { "select-person-modal": true },
            },
          ],
          attrs: { variant: "link" },
        },
        [_vm._v("Select Person")]
      ),
      _vm._v(" "),
      _c(
        "plano-modal",
        {
          attrs: {
            id: "select-person-modal",
            title: "People with potential matches",
            "ok-title": "Go",
            headerBgVariant: "primary",
            headerTextVariant: "light",
            "ok-disabled": !_vm.selectedId,
          },
          on: {
            show: function ($event) {
              return _vm.load()
            },
            ok: function ($event) {
              return _vm.$emit("personSelected", _vm.selectedId)
            },
          },
        },
        [
          _c("combo-box", {
            attrs: {
              options: _vm.options,
              "select-size": 4,
              loading: _vm.loading,
            },
            model: {
              value: _vm.selectedId,
              callback: function ($$v) {
                _vm.selectedId = $$v
              },
              expression: "selectedId",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }