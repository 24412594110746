var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row scrollable" }, [
    _c("div", { staticClass: "col-12" }, [
      _c("p", [
        _vm._v(
          "Welcome to Planorama, " +
            _vm._s(_vm.currentUser.published_name) +
            "!"
        ),
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "\n      You'll be using this site between now and the convention to view and manage your profile, interests and schedule.\n    "
        ),
      ]),
      _vm._v(" "),
      !_vm.draftSchedule && _vm.doneLoading
        ? _c(
            "div",
            [
              _c(
                "page-content-display",
                { attrs: { name: "dashboard-default" } },
                [
                  _c("p", [
                    _vm._v("\n          To get started, click on "),
                    _c("a", { attrs: { href: "/#/profile" } }, [
                      _vm._v("Profile"),
                    ]),
                    _vm._v(".\n        "),
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "\n          At this point there are 5 tabs in the profile. You will need to visit all of them.\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("ul", [
                    _c("li", [
                      _c("b", [_vm._v("General Tab")]),
                      _vm._v(" "),
                      _c("ul", [
                        _c("li", [
                          _vm._v(
                            "\n                Update your name(s), pronouns, email address, bio, and social media as needed\n              "
                          ),
                        ]),
                        _vm._v(" "),
                        _vm.eventVirtual
                          ? _c("li", [
                              _vm._v(
                                "\n                If you’re not going to be attending the convention in-person, please let us know the timezone that you will be in when you attend online.\n              "
                              ),
                            ])
                          : _vm._e(),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("b", [_vm._v("Demographics & Community")]),
                      _vm._v(" "),
                      _c("ul", [
                        _c("li", [
                          _vm._v("Fill in your information as needed."),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("b", [_vm._v("Availability")]),
                      _vm._v(" "),
                      _c("ul", [
                        _c("li", [
                          _vm._v(
                            "\n                Fill in the maximum number of program items you would like to be on.\n              "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _vm._v(
                            "\n                Select blocks of time you will be available during the convention on the availability calendar.\n              "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _vm._v(
                            "\n                Select any of the specific cornerstone items you do not want to be scheduled against.\n              "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _vm._v(
                            "\n                Let us know any other scheduling constraints you have in the free text box.\n              "
                          ),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("b", [_vm._v("Session Selection")]),
                      _vm._v(" "),
                      _c("ul", [
                        _c("li", [
                          _vm._v(
                            "\n                Use this tab to tell us what program items you are interested in.\n              "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _vm._v(
                            "\n                You can filter the options for one area, look through all options (over 600!) or search based on text in the title and description. (Note: You need to click the search button - hitting enter does not work.)\n              "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _vm._v(
                            "\n                Select sessions by using the slider to the right of the description. Your selections will save automatically.\n              "
                          ),
                        ]),
                        _vm._v(" "),
                        _vm.eventVirtual
                          ? _c("li", [
                              _vm._v(
                                "\n                While some items are marked or otherwise described as online, many we’re not sure if they will be taking place in-person or online, so everyone should feel free to sign up for items not marked either way.\n              "
                              ),
                            ])
                          : _vm._e(),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("b", [_vm._v("Session Rankings")]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(
                          "\n              The list of sessions you selected will display here. For each session:\n              "
                        ),
                        _c("ul", [
                          _c("li", [
                            _vm._v(
                              "\n                  Add a ranking 1-3. The ranking system is explained on the tab. You may leave panels unranked.\n                "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _vm._v(
                              "\n                  Indicate moderation preference for the individual items.\n                "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _vm._v(
                              "\n                  Use the text box to tell us why you are a good choice for this panel and what you would contribute.\n                "
                            ),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(
                          "\n              Please follow the additional prompt, if present.\n              "
                        ),
                        _c("ul", [
                          _c("li", [
                            _vm._v(
                              "\n                  A sentence or two is often sufficient; essays are not required.\n                "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _vm._v(
                              "\n                  We have about 1000 potential panelists and about two dozen staffers engaged in panelist assignments; this step helps us learn more about you in the context of a particular session.\n                "
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.displayDraftSchedule
        ? _c(
            "div",
            [
              _c("page-content-display", {
                attrs: { name: "dashboard-schdule" },
              }),
              _vm._v(" "),
              _c("person-schedule-display", {
                attrs: { sessions: _vm.sessions, title: "Your Draft Schedule" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "message",
                      fn: function () {
                        return [
                          _c(
                            "router-link",
                            { attrs: { to: "/profile/draft-schedule" } },
                            [
                              _vm._v("Approve your draft schedule"),
                              _c("br"),
                              _vm._v("or provide change requests."),
                            ]
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  2608279753
                ),
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.firmSchedule
        ? _c(
            "div",
            [
              _c("page-content-display", {
                attrs: { name: "dashboard-schedule" },
              }),
              _vm._v(" "),
              _c("person-schedule-display", {
                attrs: { sessions: _vm.sessions, title: "Your Schedule" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "message",
                      fn: function () {
                        return [
                          _c(
                            "router-link",
                            { attrs: { to: "/profile/schedule" } },
                            [
                              _vm._v("Approve your schedule"),
                              _c("br"),
                              _vm._v("or provide change requests."),
                            ]
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  4185678276
                ),
              }),
            ],
            1
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }