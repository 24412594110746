var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("table-vue", {
    ref: "agreements-table",
    attrs: {
      model: _vm.agreement,
      columns: _vm.columns,
      "show-add": false,
      "show-settings": false,
    },
    scopedSlots: _vm._u([
      {
        key: "cell(title)",
        fn: function (ref) {
          var item = ref.item
          return [
            item.title
              ? _c("tooltip-overflow", { attrs: { title: item.title } }, [
                  _vm._v("\n      " + _vm._s(item.title) + "\n    "),
                ])
              : _vm._e(),
          ]
        },
      },
      {
        key: "cell(terms)",
        fn: function (ref) {
          var item = ref.item
          return [
            item.terms
              ? _c("tooltip-overflow", { attrs: { title: item.terms } }, [
                  _c("span", { domProps: { innerHTML: _vm._s(item.terms) } }),
                ])
              : _vm._e(),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }