var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.type !== "checkbox"
        ? _c(
            "b-form-group",
            {
              attrs: {
                id: _vm.groupId,
                label: _vm.label,
                "label-for": _vm.inputId,
              },
            },
            [
              _vm.type === "text" || _vm.type === "email"
                ? _c("b-form-input", {
                    attrs: {
                      id: _vm.inputId,
                      value: _vm.value,
                      type: _vm.type,
                      disabled: !_vm.editable,
                    },
                    on: {
                      input: function ($event) {
                        return _vm.$emit("input", $event)
                      },
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.type === "textarea"
                ? _c("b-form-textarea", {
                    attrs: {
                      id: _vm.inputId,
                      value: _vm.value,
                      disabled: !_vm.editable,
                      maxlength: _vm.fieldOptions.maxlength || 2000,
                    },
                    on: {
                      input: function ($event) {
                        return _vm.$emit("input", $event)
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.type === "checkbox"
        ? _c(
            "b-form-checkbox",
            {
              attrs: {
                id: _vm.inputId,
                checked: _vm.value,
                disabled: !_vm.editable,
              },
              on: {
                change: function ($event) {
                  return _vm.$emit("input", $event)
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.label) + " ")]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }