var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("table", { staticClass: "table table-striped" }, [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "tbody",
        [
          _vm._l(_vm.model, function (ref, category) {
            var policies = ref.policies
            var label = ref.label
            return [
              _c("tr", { key: category }, [
                _c("td", { attrs: { colspan: "5" } }, [
                  _c("strong", [_vm._v(_vm._s(label))]),
                ]),
              ]),
              _vm._v(" "),
              _vm._l(policies, function (ref, policy) {
                var label = ref.label
                var perms = ref.perms
                return _c("tr", { key: policy }, [
                  _c("td", { staticClass: "pl-5" }, [_vm._v(_vm._s(label))]),
                  _vm._v(" "),
                  _c(
                    "td",
                    [
                      perms.view !== undefined
                        ? _c("b-checkbox", {
                            model: {
                              value:
                                _vm.model[category].policies[policy].perms.view,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.model[category].policies[policy].perms,
                                  "view",
                                  $$v
                                )
                              },
                              expression:
                                "model[category].policies[policy].perms.view",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "td",
                    [
                      perms.create !== undefined
                        ? _c("b-checkbox", {
                            model: {
                              value:
                                _vm.model[category].policies[policy].perms
                                  .create,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.model[category].policies[policy].perms,
                                  "create",
                                  $$v
                                )
                              },
                              expression:
                                "model[category].policies[policy].perms.create",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "td",
                    [
                      perms.update !== undefined
                        ? _c("b-checkbox", {
                            model: {
                              value:
                                _vm.model[category].policies[policy].perms
                                  .update,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.model[category].policies[policy].perms,
                                  "update",
                                  $$v
                                )
                              },
                              expression:
                                "model[category].policies[policy].perms.update",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "td",
                    [
                      perms.destroy !== undefined
                        ? _c("b-checkbox", {
                            model: {
                              value:
                                _vm.model[category].policies[policy].perms
                                  .destroy,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.model[category].policies[policy].perms,
                                  "destroy",
                                  $$v
                                )
                              },
                              expression:
                                "model[category].policies[policy].perms.destroy",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ])
              }),
            ]
          }),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Permission")]),
        _vm._v(" "),
        _c("th", [_vm._v("View")]),
        _vm._v(" "),
        _c("th", [_vm._v("Create")]),
        _vm._v(" "),
        _c("th", [_vm._v("Update")]),
        _vm._v(" "),
        _c("th", [_vm._v("Delete")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }