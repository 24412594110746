var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    [
      _c(
        "b-col",
        [
          _vm.disabled && _vm.bool
            ? _c(
                "not-implemented",
                [
                  _c(
                    "b-form-checkbox",
                    {
                      attrs: { disabled: "", checked: _vm.value },
                      on: {
                        input: function ($event) {
                          return _vm.$emit("input", $event)
                        },
                        change: function ($event) {
                          return _vm.$emit("change", $event)
                        },
                      },
                    },
                    [
                      _vm._t("default", function () {
                        return [
                          _vm._v(
                            "\n          " + _vm._s(_vm.label) + "\n        "
                          ),
                        ]
                      }),
                    ],
                    2
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.disabled && _vm.bool
            ? _c(
                "b-form-checkbox",
                {
                  attrs: { checked: _vm.value },
                  on: {
                    input: function ($event) {
                      return _vm.$emit("input", $event)
                    },
                    change: _vm.patchFieldHelper,
                  },
                },
                [
                  _vm._t("default", function () {
                    return [
                      _vm._v("\n        " + _vm._s(_vm.label) + "\n      "),
                    ]
                  }),
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.text
            ? _c(
                "b-form-group",
                {
                  staticClass: "mt-2",
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "label",
                        fn: function () {
                          return [
                            _vm._t("default", function () {
                              return [_vm._v(_vm._s(_vm.label))]
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    true
                  ),
                },
                [
                  _vm._v(" "),
                  _c("b-form-input", {
                    staticClass: "w-50",
                    attrs: {
                      disabled: _vm.disabled,
                      type: "text",
                      value: _vm.value,
                    },
                    on: {
                      input: function ($event) {
                        return _vm.$emit("input", $event)
                      },
                      blur: _vm.patchFieldHelperBlur,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }