var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-nav-item",
        { attrs: { to: "/dashboard", "active-class": "active" } },
        [
          _c("b-icon-pie-chart-fill", { staticClass: "mr-2" }),
          _vm._v("Dashboard"),
        ],
        1
      ),
      _vm._v(" "),
      _vm.can_see_this
        ? _c(
            "b-nav-item",
            { attrs: { to: "/people", "active-class": "active" } },
            [
              _c("b-icon-person-fill", { staticClass: "mr-2" }),
              _vm._v("People"),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.can_see_this
        ? _c(
            "b-nav-item",
            { attrs: { to: "/sessions", "active-class": "active" } },
            [
              _c("b-icon-calendar2-range-fill", { staticClass: "mr-2" }),
              _vm._v("Sessions"),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.can_see_this
        ? _c(
            "b-nav-item",
            { attrs: { to: "/schedule", "active-class": "active" } },
            [
              _c("b-icon-calendar2-date", { staticClass: "mr-2" }),
              _vm._v("Schedule"),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.can_see_this
        ? _c(
            "b-nav-item",
            { attrs: { to: "/surveys", "active-class": "active" } },
            [_c("icon-survey", { staticClass: "mr-2" }), _vm._v("Surveys")],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.can_see_this
        ? _c(
            "b-nav-item",
            { attrs: { to: "/reports", "active-class": "active" } },
            [
              _c("b-icon-bar-chart-line-fill", { staticClass: "mr-2" }),
              _vm._v("Reports"),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-nav-item",
        { attrs: { to: "/profile", "active-class": "active" } },
        [_c("b-icon-person-badge", { staticClass: "mr-2" }), _vm._v("Profile")],
        1
      ),
      _vm._v(" "),
      _vm.currentUserIsAdmin
        ? _c(
            "b-nav-item",
            { attrs: { to: "/admin", "active-class": "active" } },
            [
              _c("b-icon-minecart-loaded", { staticClass: "mr-2" }),
              _vm._v("Admin"),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.currentUserIsAdmin
        ? _c(
            "b-nav-item",
            {
              attrs: { to: "/admin-configurations", "active-class": "active" },
            },
            [
              _c("b-icon-minecart-loaded", { staticClass: "mr-2" }),
              _vm._v("Configurations"),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }