var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "new-account" },
    [
      _c("h3", [_vm._v("Create Account")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "\n    Enter your email address below to either create an account or reset your\n    password.\n  "
        ),
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "\n    If an account with this address exists, you will receive an email with a\n    link to complete the password process. If one does not, you will receive a\n    link to create your account.\n  "
        ),
      ]),
      _vm._v(" "),
      _c(
        "b-alert",
        { attrs: { show: _vm.alert.visible, variant: _vm.alert.variant } },
        [_vm._v(_vm._s(_vm.alert.text))]
      ),
      _vm._v(" "),
      _c(
        "b-form",
        { on: { submit: _vm.onSubmit } },
        [
          _c("email-field", {
            attrs: { validateNow: _vm.form.email.validate },
            on: {
              validated: function ($event) {
                _vm.form.email.valid = $event
              },
            },
            model: {
              value: _vm.person.email,
              callback: function ($$v) {
                _vm.$set(_vm.person, "email", $$v)
              },
              expression: "person.email",
            },
          }),
          _vm._v(" "),
          _vm.captchaKey
            ? _c(
                "div",
                { staticClass: "d-flex mb-2" },
                [
                  _c("vue-recaptcha", {
                    ref: "recaptcha",
                    attrs: {
                      sitekey: _vm.captchaKey,
                      loadRecaptchaScript: true,
                    },
                    on: {
                      verify: _vm.onVerifyCaptcha,
                      expired: _vm.onCaptchaError,
                      error: _vm.onCaptchaError,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "d-flex flex-row-reverse" },
            [
              _c(
                "b-button",
                {
                  staticClass: "px-5",
                  attrs: {
                    disabled: _vm.submitDisabled,
                    type: "submit",
                    variant: "primary",
                  },
                },
                [_vm._v("Send me a link")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }