var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("b-form-checkbox", {
        attrs: { switch: "", size: "lg" },
        on: { change: _vm.toggleSelectSession },
        model: {
          value: _vm.interested,
          callback: function ($$v) {
            _vm.interested = $$v
          },
          expression: "interested",
        },
      }),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          ref: "unexpress-interest-modal",
          attrs: { title: "Confirm Not Interested" },
          on: { hidden: _vm.stillInterested, ok: _vm.okNotInterested },
        },
        [
          _c("p", { staticClass: "my-4" }, [
            _vm._v(
              "\n      Confirm that you are no longer interested in that session.\n    "
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }