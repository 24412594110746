var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.mandatory && _vm.survey.mandatory_star
    ? _c("span", { staticClass: "text-danger", attrs: { title: "required" } }, [
        _vm._v(" *"),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }