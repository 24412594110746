export const publicationDatesModel = 'publication_date'

export const publicationDatesEndpoints = {
  [publicationDatesModel]: 'publication_date'
}

export const publicationDatesStore = {
  selected: {
    [publicationDatesModel]: undefined
  }
}
