var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-select", {
    staticClass: "w-100",
    attrs: { options: _vm.roomNames, value: _vm.value },
    on: {
      input: function ($event) {
        return _vm.$emit("input", $event)
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }