var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.person
    ? _c(
        "div",
        [
          _c(
            "b-form-group",
            {
              attrs: {
                label: _vm.person.name,
                "label-cols": "4",
                "label-for": _vm.formId,
              },
            },
            [
              _c("b-form-checkbox-group", {
                attrs: {
                  id: _vm.formId,
                  name: _vm.formId,
                  inline: "",
                  options: _vm.roleOptions,
                },
                on: { change: _vm.onSave },
                model: {
                  value: _vm.roles,
                  callback: function ($$v) {
                    _vm.roles = $$v
                  },
                  expression: "roles",
                },
              }),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }