var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "plano-modal",
    {
      ref: "model-form",
      attrs: { title: _vm.title },
      scopedSlots: _vm._u(
        [
          {
            key: "modal-footer",
            fn: function (ref) {
              var ok = ref.ok
              var cancel = ref.cancel
              var hide = ref.hide
              return [
                _vm._t("footer", null, { ok: _vm.close, cancel: _vm.onCancel }),
              ]
            },
          },
        ],
        null,
        true
      ),
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }