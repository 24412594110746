var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid" },
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "column flex-grow-1" },
          [
            _c("h4", { staticClass: "mt-3 mb-2" }, [
              _vm._v("Registration Sync Management"),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "d-flex justify-content-between m-2" },
              [
                _c(
                  "b-button",
                  {
                    directives: [
                      {
                        name: "b-modal",
                        rawName: "v-b-modal.confirm-reg-sync",
                        modifiers: { "confirm-reg-sync": true },
                      },
                    ],
                    attrs: { variant: "primary", size: "sm" },
                  },
                  [_vm._v("Run Registration Data Sync")]
                ),
                _vm._v(" "),
                _c("reg-sync-modal"),
              ],
              1
            ),
            _vm._v(" "),
            _c("ul", { staticClass: "list-unstyled ml-2 mb-2" }, [
              _c("li", [
                _vm._v("Last completed full sync: " + _vm._s(_vm.lastSync)),
              ]),
              _vm._v(" "),
              _c("li", [
                _vm._v("Records matched: " + _vm._s(_vm.stats.matched)),
              ]),
              _vm._v(" "),
              _c("li", [
                _vm._v("Records updated: " + _vm._s(_vm.stats.updated)),
              ]),
              _vm._v(" "),
              _c("li", [
                _vm._v("Records not found: " + _vm._s(_vm.stats.not_found)),
              ]),
            ]),
            _vm._v(" "),
            _c("h4", { staticClass: "mt-3" }, [_vm._v("Configuration")]),
            _vm._v(" "),
            _c(
              "b-form-group",
              {
                staticClass: "configuration enable ml-2",
                attrs: { "label-cols": "auto", label: "Enable Clyde" },
              },
              [
                _c("b-form-checkbox", {
                  attrs: { switch: "" },
                  on: {
                    change: function ($event) {
                      return _vm.patchClydeConfig()
                    },
                  },
                  model: {
                    value: _vm.clydeEnabled,
                    callback: function ($$v) {
                      _vm.clydeEnabled = $$v
                    },
                    expression: "clydeEnabled",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "b-form-group",
              {
                staticClass: "configuration enable ml-2",
                attrs: {
                  "label-cols": "auto",
                  label: "Use Clyde as Registration Integration",
                },
              },
              [
                _c("b-form-checkbox", {
                  attrs: { switch: "", disabled: !_vm.clydeEnabled },
                  on: {
                    change: function ($event) {
                      return _vm.patchClydeConfig()
                    },
                  },
                  model: {
                    value: _vm.clydeRegistration,
                    callback: function ($$v) {
                      _vm.clydeRegistration = $$v
                    },
                    expression: "clydeRegistration",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "b-form-group",
              { staticClass: "ml-2", attrs: { label: "Base URL for Clyde" } },
              [
                _c("b-form-input", {
                  attrs: { type: "text", disabled: !_vm.clydeEnabled },
                  on: {
                    blur: function ($event) {
                      return _vm.patchClydeConfig()
                    },
                  },
                  model: {
                    value: _vm.clydeBaseUrl,
                    callback: function ($$v) {
                      _vm.clydeBaseUrl = $$v
                    },
                    expression: "clydeBaseUrl",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "b-form-group",
              {
                staticClass: "ml-2",
                attrs: { label: "Button text for Log In Button" },
              },
              [
                _c("b-form-input", {
                  attrs: { type: "text", disabled: !_vm.clydeEnabled },
                  on: {
                    blur: function ($event) {
                      return _vm.patchClydeConfig()
                    },
                  },
                  model: {
                    value: _vm.clydeButtonText,
                    callback: function ($$v) {
                      _vm.clydeButtonText = $$v
                    },
                    expression: "clydeButtonText",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "b-form-group",
              {
                staticClass: "ml-2",
                attrs: { label: "Link text for Log In Page" },
              },
              [
                _c(
                  "div",
                  { staticClass: "d-flex align-items-center" },
                  [
                    _c("span", { staticClass: "basis-14" }, [
                      _vm._v("You can also log in with"),
                    ]),
                    _vm._v(" "),
                    _c("b-form-input", {
                      attrs: {
                        type: "text",
                        disabled: !_vm.clydeEnabled,
                        inline: "",
                      },
                      on: {
                        blur: function ($event) {
                          return _vm.patchClydeConfig()
                        },
                      },
                      model: {
                        value: _vm.clydeLinkText,
                        callback: function ($$v) {
                          _vm.clydeLinkText = $$v
                        },
                        expression: "clydeLinkText",
                      },
                    }),
                  ],
                  1
                ),
              ]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "plano-modal",
        {
          attrs: { id: "confirm-reg-sync" },
          on: {
            ok: function ($event) {
              return _vm.synchronizeSchedule()
            },
          },
          scopedSlots: _vm._u([
            {
              key: "modal-title",
              fn: function () {
                return [_vm._v("Run Registration Data Sync")]
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm._v(
            "\n    This will sync with the Registration system's data. It will bring the server down for a short time.\n    Please double check that you wish to perform this action.\n  "
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }