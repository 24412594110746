var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "icon-button",
    _vm._g(
      _vm._b(
        {
          attrs: { title: "Ignore Conflict" },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function (ref) {
                var variant = ref.variant
                return [
                  _c(
                    "b-iconstack",
                    { attrs: { variant: variant } },
                    [
                      _c("b-icon-bell", { attrs: { stacked: "" } }),
                      _vm._v(" "),
                      _c("b-icon-slash", {
                        attrs: {
                          stacked: "",
                          variant: "primary",
                          scale: "2",
                          "shift-h": "2",
                        },
                      }),
                      _vm._v(" "),
                      _c("b-icon-slash", {
                        attrs: {
                          stacked: "",
                          variant: "primary",
                          scale: "2",
                          "shift-h": "-2",
                        },
                      }),
                      _vm._v(" "),
                      _c("b-icon-slash", {
                        attrs: { stacked: "", scale: "2" },
                      }),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
        },
        "icon-button",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }