var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "container mb-3" }, [
      _c("div", { staticClass: "py-2" }, [
        _vm._v("\n      " + _vm._s(_vm.messages[_vm.code]) + "\n    "),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }