var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.survey
        ? _c("div", [
            _c(
              "p",
              [
                _vm._v("Download responses: "),
                _c(
                  "b-button",
                  {
                    attrs: { variant: "link" },
                    on: {
                      click: function ($event) {
                        return _vm.downloadWithAxios(
                          _vm.downloadLink,
                          _vm.filename
                        )
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.filename))]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("p", [
              _vm._v("There are "),
              _c("em", [_vm._v(_vm._s(_vm.survey.nbr_submissions))]),
              _vm._v(" total responses."),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("table-vue", {
        ref: "responses-table",
        attrs: {
          model: "submission_flat",
          defaultUrl: _vm.defaultUrl,
          defaultFilter: _vm.defaultFilter,
          "show-add": false,
          "show-settings": false,
          columns: _vm.question_columns,
        },
        scopedSlots: _vm._u([
          {
            key: "head()",
            fn: function (data) {
              return [
                _c("tooltip-overflow", { attrs: { title: data.label } }, [
                  _c("div", {
                    staticStyle: { width: "300px" },
                    domProps: { innerHTML: _vm._s(data.label) },
                  }),
                ]),
              ]
            },
          },
          {
            key: "cell(created_at)",
            fn: function (ref) {
              var item = ref.item
              return [
                _vm._v(
                  "\n      " +
                    _vm._s(new Date(item.created_at).toLocaleString()) +
                    "\n    "
                ),
              ]
            },
          },
          {
            key: "cell(updated_at)",
            fn: function (ref) {
              var item = ref.item
              return [
                _vm._v(
                  "\n      " +
                    _vm._s(new Date(item.updated_at).toLocaleString()) +
                    "\n    "
                ),
              ]
            },
          },
          {
            key: "cell()",
            fn: function (data) {
              return [
                _c("tooltip-overflow", { attrs: { title: data.value } }, [
                  _vm._v("\n        " + _vm._s(data.value) + "\n      "),
                ]),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }