var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container-fluid" }, [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col" },
        _vm._l(_vm.fetchedMailings, function (mail) {
          return _c("div", { key: mail.id, staticClass: "mb-2" }, [
            _c("h5", [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.DateTime.fromISO(mail.date_sent).toFormat(
                      "DDDD, t ZZZZ"
                    )
                  ) +
                  " "
              ),
            ]),
            _vm._v(" "),
            _c("dl", [
              _c("dt", { staticClass: "font-weight-bold" }, [
                _vm._v("Subject"),
              ]),
              _vm._v(" "),
              _c("dd", { staticClass: "ml-2" }, [_vm._v(_vm._s(mail.subject))]),
              _vm._v(" "),
              _c("dt", { staticClass: "font-weight-bold" }, [_vm._v("Body")]),
              _vm._v(" "),
              _c("dd", {
                staticClass: "ml-2",
                domProps: { innerHTML: _vm._s(mail.content) },
              }),
            ]),
          ])
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }