var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-sidebar",
    {
      attrs: {
        right: "",
        shadow: "",
        lazy: "",
        width: _vm.width,
        visible: !!_vm.selected,
        "no-header": "",
      },
      on: { hidden: _vm.unselect },
    },
    [
      _c(
        "b-nav",
        { staticClass: "justify-content-between", attrs: { fill: "" } },
        [
          _c(
            "b-navbar-nav",
            { staticClass: "px-3 py-2 flex-grow-1" },
            [_c("b-nav-text", [_vm._t("header")], 2)],
            1
          ),
          _vm._v(" "),
          _c(
            "b-navbar-nav",
            { staticClass: "px-3 py-2" },
            [
              _c(
                "b-nav-form",
                [
                  _c("b-button", { on: { click: _vm.unselect } }, [
                    _c("i", {
                      staticClass: "fas fa-times",
                      on: { click: _vm.unselect },
                    }),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "px-3" },
        [
          _vm._t("content", function () {
            return [_c("pre", [_vm._v(_vm._s(_vm.selected))])]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }