var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticStyle: { "padding-bottom": "25px" } },
      [
        _c("agreement-table", {
          ref: "agreements-table",
          attrs: { defaultFilter: "" },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticStyle: { border: "solid" } },
      [
        _c("agreement-editor", {
          ref: "agreementEditor",
          on: { saved: _vm.init },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }