var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid pl-0" },
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          {
            staticClass: "col-3 pt-3 d-flex flex-column",
            staticStyle: { "max-height": "calc(100vh - 150px)" },
          },
          [
            _c("schedulable-sessions", {
              ref: "schedulable-sessions",
              staticStyle: { flex: "1 0 auto" },
              attrs: {
                model: _vm.sessionModel,
                defaultFilter: _vm.sessionFilter,
                defaultSortBy: "sessions.title",
              },
            }),
            _vm._v(" "),
            _c("session-conflicts", {
              ref: "conflict-reporting",
              staticStyle: { flex: "1 0 auto" },
              attrs: {
                model: _vm.sessionConflictModel,
                sessionId: _vm.sessionIdForConflict,
                timezone: _vm.timezone,
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "col-9" }, [
          _c(
            "div",
            { staticClass: "d-flex justify-content-end" },
            [
              _c("magical-reload", {
                staticClass: "mt-1",
                attrs: {
                  title: "Reload Schedule",
                  label: "Schedule last reloaded at",
                },
              }),
              _vm._v(" "),
              _c("room-selector"),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "scrollable minus31" },
            [
              _vm.selectedRooms &&
              _vm.selectedRooms.length > 0 &&
              _vm.days.length > 0
                ? _c("schedule-calendar", {
                    staticClass: "mt-1",
                    attrs: {
                      rooms: _vm.rooms,
                      selectedRooms: _vm.selectedRooms,
                      days: _vm.days,
                      timezone: _vm.timezone,
                      defaultFilter: _vm.scheduleFilter,
                      model: _vm.sessionModel,
                    },
                    on: {
                      "schedule-changed": _vm.onScheduleChanged,
                      "show-conflicts": _vm.onShowConflicts,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("session-sidebar", { attrs: { model: _vm.sessionModel } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }