import { render, staticRenderFns } from "./person_live_schedule.vue?vue&type=template&id=f5f33fc8&scoped=true&"
import script from "./person_live_schedule.vue?vue&type=script&lang=js&"
export * from "./person_live_schedule.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f5f33fc8",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/planorama/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('f5f33fc8')) {
      api.createRecord('f5f33fc8', component.options)
    } else {
      api.reload('f5f33fc8', component.options)
    }
    module.hot.accept("./person_live_schedule.vue?vue&type=template&id=f5f33fc8&scoped=true&", function () {
      api.rerender('f5f33fc8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "app/javascript/profile/person_live_schedule.vue"
export default component.exports