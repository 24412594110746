var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "col-12 col-sm-2 col-xl-2 pl-0",
      attrs: { id: "side-navbar" },
    },
    [_c("b-nav", { attrs: { vertical: "" } }, [_c("side-nav-items")], 1)],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }