var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("combo-box", {
        attrs: { options: _vm.options, "select-size": 4, loading: _vm.loading },
        on: { change: _vm.onChange },
      }),
      _vm._v(" "),
      _vm.selectedId
        ? _c("user-convention-role", {
            attrs: { model: "person", "person-id": _vm.selectedId },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }