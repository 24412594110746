var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: {
            id: "iea-modal",
            size: "lg",
            "hide-header-close": "",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            "ok-title": "I Agree",
            "cancel-variant": "link",
          },
          on: {
            show: _vm.onShow,
            ok: _vm.okAction,
            cancel: function ($event) {
              return _vm.$emit("cancel", $event)
            },
          },
          scopedSlots: _vm._u([
            {
              key: "modal-title",
              fn: function () {
                return [
                  _vm._v(
                    "\n      Staff Agreement to Ethics and Data Privacy Policy\n      "
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm._v(" "),
          _c("div", { staticClass: "terms" }, [
            _c("p", [
              _vm._v(
                "This system contains Personal Data for staff members, program participants, and prospective program participants. This includes names, email addresses, and telephone numbers as well as survey responses, proposed and scheduled program sessions, copies of correspondence with program participants, and other information."
              ),
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "Further, some information is considered Sensitive Personal Data under the European Union General Data Protection Regulation (GDPR) including demographic information for some program participants and prospective program participants."
              ),
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "These terms and conditions documented below cover all information stored in the system."
              ),
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "You must agree to the following statements in order to have access to this system:"
              ),
            ]),
            _vm._v(" "),
            _c("ul", [
              _c("li", [
                _vm._v("I understand my account is for my exclusive use only."),
              ]),
              _vm._v(" "),
              _c("li", [
                _vm._v(
                  "I will ONLY use the information stored in the system for the purposes of convention planning, including program development."
                ),
              ]),
              _vm._v(" "),
              _c("li", [
                _vm._v(
                  "I will follow the Program Division Data Protection Policies, and the conventions Privacy Policy."
                ),
              ]),
              _vm._v(" "),
              _c("li", [
                _vm._v(
                  "I acknowledge that my activities and usage of this system are logged and may be reviewed by an administrator if needed."
                ),
              ]),
              _vm._v(" "),
              _c("li", [
                _vm._v(
                  "I understand that If my account is terminated that while my user record will be deleted in the same manner as a program participant’s record would be deleted, some of my Personally Identifiable Information (i.e., name, email address, account name) may be retained in certain records such as program session notes and application log files."
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "The following is a non-exhaustive list of prohibitions that follow from the above and that you also agree to follow:"
              ),
            ]),
            _vm._v(" "),
            _c("ul", [
              _c("li", [
                _vm._v(
                  "I will not share my login information with anyone else or allow anyone else to use a session that I have already logged in with."
                ),
              ]),
              _vm._v(" "),
              _c("li", [
                _vm._v(
                  "If someone else needs access to the system, I will direct them to their division head to obtain their own account."
                ),
              ]),
              _vm._v(" "),
              _c("li", [
                _vm._v(
                  "If I ever discover that my login information has been captured or compromised in any way, I will immediately notify the head of Program, the chair, or any other staff with administrator privileges on this system by any means available or necessary so my account can be blocked from use by others."
                ),
              ]),
              _vm._v(" "),
              _c("li", [
                _vm._v(
                  "If my password was compromised, I will update my password immediately."
                ),
              ]),
              _vm._v(" "),
              _c("li", [
                _vm._v(
                  "If I have access to the Conventions Sensitive Personal Data, I will only share that information with other staff members who also have that access."
                ),
              ]),
              _vm._v(" "),
              _c("li", [
                _vm._v(
                  "I will not view or use an attendee's information unless there is a legitimate convention need to do so. "
                ),
              ]),
              _vm._v(" "),
              _c("li", [
                _vm._v(
                  "I will not browse through data for entertainment/amusement."
                ),
              ]),
              _vm._v(" "),
              _c("li", [
                _vm._v(
                  "I will not update my personal address book or contacts list with the information from the system without prior explicit permission given by the individual(s) I am adding/updating."
                ),
              ]),
              _vm._v(" "),
              _c("li", [
                _vm._v(
                  "I will not divulge information found in this system to persons not on staff or to those who do not have a legitimate business-related need to know."
                ),
              ]),
              _vm._v(" "),
              _c("li", [
                _vm._v(
                  "I will not knowingly falsify data stored in this system or introduce incorrect data into the system."
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "By clicking ‘Agree’ below I do hereby agree to abide by the above usage restrictions. I acknowledge that there may be personal, business, and legal implications if I use this system inappropriately."
              ),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }