var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("icon-button", {
        directives: [
          {
            name: "b-modal",
            rawName: "v-b-modal.room-selector-modal",
            modifiers: { "room-selector-modal": true },
          },
        ],
        staticClass: "mt-1 mr-3",
        attrs: { icon: "gear" },
      }),
      _vm._v(" "),
      _c(
        "plano-modal",
        {
          attrs: { id: "room-selector-modal" },
          on: { ok: _vm.ok, show: _vm.show },
          scopedSlots: _vm._u([
            {
              key: "modal-title",
              fn: function () {
                return [_vm._v("Displayed Rooms")]
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "room-list" },
            [
              _c(
                "b-form-checkbox",
                {
                  ref: "select-all",
                  on: { change: _vm.onSelectAll },
                  model: {
                    value: _vm.selectAll,
                    callback: function ($$v) {
                      _vm.selectAll = $$v
                    },
                    expression: "selectAll",
                  },
                },
                [_vm._v("Select All")]
              ),
              _vm._v(" "),
              _c("hr"),
              _vm._v(" "),
              _vm._l(_vm.collection, function (room) {
                return _c(
                  "div",
                  { key: room.id },
                  [
                    _c(
                      "b-form-checkbox",
                      {
                        attrs: { value: room.id },
                        on: { change: _vm.updateSelectRooms },
                        model: {
                          value: _vm.selectedRooms,
                          callback: function ($$v) {
                            _vm.selectedRooms = $$v
                          },
                          expression: "selectedRooms",
                        },
                      },
                      [_vm._v(_vm._s(room.name))]
                    ),
                  ],
                  1
                )
              }),
            ],
            2
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }