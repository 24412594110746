var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        id: "agreements-modal",
        size: "lg",
        "hide-header-close": "",
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
        title: _vm.title,
        "ok-title": "I Agree",
        "cancel-title": "I Refuse",
      },
      on: { ok: _vm.onAgree, cancel: _vm.onRefuse },
    },
    [
      _c("div", {
        staticClass: "agreements-terms",
        domProps: { innerHTML: _vm._s(_vm.terms) },
      }),
      _vm._v(" "),
      _c("div", [
        _c("footer", { staticClass: "modal-footer" }, [
          _c("p", [
            _vm._v(
              "By clicking ‘Agree’ below I do hereby agree to abide by the above usage restrictions. I acknowledge that there may be personal, business, and legal implications if I use this system inappropriately."
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }