export const publishedSessionModel = 'published_session'

export const publishedSessionEndpoints = {
  [publishedSessionModel]: 'published_session'
}

export const publishedSessionStore = {
  selected: {
    [publishedSessionModel]: null
  }
}
