var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "scrollable" },
    [
      _c(
        "b-tabs",
        { staticClass: "mt-2", on: { input: _vm.input } },
        [
          _c(
            "b-tab",
            { attrs: { title: "Rooms" } },
            [
              _c("RoomsManager", {
                ref: "rooms-manager",
                attrs: { model: "room" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-tab",
            { attrs: { title: "Venues" } },
            [
              _c("VenuesManager", {
                ref: "venues-manager",
                attrs: { model: "venue" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-tab",
            { attrs: { title: "Room Setups" } },
            [
              _c("RoomSetsManager", {
                ref: "room-sets-manager",
                attrs: { model: "roomset" },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }