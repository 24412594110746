var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("table-vue", {
    ref: "venues-table",
    attrs: {
      model: _vm.venue,
      columns: _vm.columns,
      "show-add": false,
      "show-settings": false,
    },
    scopedSlots: _vm._u([
      {
        key: "cell(name)",
        fn: function (ref) {
          var item = ref.item
          return [
            item.name
              ? _c("tooltip-overflow", { attrs: { title: item.name } }, [
                  _vm._v("\n      " + _vm._s(item.name) + "\n    "),
                ])
              : _vm._e(),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }