var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.clydeIntegration
    ? _c(
        "div",
        { staticClass: "d-flex flex-column pt-3" },
        [
          _c("login-small-print"),
          _vm._v(" "),
          _c(
            "form",
            {
              staticClass: "w-100",
              attrs: {
                action:
                  _vm.clydeIntegration.endpoint + "?redirect=" + _vm.redirect,
                method: "post",
              },
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.csrfToken,
                    expression: "csrfToken",
                  },
                ],
                attrs: { type: "hidden", name: "authenticity_token" },
                domProps: { value: _vm.csrfToken },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.csrfToken = $event.target.value
                  },
                },
              }),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  staticClass: "w-100 my-2",
                  attrs: { type: "submit", variant: "primary" },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.clydeIntegration.buttonText || "Log in with Clyde"
                    )
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            { staticClass: "mt-2" },
            [
              _vm._v("You can also "),
              _c(
                "router-link",
                { attrs: { to: "/login?redirect=" + _vm.redirect } },
                [_vm._v("Log In")]
              ),
              _vm._v(" with a username and password."),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }