var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.my_limit
    ? _c(
        "div",
        [
          _c("validation-provider", {
            attrs: { rules: _vm.rules, name: "Limit", skipIfEmpty: true },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (ref) {
                    var valid = ref.valid
                    var errors = ref.errors
                    return [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.my_limit.max_sessions,
                            expression: "my_limit.max_sessions",
                          },
                        ],
                        attrs: {
                          type: "text",
                          maxlength: "4",
                          size: "4",
                          state: _vm.calcValid(errors, valid),
                        },
                        domProps: { value: _vm.my_limit.max_sessions },
                        on: {
                          blur: _vm.onChange,
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.my_limit,
                              "max_sessions",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "invalid-message" }, [
                        _vm._v(_vm._s(errors[0])),
                      ]),
                    ]
                  },
                },
              ],
              null,
              false,
              453064740
            ),
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }