var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container-fluid" }, [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "column flex-grow-1" },
        [
          _c("h4", { staticClass: "mt-3" }, [_vm._v("Configuration")]),
          _vm._v(" "),
          _c(
            "b-form-group",
            {
              staticClass: "configuration enable",
              attrs: { "label-cols": "auto", label: "Enable G24 RCE" },
            },
            [
              _c("b-form-checkbox", {
                attrs: { switch: "" },
                on: {
                  change: function ($event) {
                    return _vm.patchG24RCEConfig()
                  },
                },
                model: {
                  value: _vm.g24rceEnabled,
                  callback: function ($$v) {
                    _vm.g24rceEnabled = $$v
                  },
                  expression: "g24rceEnabled",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "btn btn-primary",
              attrs: { href: "/rce/schedule", target: "_blank" },
            },
            [_vm._v("Export Sessions")]
          ),
          _vm._v(" "),
          _c(
            "b-form-group",
            {
              staticClass: "ml-2 mt-5",
              attrs: { label: "Base URL for Portal" },
            },
            [
              _c("b-form-input", {
                attrs: { type: "text", disabled: !_vm.g24rceEnabled },
                on: {
                  blur: function ($event) {
                    return _vm.patchG24RCEConfig()
                  },
                },
                model: {
                  value: _vm.g24rceBasebPortalUrl,
                  callback: function ($$v) {
                    _vm.g24rceBasebPortalUrl = $$v
                  },
                  expression: "g24rceBasebPortalUrl",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }