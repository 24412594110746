var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-inline-block" },
    [
      _c("span", { staticClass: "text-muted font-italic small" }, [
        _vm._v(
          _vm._s(_vm.label) + " " + _vm._s(_vm.reloadedAt.toLocaleString())
        ),
      ]),
      _vm._v(" "),
      _c("icon-button", {
        attrs: { icon: "arrow-clockwise", title: _vm.title },
        on: {
          click: function ($event) {
            return _vm.reload()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }