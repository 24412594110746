var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-form-group",
    {
      attrs: {
        id: _vm.formGroupId,
        label: _vm.passwordLabel,
        "label-for": _vm.formInputId,
        "invalid-feedback": _vm.invalidMessage,
        state: _vm.valid,
        description: _vm.passwordDescription,
      },
    },
    [
      _c("b-form-input", {
        attrs: {
          id: _vm.formInputId,
          type: "password",
          novalidate: "",
          value: _vm.value,
          state: _vm.valid,
        },
        on: {
          input: function ($event) {
            return _vm.$emit("input", $event)
          },
          focus: _vm.onPasswordFocus,
          blur: _vm.onPasswordUnfocus,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }