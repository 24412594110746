var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "detail" },
    [
      _vm.selected
        ? _c("sidebar-vue", {
            attrs: { model: "session" },
            scopedSlots: _vm._u(
              [
                {
                  key: "header",
                  fn: function () {
                    return [
                      _c("h3", [_vm._v(_vm._s(_vm.selected.title))]),
                      _vm._v(" "),
                      _c("small", { staticClass: "text-muted d-block" }, [
                        _vm._v("Last updated"),
                      ]),
                      _vm._v(" "),
                      _c("small", { staticClass: "text-muted d-block" }, [
                        _vm._v(" on "),
                        _c("em", [
                          _c("strong", [
                            _vm._v(
                              _vm._s(
                                new Date(
                                  _vm.selected.updated_at
                                ).toLocaleString()
                              )
                            ),
                          ]),
                        ]),
                      ]),
                    ]
                  },
                  proxy: true,
                },
                _vm.selected
                  ? {
                      key: "content",
                      fn: function () {
                        return [
                          _c(
                            "b-row",
                            [
                              _c("b-col", [
                                _c("dl", [
                                  _c("dt", [_vm._v("Short Title:")]),
                                  _vm._v(" "),
                                  _c("dd", { staticClass: "ml-2" }, [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.selected.short_title) +
                                        "\n            "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("dt", [_vm._v("Status:")]),
                                  _vm._v(" "),
                                  _c("dd", { staticClass: "ml-2" }, [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.SESSION_STATUS[
                                            _vm.selected.status
                                          ]
                                        ) +
                                        "\n            "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("dt", [
                                    _vm._v(
                                      "\n              Description:\n            "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("dd", { staticClass: "ml-2" }, [
                                    _c("div", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.selected.description
                                        ),
                                      },
                                    }),
                                  ]),
                                ]),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("b-row", [
                            _c(
                              "div",
                              { staticClass: "col-12 col-md-6 col-lg-4" },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c(
                                      "b-form-checkbox",
                                      {
                                        attrs: { disabled: "", switch: "" },
                                        model: {
                                          value: _vm.selected.open_for_interest,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.selected,
                                              "open_for_interest",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "selected.open_for_interest",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n              Open for Interest\n            "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col-12 col-md-6 col-lg-4" },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c(
                                      "b-form-checkbox",
                                      {
                                        attrs: { switch: "", disabled: "" },
                                        model: {
                                          value: _vm.selected.proofed,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.selected,
                                              "proofed",
                                              $$v
                                            )
                                          },
                                          expression: "selected.proofed",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n              Copy Edited/Proofed\n            "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col-12 col-md-6 col-lg-4" },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c(
                                      "b-form-checkbox",
                                      {
                                        attrs: {
                                          switch: "",
                                          disabled: "",
                                          checked: _vm.scheduled,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n              Scheduled\n            "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              { staticClass: "col-12 col-md-6 col-lg-4" },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: "Public Schedule Visibility",
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "text-muted ml-2" },
                                      [_vm._v("Not Visible")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "b-form-checkbox",
                                      {
                                        staticClass: "d-inline-block",
                                        attrs: {
                                          switch: "",
                                          disabled: "",
                                          checked:
                                            _vm.selected.visibility ===
                                            "is_public",
                                        },
                                      },
                                      [_vm._v("Visible")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col-12 col-md-6 col-lg-4" },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c(
                                      "b-form-checkbox",
                                      {
                                        attrs: {
                                          id: "session-recorded",
                                          switch: "",
                                          disabled: "",
                                        },
                                        model: {
                                          value: _vm.selected.recorded,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.selected,
                                              "recorded",
                                              $$v
                                            )
                                          },
                                          expression: "selected.recorded",
                                        },
                                      },
                                      [_vm._v("Will be recorded")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col-12 col-md-6 col-lg-4" },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c(
                                      "b-form-checkbox",
                                      {
                                        attrs: {
                                          id: "session-streamed",
                                          switch: "",
                                          disabled: "",
                                        },
                                        model: {
                                          value: _vm.selected.streamed,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.selected,
                                              "streamed",
                                              $$v
                                            )
                                          },
                                          expression: "selected.streamed",
                                        },
                                      },
                                      [_vm._v("Will be livestreamed")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "float-right d-flex justify-content-end",
                            },
                            [
                              _c(
                                "b-button",
                                {
                                  attrs: {
                                    title: "Edit Session",
                                    variant: "primary",
                                    to: _vm.editLink,
                                  },
                                },
                                [
                                  _c("b-icon-pencil", {
                                    attrs: { variant: "white" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-tabs",
                            {
                              attrs: {
                                "content-class": "mt-3",
                                "nav-class": "border-0",
                                "nav-wrapper-class": "border-bottom",
                              },
                            },
                            [
                              _c(
                                "b-tab",
                                {
                                  attrs: {
                                    title: "General",
                                    active: "",
                                    lazy: "",
                                  },
                                },
                                [
                                  _c(
                                    "dl",
                                    [
                                      _c("dt", [_vm._v("Areas")]),
                                      _vm._v(" "),
                                      _vm.selected.area_list.length
                                        ? _c(
                                            "dd",
                                            { staticClass: "ml-2 font-italic" },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.formattedAreaList)
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      !_vm.selected.area_list.length
                                        ? _c(
                                            "dd",
                                            {
                                              staticClass:
                                                "ml-2 font-italic text-muted",
                                            },
                                            [_vm._v("None Selected")]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c("dt", [_vm._v("Format")]),
                                      _vm._v(" "),
                                      _vm.selected.format
                                        ? _c(
                                            "dd",
                                            { staticClass: "ml-2 font-italic" },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.selected.format.name)
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      !_vm.selected.format
                                        ? _c(
                                            "dd",
                                            {
                                              staticClass:
                                                "ml-2 font-italic text-muted",
                                            },
                                            [_vm._v("None Selected")]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c("dt", [_vm._v("Session Environment")]),
                                      _vm._v(" "),
                                      _c(
                                        "dd",
                                        { staticClass: "ml-2 font-italic" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.SESSION_ENVIRONMENT[
                                                _vm.selected.environment
                                              ]
                                            )
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("dt", [_vm._v("Public Tags")]),
                                      _vm._v(" "),
                                      _vm.selected.tag_list.length
                                        ? _c(
                                            "dd",
                                            { staticClass: "ml-2 font-italic" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.formatTags(
                                                    _vm.selected.tag_list
                                                  )
                                                )
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      !_vm.selected.tag_list.length
                                        ? _c(
                                            "dd",
                                            {
                                              staticClass:
                                                "ml-2 font-italic text-muted",
                                            },
                                            [_vm._v("None Selected")]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c("dt", [_vm._v("Admin Labels")]),
                                      _vm._v(" "),
                                      _vm.selected.label_list.length
                                        ? _c(
                                            "dd",
                                            { staticClass: "ml-2 font-italic" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.formatTags(
                                                    _vm.selected.label_list
                                                  )
                                                )
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      !_vm.selected.label_list.length
                                        ? _c(
                                            "dd",
                                            {
                                              staticClass:
                                                "ml-2 font-italic text-muted",
                                            },
                                            [_vm._v("None Selected")]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c("dt", [_vm._v("Room Setup")]),
                                      _vm._v(" "),
                                      _vm.selected.room_set
                                        ? _c(
                                            "dd",
                                            { staticClass: "ml-2 font-italic" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.selected.room_set.name
                                                )
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      !_vm.selected.room_set
                                        ? _c(
                                            "dd",
                                            {
                                              staticClass:
                                                "ml-2 font-italic text-muted",
                                            },
                                            [_vm._v("None Selected")]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c("dt", [
                                        _vm._v("Attendee Signup Required"),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "dd",
                                        { staticClass: "ml-2 font-italic" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.selected.require_signup
                                                ? "Yes"
                                                : "No"
                                            )
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("dt", { staticClass: "ml-2" }, [
                                        _vm._v('If "Yes", max openings'),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "dd",
                                        { staticClass: "ml-3 font-italic" },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.selected.audience_size)
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      !_vm.selected.audience_size
                                        ? _c(
                                            "dd",
                                            {
                                              staticClass:
                                                "ml-3 font-italic text-muted",
                                            },
                                            [_vm._v("None Set")]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c("dt", [
                                        _vm._v("Attendee Age Restrictions"),
                                      ]),
                                      _vm._v(" "),
                                      _vm.selected.age_restriction_id
                                        ? _c(
                                            "dd",
                                            { staticClass: "ml-2 font-italic" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.ageRestrictionName(
                                                    _vm.selected
                                                      .age_restriction_id
                                                  )
                                                )
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      !_vm.selected.age_restriction_id
                                        ? _c(
                                            "dd",
                                            {
                                              staticClass:
                                                "ml-2 font-italic text-muted",
                                            },
                                            [_vm._v("None")]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c("dt", [
                                        _vm._v("Minors Participation"),
                                      ]),
                                      _vm._v(" "),
                                      _vm._l(
                                        _vm.minors_participation,
                                        function (mp) {
                                          return _c(
                                            "dd",
                                            {
                                              key: mp,
                                              staticClass: "ml-2 font-italic",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm
                                                    .SESSION_MINORS_PARTICIPATION[
                                                    mp
                                                  ]
                                                )
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      _vm._v(" "),
                                      !_vm.minors_participation.length
                                        ? _c(
                                            "dd",
                                            {
                                              staticClass:
                                                "ml-2 font-italic text-muted",
                                            },
                                            [_vm._v("No Selection")]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c("dt", [
                                        _vm._v("Interest Instructions"),
                                      ]),
                                      _vm._v(" "),
                                      _c("dd", {
                                        staticClass: "ml-2 font-italic",
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.selected
                                              .instructions_for_interest
                                          ),
                                        },
                                      }),
                                      _vm._v(" "),
                                      !_vm.selected.instructions_for_interest
                                        ? _c(
                                            "dd",
                                            {
                                              staticClass:
                                                "ml-2 font-italic text-muted",
                                            },
                                            [_vm._v("No Entry")]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c("dt", [
                                        _vm._v(
                                          "Required Room Features/Services"
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "dd",
                                        {
                                          staticClass:
                                            "ml-2 font-italic keep-format",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.selected.room_notes)
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      !_vm.selected.room_notes
                                        ? _c(
                                            "dd",
                                            {
                                              staticClass:
                                                "ml-2 font-italic text-muted",
                                            },
                                            [_vm._v("No Entry")]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c("dt", [
                                        _vm._v("Scheduled Participant Notes"),
                                      ]),
                                      _vm._v(" "),
                                      _c("dd", {
                                        staticClass: "ml-2 font-italic",
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.selected.participant_notes
                                          ),
                                        },
                                      }),
                                      _vm._v(" "),
                                      !_vm.selected.participant_notes
                                        ? _c(
                                            "dd",
                                            {
                                              staticClass:
                                                "ml-2 font-italic text-muted",
                                            },
                                            [_vm._v("No Entry")]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c("dt", [_vm._v("Tech/Hotel Notes")]),
                                      _vm._v(" "),
                                      _c(
                                        "dd",
                                        {
                                          staticClass:
                                            "ml-2 font-italic keep-format",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.selected.tech_notes)
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      !_vm.selected.tech_notes
                                        ? _c(
                                            "dd",
                                            {
                                              staticClass:
                                                "ml-2 font-italic text-muted",
                                            },
                                            [_vm._v("No Entry")]
                                          )
                                        : _vm._e(),
                                    ],
                                    2
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "b-tab",
                                { attrs: { title: "Participant Assignment" } },
                                [
                                  _c("view-participants", {
                                    attrs: { session: _vm.selected },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("b-tab", { attrs: { title: "Schedule" } }, [
                                _c("dl", [
                                  _c("dt", [_vm._v("Space")]),
                                  _vm._v(" "),
                                  _vm.selected.room
                                    ? _c(
                                        "dd",
                                        { staticClass: "ml-2 font-italic" },
                                        [_vm._v(_vm._s(_vm.selected.room.name))]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  !_vm.selected.room
                                    ? _c(
                                        "dd",
                                        {
                                          staticClass:
                                            "ml-2 font-italic text-muted",
                                        },
                                        [_vm._v("No space selected")]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("dt", [_vm._v("Time")]),
                                  _vm._v(" "),
                                  _vm.selected.start_time
                                    ? _c(
                                        "dd",
                                        { staticClass: "ml-2 font-italic" },
                                        [_vm._v(_vm._s(_vm.formattedStartTime))]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  !_vm.selected.start_time
                                    ? _c(
                                        "dd",
                                        {
                                          staticClass:
                                            "ml-2 font-italic text-muted",
                                        },
                                        [_vm._v("No time selected")]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("dt", [_vm._v("Duration")]),
                                  _vm._v(" "),
                                  _c(
                                    "dd",
                                    { staticClass: "ml-2 font-italic" },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.selected.duration) +
                                          " minutes"
                                      ),
                                    ]
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c(
                                "b-tab",
                                { attrs: { title: "Conflicts", lazy: "" } },
                                [
                                  _c("session-conflicts", {
                                    ref: "conflict-reporting",
                                    attrs: {
                                      model: _vm.sessionConflictModel,
                                      sessionId: _vm.selected.id,
                                      displaySessionInfo: false,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "b-tab",
                                { attrs: { title: "Notes" } },
                                [_c("session-notes")],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    }
                  : null,
              ],
              null,
              true
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }