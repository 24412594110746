var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "survey" },
    [
      _c("edit-survey-controls"),
      _vm._v(" "),
      _vm._l(_vm.selectedSurveyPages, function (p, i) {
        return _c("edit-survey-page", {
          key: p.id,
          attrs: { page: p, i: i, n: _vm.selectedSurveyPageNbr },
        })
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }