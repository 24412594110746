var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.registrationIntegration && _vm.isSelf
    ? _c(
        "div",
        { staticClass: "mr-3" },
        [
          !_vm.currentUserLinked
            ? _c("div", { staticClass: "d-flex flex-column align-items-end" }, [
                _c(
                  "form",
                  {
                    attrs: {
                      action:
                        _vm.registrationIntegration.endpoint +
                        "?redirect=profile",
                      method: "post",
                    },
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.csrfToken,
                          expression: "csrfToken",
                        },
                      ],
                      attrs: { type: "hidden", name: "authenticity_token" },
                      domProps: { value: _vm.csrfToken },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.csrfToken = $event.target.value
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "disabled-tooltip",
                      {
                        attrs: {
                          disabled: !_vm.isSelf,
                          disabledTooltip: _vm.PERSON_DISABLED_LINK,
                        },
                      },
                      [
                        _c(
                          "b-button",
                          {
                            attrs: {
                              variant: "info",
                              size: "sm",
                              type: "submit",
                              disabled: !_vm.isSelf,
                            },
                          },
                          [_vm._v("Link To Registration")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "font-italic" }, [
                  _c(
                    "a",
                    {
                      directives: [
                        {
                          name: "b-modal",
                          rawName: "v-b-modal.linkExplanation",
                          modifiers: { linkExplanation: true },
                        },
                      ],
                      attrs: { href: "" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                        },
                        keyup: function ($event) {
                          $event.preventDefault()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.PROFILE_LINK_EXPLAINATION_TITLE))]
                  ),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.currentUserLinked
            ? _c(
                "div",
                [
                  _c(
                    "disabled-tooltip",
                    {
                      attrs: {
                        disabled: !_vm.isSelf,
                        disabledTooltip: _vm.PERSON_DISABLED_UNLINK,
                      },
                    },
                    [
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "b-modal",
                              rawName: "v-b-modal.confirmUnlink",
                              modifiers: { confirmUnlink: true },
                            },
                          ],
                          attrs: { variant: "link", disabled: !_vm.isSelf },
                        },
                        [_vm._v("Unlink Registration")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("dl-person", {
                    staticClass: "align-with-link pt-2",
                    attrs: { fields: ["registration_number"] },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "plano-modal",
            {
              attrs: {
                id: "confirmUnlink",
                "ok-title": "Yes",
                "cancel-variant": "link",
              },
              on: { ok: _vm.unlinkPerson },
            },
            [
              _c("p", [_vm._v(_vm._s(_vm.PERSON_UNLINK_CONFIRMATION_1))]),
              _vm._v(" "),
              _c("p", [_vm._v(_vm._s(_vm.PERSON_UNLINK_CONFIRMATION_2))]),
            ]
          ),
          _vm._v(" "),
          _c(
            "plano-modal",
            {
              attrs: {
                id: "linkExplanation",
                "ok-only": true,
                title: _vm.PROFILE_LINK_EXPLAINATION_TITLE,
              },
            },
            [
              _c("p", [_vm._v(_vm._s(_vm.PROFILE_LINK_EXPLAINATION_1))]),
              _vm._v(" "),
              _c("p", [_vm._v(_vm._s(_vm.PROFILE_LINK_EXPLAINATION_2))]),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }