export const room_set_columns = [
  {
    key: 'name',
    label: 'Name',
    type: "text",
    sortable: true
  },
  {
    key: 'description',
    label: 'Description',
    type: "text",
  },
];
