var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid" },
    [
      _c("dl", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12 col-sm-6 col-lg-4" },
          [
            _c("dl-person", {
              attrs: {
                fields: [
                  "registered",
                  "registration_number",
                  "registration_type",
                  "reg_attending_status",
                ],
                nullText: "Unknown",
              },
              scopedSlots: _vm._u([
                {
                  key: "registration_number-val-end",
                  fn: function () {
                    return [
                      _c("edit-button", {
                        directives: [
                          {
                            name: "b-modal",
                            rawName: "v-b-modal.edit-reg-number",
                            modifiers: { "edit-reg-number": true },
                          },
                        ],
                        attrs: {
                          disabled: !_vm.currentUserIsAdmin,
                          id: "reg-number-edit-button",
                        },
                      }),
                      _vm._v(" "),
                      _c("icon-button", {
                        attrs: {
                          modal: "unlink-confirmation",
                          disabled:
                            !_vm.selected.registration_number ||
                            !_vm.currentUserIsAdmin,
                          tooltip: _vm.REG_ID_UNLINK_BUTTON,
                          disabledTooltip:
                            "You are not an admin or there is no Ticket Number to unlink",
                          background: "none",
                          variant: "danger",
                          icon: "trash-fill",
                        },
                      }),
                    ]
                  },
                  proxy: true,
                },
              ]),
            }),
            _vm._v(" "),
            _c(
              "b-button",
              {
                attrs: {
                  variant: "primary",
                  disabled: _vm.selected.reg_id == null,
                },
                on: { click: _vm.resyncPerson },
              },
              [_vm._v("Resync Registration")]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "col-12 col-sm-6 col-lg-4" }, [
          _c("dt", [_vm._v("Status")]),
          _vm._v(" "),
          _c("dd", { staticClass: "font-italic ml-2" }, [
            _vm._v(
              "\n        " +
                _vm._s(
                  _vm.PERSON_CON_STATE[_vm.selected.con_state || "not_set"]
                ) +
                "\n      "
            ),
          ]),
          _vm._v(" "),
          _c("dt", [_vm._v("Convention Class")]),
          _vm._v(" "),
          _c("dd", { staticClass: "font-italic ml-2" }, [
            _vm._v(_vm._s(_vm.conventionClasses)),
          ]),
        ]),
        _vm._v(" "),
        _c("dt", { staticClass: "col-12 mt-2" }, [_vm._v("Comments")]),
        _vm._v(" "),
        _c(
          "dd",
          { staticClass: "col-12" },
          [
            _c("b-form-textarea", {
              model: {
                value: _vm.comments,
                callback: function ($$v) {
                  _vm.comments = $$v
                },
                expression: "comments",
              },
            }),
            _vm._v(" "),
            _c(
              "b-button",
              {
                staticClass: "float-right mt-1",
                attrs: { variant: "primary" },
                on: {
                  click: function ($event) {
                    return _vm.patchSelected({ comments: _vm.comments })
                  },
                },
              },
              [_vm._v("Save Comments")]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _vm.currentUserIsAdmin ? _c("person-edit-reg-number") : _vm._e(),
      _vm._v(" "),
      _c(
        "plano-modal",
        {
          attrs: {
            "return-focus": "#reg-number-edit-button",
            id: "unlink-confirmation",
            "ok-variant": "danger",
            title: _vm.REG_ID_UNLINK_CONFIRMATION_TITLE,
          },
          on: {
            ok: function ($event) {
              return _vm.unlinkPerson()
            },
          },
        },
        [
          _vm._v("\n    This will unlink "),
          _c("strong", [_vm._v(_vm._s(_vm.selected.published_name))]),
          _vm._v(
            " from Ticket Number " +
              _vm._s(_vm.selected.registration_number) +
              ".\n  "
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }