import { render, staticRenderFns } from "./admin_configurations.vue?vue&type=template&id=001c6558&"
import script from "./admin_configurations.vue?vue&type=script&lang=js&"
export * from "./admin_configurations.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/planorama/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('001c6558')) {
      api.createRecord('001c6558', component.options)
    } else {
      api.reload('001c6558', component.options)
    }
    module.hot.accept("./admin_configurations.vue?vue&type=template&id=001c6558&", function () {
      api.rerender('001c6558', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "app/javascript/administration/admin_configurations.vue"
export default component.exports