var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid scrollable" },
    [
      _c("h5", [_vm._v("Surveys Taken")]),
      _vm._v(" "),
      _c("loading-overlay", { attrs: { loading: _vm.loading } }, [
        _vm.surveys.length
          ? _c(
              "ul",
              { staticClass: "option-3" },
              _vm._l(_vm.surveys, function (survey) {
                return _c(
                  "li",
                  { key: survey.id },
                  [
                    _vm.currentUserIsStaff || _vm.currentUserIsAdmin
                      ? _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                name: "survey_responses",
                                params: {
                                  id: survey.id,
                                  responses: "responses",
                                },
                                query: { person_id: _vm.person.id },
                              },
                              target: "_blank",
                            },
                          },
                          [_vm._v(_vm._s(survey.name))]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.currentUserIsStaff && !_vm.currentUserIsAdmin
                      ? _c("span", [_vm._v(_vm._s(survey.name))])
                      : _vm._e(),
                  ],
                  1
                )
              }),
              0
            )
          : _vm._e(),
        _vm._v(" "),
        !_vm.surveys.length
          ? _c("div", { staticClass: "mt-3" }, [
              _c("h5", { staticClass: "font-italic text-muted" }, [
                _vm._v("No surveys taken."),
              ]),
            ])
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }