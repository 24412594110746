var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.enabledIntegrations.length
    ? _c(
        "div",
        { staticClass: "d-flex flex-column pt-3" },
        [
          _vm._l(_vm.enabledIntegrationsNoClyde, function (integration) {
            return _c(
              "form",
              {
                key: integration.endpoint,
                staticClass: "w-100",
                attrs: {
                  action: integration.endpoint + "?redirect=" + _vm.redirect,
                  method: "post",
                },
              },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.csrfToken,
                      expression: "csrfToken",
                    },
                  ],
                  attrs: { type: "hidden", name: "authenticity_token" },
                  domProps: { value: _vm.csrfToken },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.csrfToken = $event.target.value
                    },
                  },
                }),
                _vm._v(" "),
                _c(
                  "b-button",
                  {
                    staticClass: "w-100 mb-2",
                    attrs: { type: "submit", variant: "primary" },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        integration.buttonText ||
                          "Log in with " + integration.name
                      )
                    ),
                  ]
                ),
              ],
              1
            )
          }),
          _vm._v(" "),
          _vm.clydeIntegration && !_vm.noClydeLogin
            ? _c(
                "span",
                [
                  _vm._v("You can also "),
                  _c(
                    "router-link",
                    { attrs: { to: "/login/clyde?redirect=" + _vm.redirect } },
                    [_vm._v("Log In")]
                  ),
                  _vm._v(
                    " with " +
                      _vm._s(_vm.clydeIntegration.linkText || "Clyde") +
                      "."
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }