var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "col-12" },
    [
      _c(
        "draggable",
        {
          attrs: { handle: ".qhandle" },
          on: { end: _vm.saveAnswerOrder },
          model: {
            value: _vm.answers,
            callback: function ($$v) {
              _vm.answers = $$v
            },
            expression: "answers",
          },
        },
        [
          _vm._l(_vm.answers, function (a, i) {
            return [
              !a.other
                ? _c("div", { key: a.id, staticClass: "row survey-answer" }, [
                    _c(
                      "div",
                      { staticClass: "col-5 pb-2" },
                      [
                        _vm.isSelected
                          ? _c(
                              "div",
                              { staticClass: "float-left qhandle" },
                              [_c("b-icon-grip-vertical")],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          _vm.optionComponent,
                          {
                            tag: "component",
                            staticClass: "ml-3",
                            attrs: { disabled: "", i: i + 1 },
                          },
                          [
                            _vm.isSelected && _vm.usePlanoEditor
                              ? _c("plano-editor", {
                                  attrs: {
                                    title:
                                      "Rich Text Editor for Option " + (i + 1),
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.patchAnswerText(a, $event)
                                    },
                                  },
                                  model: {
                                    value: a.answer,
                                    callback: function ($$v) {
                                      _vm.$set(a, "answer", $$v)
                                    },
                                    expression: "a.answer",
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.isSelected && !_vm.usePlanoEditor
                              ? _c("b-form-input", {
                                  staticClass: "mt-n2 mb-2",
                                  on: {
                                    change: function ($event) {
                                      return _vm.patchAnswerText(a, $event)
                                    },
                                  },
                                  model: {
                                    value: a.answer,
                                    callback: function ($$v) {
                                      _vm.$set(a, "answer", $$v)
                                    },
                                    expression: "a.answer",
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.isSelected
                              ? _c("span", {
                                  domProps: { innerHTML: _vm._s(a.answer) },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.isSelected
                      ? _c(
                          "div",
                          { staticClass: "col-1 pb-2 text-center" },
                          [
                            _c("b-icon-x", {
                              staticClass: "h3",
                              on: {
                                click: function ($event) {
                                  return _vm.removeOption(i)
                                },
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.singlechoice && _vm.question.branching
                      ? _c(
                          "div",
                          { staticClass: "col-6" },
                          [
                            _vm.isSelected
                              ? _c("next-page-picker", {
                                  staticClass: "mt-n2 mb-2",
                                  attrs: {
                                    id: _vm.pagePickerId(a),
                                    disabled: _vm.singlePage,
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.patchAnswer(a, {
                                        next_page_id: $event,
                                      })
                                    },
                                  },
                                  model: {
                                    value: a.next_page_id,
                                    callback: function ($$v) {
                                      _vm.$set(a, "next_page_id", $$v)
                                    },
                                    expression: "a.next_page_id",
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                  ])
                : _vm._e(),
            ]
          }),
        ],
        2
      ),
      _vm._v(" "),
      _vm.other && !_vm.dropdown
        ? [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-5 pb-2" },
                [
                  _c(
                    _vm.optionComponent,
                    {
                      tag: "component",
                      staticClass: "ml-3",
                      attrs: { disabled: "" },
                    },
                    [
                      _c(
                        "b-form-group",
                        {
                          staticClass: "mt-n2 mb-2",
                          attrs: { label: "Other", "label-cols": "2" },
                        },
                        [
                          _vm.isSelected
                            ? _c("b-form-input", {
                                attrs: { type: "text", disabled: "" },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.isSelected
                ? _c(
                    "div",
                    { staticClass: "col-1 pb-2 text-center" },
                    [
                      _c("b-icon-x", {
                        staticClass: "h3",
                        on: { click: _vm.removeOther },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.singlechoice && _vm.question.branching
                ? _c(
                    "div",
                    { staticClass: "col-6" },
                    [
                      _vm.isSelected
                        ? _c("next-page-picker", {
                            staticClass: "mt-n2 mb-2",
                            attrs: {
                              id: _vm.pagePickerId(_vm.other),
                              disabled: _vm.singlePage,
                            },
                            on: {
                              change: function ($event) {
                                return _vm.patchAnswer(_vm.other, {
                                  next_page_id: $event,
                                })
                              },
                            },
                            model: {
                              value: _vm.other.next_page_id,
                              callback: function ($$v) {
                                _vm.$set(_vm.other, "next_page_id", $$v)
                              },
                              expression: "other.next_page_id",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.isSelected
        ? _c(
            "div",
            { staticClass: "col-12 pb-2" },
            [
              _c(
                "b-button",
                { attrs: { variant: "link" }, on: { click: _vm.addOption } },
                [_vm._v("Add option")]
              ),
              _vm._v(" "),
              !_vm.other && !_vm.dropdown
                ? _c(
                    "div",
                    { staticClass: "d-inline-block" },
                    [
                      _vm._v(" or "),
                      _c(
                        "b-button",
                        {
                          attrs: { variant: "link" },
                          on: { click: _vm.addOther },
                        },
                        [_vm._v('add "Other"')]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }