var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        "survey-question",
        "mt-3",
        "border",
        "p-3",
        { selected: _vm.isSelected },
      ],
      on: {
        click: function ($event) {
          return _vm.selectQuestion(_vm.question)
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "handle d-flex justify-content-center" },
        [_c("b-icon-grip-horizontal")],
        1
      ),
      _vm._v(" "),
      !_vm.formatting && _vm.isSelected
        ? _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-5" },
              [
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      id: _vm.formGroupId("question-text"),
                      label: "Question Text",
                      "label-for": _vm.formId("question-text"),
                    },
                  },
                  [
                    _c("plano-editor", {
                      staticStyle: {
                        border: "solid 2px",
                        "border-radius": "5px",
                      },
                      attrs: {
                        id: _vm.formId("question-text"),
                        title: "Rich Text Editor for Question",
                      },
                      on: {
                        blur: function ($event) {
                          return _vm.patchSelectedQuestion({
                            question: $event.editor._.data,
                          })
                        },
                      },
                      model: {
                        value: _vm.question.question,
                        callback: function ($$v) {
                          _vm.$set(_vm.question, "question", $$v)
                        },
                        expression: "question.question",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-6 offset-1" },
              [
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      id: _vm.formGroupId("question-type"),
                      label: "Question Type",
                      "label-for": _vm.formId("question-type"),
                    },
                  },
                  [
                    _c("b-form-select", {
                      attrs: {
                        id: _vm.formId("question-type"),
                        options: _vm.questionTypes,
                      },
                      on: {
                        change: function ($event) {
                          return _vm.patchSelectedQuestion({
                            question_type: $event,
                          })
                        },
                      },
                      model: {
                        value: _vm.question.question_type,
                        callback: function ($$v) {
                          _vm.$set(_vm.question, "question_type", $$v)
                        },
                        expression: "question.question_type",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "offset-6 col-6" },
              [
                _c("linked-field", {
                  attrs: { question: _vm.question },
                  on: {
                    change: function ($event) {
                      return _vm.patchSelectedQuestion({ linked_field: $event })
                    },
                  },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.formatting && !_vm.isSelected
        ? _c("div", { staticClass: "row" }, [
            _c(
              "div",
              {
                class: {
                  "col-12": !_vm.question.linked_field,
                  "col-6": _vm.question.linked_field,
                },
              },
              [
                _c("span", {
                  staticClass: "py-3",
                  domProps: { innerHTML: _vm._s(_vm.question.question) },
                }),
                _vm._v(" "),
                _c("mandatory-star", {
                  attrs: { mandatory: _vm.question.mandatory },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _vm.question.linked_field
              ? _c("div", { staticClass: "text-right col-6" }, [
                  _vm._v(
                    "\n      Linked field: " +
                      _vm._s(_vm.displayLinkedField) +
                      "\n    "
                  ),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "row" },
        [
          _vm.textfield
            ? [
                _c(
                  "div",
                  { staticClass: "col-12" },
                  [
                    _vm.isSelected
                      ? _c("small", [_vm._v("Short answer text")])
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.isSelected
                      ? _c("b-form-input", {
                          attrs: {
                            type: "text",
                            disabled: "",
                            value: "Short answer text",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.textbox
            ? [
                _c(
                  "div",
                  { staticClass: "col-12" },
                  [
                    _vm.isSelected
                      ? _c("small", [_vm._v("Long answer text")])
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.isSelected
                      ? _c("b-textarea", {
                          attrs: { disabled: "", value: "Long answer text" },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.singlechoice || _vm.multiplechoice || _vm.dropdown
            ? _c("options-question", { attrs: { question: _vm.question } })
            : _vm._e(),
          _vm._v(" "),
          _vm.hr ? [_vm._m(0)] : _vm._e(),
          _vm._v(" "),
          _vm.email
            ? [
                _c(
                  "div",
                  { staticClass: "col-12" },
                  [
                    !_vm.isSelected
                      ? _c("b-form-input", {
                          attrs: { disabled: "", type: "email" },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isSelected
                      ? _c("small", [_vm._v("Email field")])
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.socialmedia
            ? [
                !_vm.isSelected
                  ? _c(
                      "div",
                      { staticClass: "col-12 col-lg-8 col-xl-6" },
                      [
                        _c(
                          "b-form-group",
                          { attrs: { label: "Facebook", "label-cols": "3" } },
                          [
                            _c(
                              "b-input-group",
                              {
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "prepend",
                                      fn: function () {
                                        return [
                                          _c("b-input-group-text", [
                                            _vm._v("facebook.com/"),
                                          ]),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  2833846945
                                ),
                              },
                              [
                                _vm._v(" "),
                                _c("b-form-input", {
                                  attrs: { type: "text", disabled: "" },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "b-form-group",
                          { attrs: { label: "Website", "label-cols": "3" } },
                          [
                            _c(
                              "b-input-group",
                              { attrs: { prepend: "url" } },
                              [
                                _c("b-form-input", {
                                  attrs: { type: "text", disabled: "" },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "b-form-group",
                          { attrs: { label: "Instagram", "label-cols": "3" } },
                          [
                            _c(
                              "b-input-group",
                              {
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "prepend",
                                      fn: function () {
                                        return [
                                          _c("b-input-group-text", [
                                            _vm._v("instagram.com/"),
                                          ]),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  3272662257
                                ),
                              },
                              [
                                _vm._v(" "),
                                _c("b-form-input", {
                                  attrs: { type: "text", disabled: "" },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "b-form-group",
                          { attrs: { label: "Twitch", "label-cols": "3" } },
                          [
                            _c(
                              "b-input-group",
                              {
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "prepend",
                                      fn: function () {
                                        return [
                                          _c("b-input-group-text", [
                                            _vm._v("twitch.tv/"),
                                          ]),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  804443775
                                ),
                              },
                              [
                                _vm._v(" "),
                                _c("b-form-input", {
                                  attrs: { type: "text", disabled: "" },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "b-form-group",
                          { attrs: { label: "YouTube", "label-cols": "3" } },
                          [
                            _c(
                              "b-input-group",
                              {
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "prepend",
                                      fn: function () {
                                        return [
                                          _c("b-input-group-text", [
                                            _vm._v("youtube.com/"),
                                          ]),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  558140620
                                ),
                              },
                              [
                                _vm._v(" "),
                                _c("b-form-input", {
                                  attrs: { type: "text", disabled: "" },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "b-form-group",
                          { attrs: { label: "TikTok", "label-cols": "3" } },
                          [
                            _c(
                              "b-input-group",
                              { attrs: { prepend: "@" } },
                              [
                                _c("b-form-input", {
                                  attrs: { type: "text", disabled: "" },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "b-form-group",
                          { attrs: { label: "LinkedIn", "label-cols": "3" } },
                          [
                            _c(
                              "b-input-group",
                              {
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "prepend",
                                      fn: function () {
                                        return [
                                          _c("b-input-group-text", [
                                            _vm._v("linkedin.com/in/"),
                                          ]),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  901077351
                                ),
                              },
                              [
                                _vm._v(" "),
                                _c("b-form-input", {
                                  attrs: { type: "text", disabled: "" },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "b-form-group",
                          { attrs: { label: "Bluesky", "label-cols": "3" } },
                          [
                            _c(
                              "b-input-group",
                              { attrs: { prepend: "@" } },
                              [
                                _c("b-form-input", {
                                  attrs: { type: "text", disabled: "" },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "b-form-group",
                          { attrs: { label: "Fediverse", "label-cols": "3" } },
                          [
                            _c(
                              "b-input-group",
                              { attrs: { prepend: "url" } },
                              [
                                _c("b-form-input", {
                                  attrs: { type: "text", disabled: "" },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label: "X (formerly Twitter)",
                              "label-cols": "3",
                            },
                          },
                          [
                            _c(
                              "b-input-group",
                              { attrs: { prepend: "@" } },
                              [
                                _c("b-form-input", {
                                  attrs: { type: "text", disabled: "" },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "b-form-group",
                          { attrs: { label: "Other", "label-cols": "3" } },
                          [
                            _c("b-form-input", {
                              attrs: { type: "text", disabled: "" },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "col-12" }, [
                  _vm.isSelected
                    ? _c("small", [_vm._v("Social Media fields")])
                    : _vm._e(),
                ]),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.textonly
            ? [
                _c(
                  "div",
                  { staticClass: "col-12" },
                  [
                    _vm.isSelected
                      ? _c("plano-editor", {
                          staticClass: "pb-2",
                          attrs: { type: "classic" },
                          on: {
                            blur: function ($event) {
                              return _vm.patchSelectedQuestion({
                                question: $event.editor._.data,
                              })
                            },
                          },
                          model: {
                            value: _vm.question.question,
                            callback: function ($$v) {
                              _vm.$set(_vm.question, "question", $$v)
                            },
                            expression: "question.question",
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.isSelected
                      ? _c("div", {
                          domProps: {
                            innerHTML: _vm._s(_vm.question.question),
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.yesnomaybe
            ? [
                _c(
                  "div",
                  { staticClass: "col-12" },
                  [
                    _c(
                      "b-form-radio-group",
                      { attrs: { stacked: "" } },
                      [
                        _c(
                          "b-form-radio",
                          {
                            attrs: { disabled: "", value: _vm.yesLabel.value },
                          },
                          [_vm._v(_vm._s(_vm.yesLabel.label))]
                        ),
                        _vm._v(" "),
                        _c(
                          "b-form-radio",
                          { attrs: { disabled: "", value: _vm.noLabel.value } },
                          [_vm._v(_vm._s(_vm.noLabel.label))]
                        ),
                        _vm._v(" "),
                        _c(
                          "b-form-radio",
                          {
                            attrs: {
                              disabled: "",
                              value: _vm.maybeLabel.value,
                            },
                          },
                          [_vm._v(_vm._s(_vm.maybeLabel.label))]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "ml-4 mt-1 mb-3" },
                      [
                        _c("b-form-textarea", {
                          attrs: {
                            placeholder: _vm.SURVEY_YESNOMAYBE_PLACEHOLDER,
                            disabled: "",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.boolean
            ? [
                _c(
                  "div",
                  { staticClass: "col-12" },
                  [
                    _c(
                      "b-form-radio-group",
                      { attrs: { stacked: "" } },
                      [
                        _c(
                          "b-form-radio",
                          {
                            attrs: { disabled: "", value: _vm.bYesLabel.value },
                          },
                          [_vm._v(_vm._s(_vm.bYesLabel.label))]
                        ),
                        _vm._v(" "),
                        _c(
                          "b-form-radio",
                          {
                            attrs: { disabled: "", value: _vm.bNoLabel.value },
                          },
                          [_vm._v(_vm._s(_vm.bNoLabel.label))]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.attendance_type
            ? [
                _c(
                  "div",
                  { staticClass: "col-12" },
                  [
                    _c(
                      "b-form-radio-group",
                      { attrs: { stacked: "" } },
                      [
                        _c(
                          "b-form-radio",
                          {
                            attrs: {
                              disabled: "",
                              value: _vm.inPersonLabel.value,
                            },
                          },
                          [_vm._v(_vm._s(_vm.inPersonLabel.label))]
                        ),
                        _vm._v(" "),
                        _c(
                          "b-form-radio",
                          {
                            attrs: {
                              disabled: "",
                              value: _vm.virtualLabel.value,
                            },
                          },
                          [_vm._v(_vm._s(_vm.virtualLabel.label))]
                        ),
                        _vm._v(" "),
                        _c(
                          "b-form-radio",
                          {
                            attrs: {
                              disabled: "",
                              value: _vm.hybridLabel.value,
                            },
                          },
                          [_vm._v(_vm._s(_vm.hybridLabel.label))]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _vm.isSelected
        ? _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-6" },
              [
                _vm.socialmedia
                  ? _c(
                      "div",
                      {
                        staticClass: "d-inline",
                        attrs: {
                          title: "Social Media questions cannot be required",
                        },
                      },
                      [
                        _c(
                          "b-form-checkbox",
                          { attrs: { inline: "", disabled: "" } },
                          [_vm._v("Required")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                !_vm.formatting && !_vm.socialmedia
                  ? _c(
                      "b-form-checkbox",
                      {
                        attrs: { inline: "", disabled: _vm.socialmedia },
                        on: {
                          change: function ($event) {
                            return _vm.patchSelectedQuestion({
                              mandatory: $event,
                            })
                          },
                        },
                        model: {
                          value: _vm.question.mandatory,
                          callback: function ($$v) {
                            _vm.$set(_vm.question, "mandatory", $$v)
                          },
                          expression: "question.mandatory",
                        },
                      },
                      [_vm._v("Required")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                !_vm.formatting
                  ? _c(
                      "b-form-checkbox",
                      {
                        attrs: { inline: "" },
                        on: {
                          change: function ($event) {
                            return _vm.patchSelectedQuestion({
                              private: $event,
                            })
                          },
                        },
                        model: {
                          value: _vm.question.private,
                          callback: function ($$v) {
                            _vm.$set(_vm.question, "private", $$v)
                          },
                          expression: "question.private",
                        },
                      },
                      [_vm._v("Sensitive")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.singlechoice
                  ? _c(
                      "b-form-checkbox",
                      {
                        attrs: { inline: "" },
                        on: {
                          change: function ($event) {
                            return _vm.patchSelectedQuestion({
                              branching: $event,
                            })
                          },
                        },
                        model: {
                          value: _vm.question.branching,
                          callback: function ($$v) {
                            _vm.$set(_vm.question, "branching", $$v)
                          },
                          expression: "question.branching",
                        },
                      },
                      [_vm._v("Branching")]
                    )
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-6 d-flex justify-content-end" },
              [
                _c(
                  "b-button",
                  {
                    directives: [
                      {
                        name: "b-tooltip",
                        rawName: "v-b-tooltip.bottom",
                        modifiers: { bottom: true },
                      },
                    ],
                    staticClass: "mr-2",
                    attrs: { variant: "info", title: "Duplicate" },
                    on: { click: _vm.duplicateSelectedQuestion },
                  },
                  [_c("b-icon-files")],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-button",
                  {
                    directives: [
                      {
                        name: "b-tooltip",
                        rawName: "v-b-tooltip.bottom",
                        modifiers: { bottom: true },
                      },
                    ],
                    attrs: { variant: "info", title: "Delete" },
                    on: { click: _vm.deleteSelectedQuestion },
                  },
                  [_c("b-icon-trash")],
                  1
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            id: "unlink-question-modal",
            size: "lg",
            "hide-header-close": "",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            title: "Really??",
            "cancel-title": "Yes, unlink",
            "ok-title": "Leave question type as it was",
          },
          on: { cancel: _vm.unlinkQuestion, ok: _vm.restoreOldValues },
        },
        [_c("div", [_vm._v(_vm._s(_vm.QUESTION_MODAL_MESSAGE))])]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-12" }, [_c("hr")])
  },
]
render._withStripped = true

export { render, staticRenderFns }