var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ValidationProvider", {
    attrs: {
      name: _vm.parameter.parameter_name,
      rules: _vm.rules,
      skipIfEmpty: true,
    },
    scopedSlots: _vm._u(
      [
        {
          key: "default",
          fn: function (ref) {
            var valid = ref.valid
            var errors = ref.errors
            return [
              _vm._t(
                "default",
                function () {
                  return [
                    _c(
                      "b-form-group",
                      { attrs: { label: _vm.parameterLabel } },
                      [
                        _vm.parameter.parameter_type === "Timezone"
                          ? _c(
                              "div",
                              [
                                _c("timezone-selector", {
                                  on: { input: _vm.onChange },
                                  model: {
                                    value: _vm.configuration.parameter_value,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.configuration,
                                        "parameter_value",
                                        $$v
                                      )
                                    },
                                    expression: "configuration.parameter_value",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm.parameter.parameter_type === "DateTime"
                          ? _c(
                              "div",
                              [
                                _c("b-form-datepicker", {
                                  on: { input: _vm.onChange },
                                  model: {
                                    value: _vm.dateval,
                                    callback: function ($$v) {
                                      _vm.dateval = $$v
                                    },
                                    expression: "dateval",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm.parameter.parameter_type === "Boolean"
                          ? _c("div", [
                              _c(
                                "label",
                                [
                                  _vm._v("No "),
                                  _c(
                                    "b-form-checkbox",
                                    {
                                      attrs: {
                                        value: "true",
                                        "unchecked-value": "false",
                                        switch: "",
                                        inline: "",
                                      },
                                      on: { change: _vm.onChange },
                                      model: {
                                        value:
                                          _vm.configuration.parameter_value,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.configuration,
                                            "parameter_value",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "configuration.parameter_value",
                                      },
                                    },
                                    [_vm._v("Yes")]
                                  ),
                                ],
                                1
                              ),
                            ])
                          : _vm.parameter.parameter_name ===
                            "people_hidden_fields"
                          ? _c(
                              "div",
                              [
                                _c("two-sided-multi-select", {
                                  attrs: {
                                    options: _vm.peopleHideableFieldsOptions,
                                    "not-selected-label": "Visible",
                                    "selected-label": "Hidden",
                                  },
                                  on: { change: _vm.onChange },
                                  model: {
                                    value: _vm.configuration.parameter_json,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.configuration,
                                        "parameter_json",
                                        $$v
                                      )
                                    },
                                    expression: "configuration.parameter_json",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm.parameter.parameter_type !== "JSON"
                          ? _c(
                              "div",
                              [
                                _c("b-form-input", {
                                  attrs: {
                                    state: _vm.calcValid(errors, valid),
                                  },
                                  on: { change: _vm.onChange },
                                  model: {
                                    value: _vm.configuration.parameter_value,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.configuration,
                                        "parameter_value",
                                        $$v
                                      )
                                    },
                                    expression: "configuration.parameter_value",
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "b-form-invalid-feedback",
                                  { attrs: { id: "inputLiveFeedback" } },
                                  [_vm._v(_vm._s(errors[0]))]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    ),
                  ]
                },
                null,
                { onChange: _vm.onChange, config: _vm.configuration }
              ),
            ]
          },
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }