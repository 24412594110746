var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.disabled
        ? _c("div", [_c("p", { domProps: { innerHTML: _vm._s(_vm.value) } })])
        : _vm._e(),
      _vm._v(" "),
      !_vm.disabled
        ? _c(
            "ckeditor",
            _vm._g(
              {
                ref: "editor",
                class: _vm.inputClass,
                attrs: { type: _vm.type, config: _vm.config, value: _vm.value },
                on: {
                  namespaceloaded: _vm.onNamespaceLoaded,
                  blur: _vm.onBlur,
                },
              },
              _vm.$listeners
            )
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }