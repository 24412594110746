var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-form-group",
    {
      attrs: {
        id: _vm.groupId,
        label: _vm.label,
        "label-for": _vm.id,
        "invalid-feedback": _vm.invalidFeedback,
        state: _vm.valid,
        "label-sr-only": _vm.labelSrOnly,
      },
    },
    [
      _c("b-form-input", {
        attrs: {
          id: _vm.id,
          value: _vm.value,
          type: "text",
          state: _vm.valid,
          novalidate: "",
          disabled: _vm.disabled,
          "aria-describedBy": _vm.ariaDescribedBy || _vm.groupId,
          required: _vm.required,
        },
        on: {
          input: function ($event) {
            return _vm.$emit("input", $event)
          },
          focus: _vm.onEmailFocus,
          blur: _vm.onEmailUnfocus,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }