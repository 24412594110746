var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-button",
        {
          directives: [
            {
              name: "b-modal",
              rawName: "v-b-modal.reg-sync-modal",
              modifiers: { "reg-sync-modal": true },
            },
          ],
          attrs: { variant: "link" },
        },
        [
          _c(
            "b-badge",
            {
              staticStyle: { "font-size": "14px" },
              attrs: { pill: "", variant: "warning" },
            },
            [_vm._v(_vm._s(_vm.possibleMatchCount))]
          ),
          _vm._v(" "),
          _vm.loading
            ? _c("b-spinner", { attrs: { variant: "warning", small: "" } })
            : _vm._e(),
          _vm._v("\n    Review potential matches"),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "plano-modal",
        {
          attrs: {
            id: "reg-sync-modal",
            title: "Registration Sync Management",
            "ok-only": "",
            "ok-title": "Close",
            headerBgVariant: "primary",
            headerTextVariant: "light",
            "no-close-on-backdrop": true,
            "no-close-on-esc": false,
          },
          on: {
            hide: function ($event) {
              return _vm.onHide()
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "mb-2" },
            [
              _c("div", { staticClass: "small" }, [
                _vm._v("Last completed full sync: " + _vm._s(_vm.lastSync)),
              ]),
              _vm._v(" "),
              _vm.modalLoading
                ? _c("b-spinner", { attrs: { variant: "primary" } })
                : _vm._e(),
              _vm._v(" "),
              _vm.selected
                ? _c("div", [
                    _vm._v(
                      "\n        People with potential matches: " +
                        _vm._s(_vm.fullTotal) +
                        "\n      "
                    ),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          !_vm.fullTotal && !_vm.modalLoading
            ? _c("div", [
                _c("span", { staticClass: "text-muted font-italic" }, [
                  _vm._v("No more people to match!"),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.selected
            ? _c("div", [
                _c(
                  "div",
                  { staticClass: "mb-2 d-flex align-items-center" },
                  [
                    _c("icon-button", {
                      attrs: {
                        icon: "caret-left-fill",
                        tooltip: "Previous",
                        disabled: _vm.selectedOrdinal === 1,
                        disabledTooltip: "No previous person",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.alsoHideTooltip(_vm.selectPrev)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("h5", { staticClass: "name-span mb-0" }, [
                      _vm._v(_vm._s(_vm.selected.published_name)),
                    ]),
                    _vm._v(" "),
                    _c("icon-button", {
                      attrs: {
                        icon: "caret-right-fill",
                        tooltip: "Next",
                        disabled: _vm.selectedOrdinal === _vm.fullTotal,
                        disabledTooltip: "No next person",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.alsoHideTooltip(_vm.selectNext)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("reg-sync-person-search", {
                      on: {
                        personSelected: function ($event) {
                          return _vm.select($event)
                        },
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("h6", [_vm._v("Planorama Data")]),
                _vm._v(" "),
                _c("div", { staticClass: "mb-3 ml-2" }, [
                  _c("div", [
                    _vm._v(
                      "Email: " + _vm._s(_vm.selected.primary_email.email)
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v(
                      "Published Name: " + _vm._s(_vm.selected.published_name)
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", [_vm._v("Name: " + _vm._s(_vm.selected.name))]),
                ]),
                _vm._v(" "),
                _c("h5", [
                  _vm._v("Potential Matches (from registration system)"),
                ]),
                _vm._v(" "),
                Object.keys(_vm.selected.registration_sync_data).length
                  ? _c("div", { staticClass: "scrollable-region" }, [
                      _c(
                        "ol",
                        _vm._l(
                          _vm.selected.registration_sync_data,
                          function (reg_data, _, index) {
                            return _c("li", { key: reg_data.id }, [
                              index !== 0
                                ? _c("div", {
                                    staticClass: "w-75 my-3",
                                    staticStyle: {
                                      "border-bottom": "1px solid black",
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "d-flex justify-content-between",
                                },
                                [
                                  _c(
                                    "div",
                                    [
                                      _c("display-sync-data", {
                                        attrs: { regData: reg_data },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex flex-column justify-content-center mr-3",
                                    },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          attrs: { variant: "primary" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.matchAndNext(
                                                reg_data.reg_id,
                                                _vm.selected.id
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("Match")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "spinner-button",
                                        {
                                          staticClass: "mt-2",
                                          attrs: {
                                            variant: "primary",
                                            showButtonTextWhileLoading: true,
                                            loading:
                                              _vm.dismissInProgress ===
                                              index + 1,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.dismissAndRefresh(
                                                reg_data.reg_id,
                                                _vm.selected.id,
                                                index + 1
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("Dismiss")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ])
                          }
                        ),
                        0
                      ),
                    ])
                  : _c("div", [
                      _c("span", { staticClass: "font-italic text-muted" }, [
                        _vm._v("There are no more potential matches."),
                      ]),
                    ]),
              ])
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }