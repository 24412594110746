var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("h5", [_vm._v("Additional Participants")]),
      _vm._v(" "),
      _c("search-vue", {
        staticClass: "w-75 mt-3",
        attrs: {
          id: "session-search-field",
          value: _vm.filter,
          columns: _vm.columns,
        },
        on: { change: _vm.onSearchChanged },
      }),
      _vm._v(" "),
      _c("b-pagination", {
        staticClass: "d-flex justify-content-end",
        attrs: {
          "total-rows": _vm.totalRows,
          "per-page": _vm.perPage,
          "first-text": "First",
          "last-text": "Last",
          "prev-text": "Prev",
          "next-text": "Next",
        },
        model: {
          value: _vm.currentPage,
          callback: function ($$v) {
            _vm.currentPage = $$v
          },
          expression: "currentPage",
        },
      }),
      _vm._v(" "),
      _vm._l(_vm.sortedCollection, function (item) {
        return _c(
          "div",
          { key: item.id, staticClass: "row mb-4" },
          [
            _c(
              "div",
              { staticClass: "col-2" },
              [
                _c(
                  "b-button",
                  {
                    attrs: { variant: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.addToASession(item)
                      },
                    },
                  },
                  [_vm._v("Add to Session")]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("assignee", { attrs: { person: item } }),
          ],
          1
        )
      }),
      _vm._v(" "),
      _c("b-pagination", {
        staticClass: "d-flex justify-content-end",
        attrs: {
          "total-rows": _vm.totalRows,
          "per-page": _vm.perPage,
          "first-text": "First",
          "last-text": "Last",
          "prev-text": "Prev",
          "next-text": "Next",
        },
        model: {
          value: _vm.currentPage,
          callback: function ($$v) {
            _vm.currentPage = $$v
          },
          expression: "currentPage",
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }