var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-overlay",
        {
          attrs: { show: _vm.loading, rounded: "sm", "no-center": "" },
          scopedSlots: _vm._u([
            {
              key: "overlay",
              fn: function () {
                return [
                  _c("b-spinner", {
                    staticClass: "position-relative",
                    staticStyle: { top: "200px", left: "50%" },
                    attrs: { label: "Spinning" },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm._v(" "),
          _vm._l(_vm.days, function (day) {
            return _c("schedule-day", {
              key: day,
              ref: "day-" + day,
              refInFor: true,
              attrs: {
                rooms: _vm.rooms,
                "selected-date": day,
                selectedRooms: _vm.selectedRooms,
                timezone: _vm.timezone,
                model: _vm.sessionModel,
              },
              on: {
                "schedule-changed": _vm.onScheduleChanged,
                "show-conflicts": _vm.onShowConflicts,
              },
            })
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }