var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-tabs",
        {
          attrs: {
            "content-class": "mt-3",
            "no-nav-style": "",
            "no-key-nav": "",
            "nav-class": "nav-tabs-style",
          },
          model: {
            value: _vm.tabIndex,
            callback: function ($$v) {
              _vm.tabIndex = $$v
            },
            expression: "tabIndex",
          },
        },
        [
          _c("b-tab", { attrs: { title: "First", active: "", lazy: "" } }, [
            _c("div", { staticClass: "d-flex flex-row" }, [
              _c("h4", [_vm._v("Draft Mailings")]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "d-flex flex-row" }, [
              _c(
                "div",
                { staticClass: "p-6 col-sm" },
                [
                  _c("combo-box", {
                    attrs: {
                      options: _vm.options,
                      "select-size": 4,
                      loading: _vm.loading,
                    },
                    on: { change: _vm.onChange },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "d-flex flex-column" },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "m-1",
                      attrs: { variant: "primary" },
                      on: { click: _vm.onNewView },
                    },
                    [_c("b-icon-plus", { attrs: { scale: "2" } })],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-button",
                    {
                      staticClass: "m-1",
                      attrs: {
                        variant: "primary",
                        disabled: !_vm.selectedId,
                        title: "View",
                      },
                      on: { click: _vm.onView },
                    },
                    [_c("b-icon-eye")],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-button",
                    {
                      staticClass: "m-1",
                      attrs: {
                        variant: "primary",
                        disabled: !_vm.selectedId,
                        title: "Delete",
                      },
                      on: { click: _vm.onDelete },
                    },
                    [_c("b-icon-trash")],
                    1
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "d-flex flex-row" }, [
              _c("h4", [_vm._v("Sent Mailings")]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "d-flex flex-row" },
              [
                _c("mailings-table", {
                  staticClass: "w-100",
                  attrs: {
                    defaultFilter:
                      '{"op":"all","queries":[["mailing_state", "!=", "draft"]]}',
                  },
                  on: { view: _vm.onReadOnlyView, clone: _vm.onClone },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "b-tab",
            { attrs: { title: "Second", lazy: "" } },
            [
              _c("mailing-editor", {
                attrs: {
                  selectedId: _vm.selectedId,
                  readOnly: _vm.selected != null,
                  model: "mailing",
                },
                on: { mailingSent: _vm.onManage },
                scopedSlots: _vm._u([
                  {
                    key: "controls",
                    fn: function () {
                      return [
                        _c(
                          "b-button",
                          {
                            attrs: { variant: "primary" },
                            on: { click: _vm.onManage },
                          },
                          [_vm._v("Back")]
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }