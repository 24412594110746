var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-navbar",
    {
      staticClass: "w-100 justify-content-start align-items-start",
      class: { "not-production": _vm.currentSettings.env !== "production" },
      attrs: {
        id: "mobile-navbar",
        toggleable: "sm",
        type: "dark",
        variant: "primary",
        sticky: true,
      },
    },
    [
      _c("b-navbar-toggle", {
        staticClass: "mr-2 mt-2",
        attrs: { target: "nav-collapse" },
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "d-flex flex-wrap justify-content-between align-items-center",
          staticStyle: { "flex-basis": "calc(100% - 4rem)" },
        },
        [
          _c(
            "b-navbar-brand",
            { staticClass: "bigger", attrs: { href: "#" } },
            [_vm._v("Planorama")]
          ),
          _vm._v(" "),
          _vm.loggedIn
            ? _c("b-nav-text", [
                _vm._v("Logged in as: " + _vm._s(_vm.currentUserDisplay)),
              ])
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-collapse",
        { attrs: { id: "nav-collapse", "is-nav": "" } },
        [
          _vm.loggedIn
            ? _c(
                "b-navbar-nav",
                { staticClass: "border-top mt-1" },
                [_c("side-nav-items")],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "b-navbar-nav",
            { staticClass: "border-top mt-1" },
            [
              _c("help-link", { attrs: { mobile: true } }),
              _vm._v(" "),
              _c(
                "b-nav-item",
                {
                  attrs: {
                    href: "https://planoramaevents.github.io/planorama",
                    target: "_blank",
                  },
                },
                [
                  _c("b-icon-info-circle", { staticClass: "mr-2" }),
                  _vm._v("About\n      "),
                ],
                1
              ),
              _vm._v(" "),
              _vm.loggedIn
                ? _c(
                    "b-nav-item",
                    { on: { click: _vm.logout } },
                    [
                      _c("b-icon-door-open", { staticClass: "mr-2" }),
                      _vm._v("Logout\n      "),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }