
export const curatedTagModel = 'curated_tag';

export const curatedTagEndpoints = {
  [curatedTagModel]: 'curated_tag'
}

export const curatedTagStore = {
  actions: {
  },
  selected: {
    [curatedTagModel]: undefined
  },
  getters: {
  }
}
