var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "position-absolute survey-controls m-3 p-3 border" },
    [
      _c(
        "b-button",
        {
          directives: [
            {
              name: "b-tooltip",
              rawName: "v-b-tooltip.left",
              modifiers: { left: true },
            },
          ],
          staticClass: "mb-2 d-block",
          attrs: { title: "Add a Question", variant: "info" },
          on: {
            click: function ($event) {
              return _vm.onNewQuestion()
            },
          },
        },
        [_c("b-icon-plus-circle")],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "b-tooltip",
              rawName: "v-b-tooltip.left",
              modifiers: { left: true },
            },
          ],
          attrs: { title: "Import a Question" },
        },
        [
          _c(
            "b-button",
            {
              staticClass: "mb-2 d-block",
              attrs: { disabled: "", variant: "info" },
            },
            [_c("b-icon-box-arrow-in-right")],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-button",
        {
          directives: [
            {
              name: "b-tooltip",
              rawName: "v-b-tooltip.left",
              modifiers: { left: true },
            },
          ],
          staticClass: "mb-2 d-block",
          attrs: { title: "Add a Page", variant: "info" },
          on: { click: _vm.onNewPage },
        },
        [_c("b-icon-hdd-stack")],
        1
      ),
      _vm._v(" "),
      _c(
        "b-button",
        {
          directives: [
            {
              name: "b-tooltip",
              rawName: "v-b-tooltip.left",
              modifiers: { left: true },
            },
          ],
          staticClass: "mb-2 d-block",
          attrs: { title: "Add a Horizontal Rule", variant: "info" },
          on: {
            click: function ($event) {
              return _vm.onNewQuestion("hr")
            },
          },
        },
        [_c("b-icon-hr")],
        1
      ),
      _vm._v(" "),
      _c(
        "b-button",
        {
          directives: [
            {
              name: "b-tooltip",
              rawName: "v-b-tooltip.left",
              modifiers: { left: true },
            },
          ],
          staticClass: "d-block",
          attrs: { title: "Add a Text Block", variant: "info" },
          on: {
            click: function ($event) {
              return _vm.onNewQuestion("textonly")
            },
          },
        },
        [_c("b-icon-fonts")],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }