<!-- CONVERTED -->
<template>
  <div class="mb-3">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "SurveyScreen"
}
</script>

<style>

</style>
