<template>
  <div class="scrollable">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: "ProfileScreen"
}
</script>

<style>

</style>
