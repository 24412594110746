var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "detail" }, [
    _c("dl", [
      _c("dt", [_vm._v("Name:")]),
      _vm._v(" "),
      _c("dd", { staticClass: "ml-2" }, [_vm._v(_vm._s(_vm.selected.name))]),
      _vm._v(" "),
      _c("dt", [_vm._v("Name Sort By:")]),
      _vm._v(" "),
      _c("dd", { staticClass: "ml-2" }, [
        _vm._v(_vm._s(_vm._f("na_if_empty")(_vm.selected.name_sort_by))),
      ]),
      _vm._v(" "),
      _c("dt", [_vm._v("Name Sort By Confirmed:")]),
      _vm._v(" "),
      _c("dd", { staticClass: "ml-2" }, [
        _vm._v(_vm._s(_vm.selected.name_sort_by_confirmed ? "Y" : "N")),
      ]),
      _vm._v(" "),
      _c("dt", [_vm._v("Pseudonym:")]),
      _vm._v(" "),
      _c("dd", { staticClass: "ml-2" }, [
        _vm._v(_vm._s(_vm._f("na_if_empty")(_vm.selected.pseudonym))),
      ]),
      _vm._v(" "),
      _c("dt", [_vm._v("Pseudonym Sort By:")]),
      _vm._v(" "),
      _c("dd", { staticClass: "ml-2" }, [
        _vm._v(_vm._s(_vm._f("na_if_empty")(_vm.selected.pseudonym_sort_by))),
      ]),
      _vm._v(" "),
      _c("dt", [_vm._v("Pseudonym Sort By Confirmed:")]),
      _vm._v(" "),
      _c("dd", { staticClass: "ml-2" }, [
        _vm._v(_vm._s(_vm.selected.pseudonym_sort_by_confirmed ? "Y" : "N")),
      ]),
      _vm._v(" "),
      _c("dt", [_vm._v("Bio:")]),
      _vm._v(" "),
      _c("dd", { staticClass: "ml-2" }, [
        _vm.selected.bio
          ? _c("span", { domProps: { innerHTML: _vm._s(_vm.selected.bio) } })
          : _c("span", [_vm._v("N/A")]),
      ]),
      _vm._v(" "),
      _c("dt", [_vm._v("Pronouns:")]),
      _vm._v(" "),
      _c("dd", { staticClass: "ml-2" }, [
        _vm._v(_vm._s(_vm._f("na_if_empty")(_vm.selected.pronouns))),
      ]),
      _vm._v(" "),
      _c("dt", [_vm._v("Job Title:")]),
      _vm._v(" "),
      _c("dd", { staticClass: "ml-2" }, [
        _vm._v(_vm._s(_vm._f("na_if_empty")(_vm.selected.job_title))),
      ]),
      _vm._v(" "),
      _c("dt", [_vm._v("Organization:")]),
      _vm._v(" "),
      _c("dd", { staticClass: "ml-2" }, [
        _vm._v(_vm._s(_vm._f("na_if_empty")(_vm.selected.organization))),
      ]),
      _vm._v(" "),
      _c("dt", [_vm._v("Registered:")]),
      _vm._v(" "),
      _c("dd", { staticClass: "ml-2" }, [
        _vm._v(_vm._s(_vm.selected.registered ? "Y" : "N")),
      ]),
      _vm._v(" "),
      _c("dt", [_vm._v("Opted In:")]),
      _vm._v(" "),
      _c("dd", { staticClass: "ml-2" }, [
        _vm._v(_vm._s(_vm.selected.opted_in ? "Y" : "N")),
      ]),
      _vm._v(" "),
      _c("dt", [_vm._v("Can Share:")]),
      _vm._v(" "),
      _c("dd", { staticClass: "ml-2" }, [
        _vm._v(_vm._s(_vm.selected.can_share ? "Y" : "N")),
      ]),
      _vm._v(" "),
      _c("dt", [_vm._v("Can Photo:")]),
      _vm._v(" "),
      _c("dd", { staticClass: "ml-2" }, [
        _vm._v(_vm._s(_vm.selected.can_photo === "yes" ? "Y" : "N")),
      ]),
      _vm._v(" "),
      _c("dt", [_vm._v("Can Stream:")]),
      _vm._v(" "),
      _c("dd", { staticClass: "ml-2" }, [
        _vm._v(_vm._s(_vm.selected.can_stream === "yes" ? "Y" : "N")),
      ]),
      _vm._v(" "),
      _c("dt", [_vm._v("Can Record:")]),
      _vm._v(" "),
      _c("dd", { staticClass: "ml-2" }, [
        _vm._v(_vm._s(_vm.selected.can_record === "yes" ? "Y" : "N")),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }