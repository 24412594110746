var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    [
      _c("b-col", [
        _c("h2", [_vm._v("Survey submitted")]),
        _vm._v(" "),
        _c("p", [_vm._v(_vm._s(_vm.thankYou))]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }