var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid" },
    [
      _c("sign-agreements", { ref: "agreement-signer" }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "row" },
        [
          _c("mobile-navbar"),
          _vm._v(" "),
          _c("top-navbar"),
          _vm._v(" "),
          _vm.loggedIn && _vm.hasPassword ? _c("side-navbar") : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              class: [
                "col-12 pr-0",
                {
                  "col-sm-10": _vm.loggedIn && _vm.hasPassword,
                  "col-xl-10": _vm.loggedIn && _vm.hasPassword,
                },
              ],
            },
            [
              _c(
                "b-overlay",
                { attrs: { show: _vm.wholePageSpinner } },
                [_c("router-view", { key: _vm.magicalReload })],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("bottom-navbar"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }