var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.editable_person
    ? _c(
        "b-form-group",
        {
          staticClass: "mt-4",
          attrs: {
            label: _vm.AVAILABILITY_NOTES_LABEL,
            "label-for": "availability-notes-input",
          },
        },
        [
          _c("b-form-textarea", {
            attrs: { id: "availability-notes-input" },
            on: {
              blur: function ($event) {
                return _vm.patchCurrentUserAvailabilityNotes()
              },
            },
            model: {
              value: _vm.editable_person.availability_notes,
              callback: function ($$v) {
                _vm.$set(_vm.editable_person, "availability_notes", $$v)
              },
              expression: "editable_person.availability_notes",
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }