var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-overlay",
    { attrs: { show: _vm.loading, rounded: "sm" } },
    [
      _c("v-select", {
        attrs: {
          options: _vm.options,
          disabled: _vm.disabled,
          taggable: _vm.taggable,
          multiple: "",
        },
        model: {
          value: _vm.selectedValue,
          callback: function ($$v) {
            _vm.selectedValue = $$v
          },
          expression: "selectedValue",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }