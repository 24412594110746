var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-skeleton-wrapper",
        {
          attrs: { loading: _vm.loading },
          scopedSlots: _vm._u([
            {
              key: "loading",
              fn: function () {
                return [
                  _c("dd", [_vm._v("Moderator")]),
                  _vm._v(" "),
                  _c(
                    "dt",
                    { staticClass: "ml-2" },
                    [_c("b-skeleton", { attrs: { width: "10rem" } })],
                    1
                  ),
                  _vm._v(" "),
                  _c("dd", [_vm._v("Participant")]),
                  _vm._v(" "),
                  _c(
                    "dt",
                    { staticClass: "ml-2" },
                    [_c("b-skeleton", { attrs: { width: "10rem" } })],
                    1
                  ),
                  _vm._v(" "),
                  _c("dd", [_vm._v("Invisible Participant")]),
                  _vm._v(" "),
                  _c(
                    "dt",
                    { staticClass: "ml-2" },
                    [_c("b-skeleton", { attrs: { width: "10rem" } })],
                    1
                  ),
                  _vm._v(" "),
                  _c("dd", [_vm._v("Reserved")]),
                  _vm._v(" "),
                  _c(
                    "dt",
                    { staticClass: "ml-2" },
                    [_c("b-skeleton", { attrs: { width: "10rem" } })],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm._v(" "),
          _c("view-participant-type", {
            attrs: { session: _vm.session, sessionRoleName: "Moderator" },
          }),
          _vm._v(" "),
          _c("view-participant-type", {
            attrs: { session: _vm.session, sessionRoleName: "Participant" },
          }),
          _vm._v(" "),
          _c("view-participant-type", {
            attrs: {
              session: _vm.session,
              sessionRoleName: "Invisible",
              sessionRoleLabel: "Invisible Participant",
            },
          }),
          _vm._v(" "),
          _c("view-participant-type", {
            attrs: {
              session: _vm.session,
              sessionRoleName: "Reserve",
              sessionRoleLabel: "Reserved",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }