var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-form-group",
    {
      staticClass: "ml-2",
      attrs: { label: "Room", "label-cols": "12", "label-cols-md": "1" },
    },
    [
      _c(
        "div",
        { staticClass: "form-row" },
        [
          _c("b-form-select", {
            staticClass: "col-6",
            attrs: { size: "sm", value: _vm.value, options: _vm.roomOptions },
            on: {
              input: function ($event) {
                return _vm.$emit("input", $event)
              },
              change: function ($event) {
                return _vm.$emit("change", $event)
              },
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }