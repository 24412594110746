var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "edit-modal",
        {
          attrs: {
            id: "edit-reg-number",
            title: "Edit Ticket Number",
            "ok-disabled": !_vm.selected || (_vm.matchedPerson && !_vm.viewed),
          },
          on: {
            close: function ($event) {
              return _vm.onHide()
            },
            cancel: function ($event) {
              return _vm.onHide()
            },
            ok: function ($event) {
              $event.preventDefault()
              return _vm.attemptLink()
            },
          },
        },
        [
          _c(
            "form",
            {
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.searchRegId()
                },
              },
            },
            [
              _c("label", { attrs: { for: "reg-id-input" } }, [
                _vm._v("Ticket Number"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "d-flex align-items-center input-line" },
                [
                  _c(
                    "b-input-group",
                    { attrs: { prepend: "#" } },
                    [
                      _c("b-form-input", {
                        attrs: { type: "text", id: "reg-id-input" },
                        model: {
                          value: _vm.partialRegId,
                          callback: function ($$v) {
                            _vm.partialRegId = $$v
                          },
                          expression: "partialRegId",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-button",
                    {
                      staticClass: "ml-2",
                      attrs: { variant: "primary", size: "sm", type: "submit" },
                    },
                    [_vm._v("Search")]
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "display-sync-data" },
            [
              _vm.selected
                ? _c("h6", [
                    _vm._v("\n        " + _vm._s(_vm.REG_ID_FOUND)),
                    _vm.matchedPerson
                      ? _c("span", [
                          _vm._v(", already linked to "),
                          _c("strong", [_vm._v(_vm._s(_vm.matchedPerson))]),
                          _vm._v(" in\n          Planorama."),
                        ])
                      : _vm._e(),
                    !_vm.matchedPerson
                      ? _c("span", [
                          _vm._v(
                            ", not currently linked to anybody in Planorama."
                          ),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.matchedPerson
                ? _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "b-toggle",
                          rawName: "v-b-toggle.sync-data",
                          modifiers: { "sync-data": true },
                        },
                      ],
                      staticClass: "pl-0",
                      attrs: { variant: "link" },
                    },
                    [_vm._v("See matched person")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "b-collapse",
                {
                  attrs: { id: "sync-data", visible: !_vm.matchedPerson },
                  on: {
                    shown: function ($event) {
                      return _vm.markViewed()
                    },
                  },
                },
                [
                  _c("display-sync-data", {
                    staticClass: "ml-2",
                    attrs: { regData: _vm.selected },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              !_vm.selected && !_vm.searched
                ? _c("div", { staticClass: "text-muted font-italic" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.REG_ID_SEARCH_PLACEHOLDER) +
                        "\n      "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              !_vm.selected && _vm.searched
                ? _c("div", { staticClass: "text-muted font-italic" }, [
                    _vm._v(
                      "\n        " + _vm._s(_vm.REG_ID_NOT_FOUND) + "\n      "
                    ),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "plano-modal",
        {
          attrs: { id: "unmatch-match" },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var ok = ref.ok
                var cancel = ref.cancel
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { variant: "link" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v("Cancel")]
                  ),
                  _vm._v(" "),
                  _c(
                    "spinner-button",
                    {
                      attrs: {
                        variant: "danger",
                        loading: _vm.unlinkLinkLoading,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.unlinkLink(ok)
                        },
                      },
                    },
                    [_vm._v("Ok")]
                  ),
                ]
              },
            },
          ]),
        },
        [
          _vm._v("This will unlink "),
          _c("strong", [_vm._v(_vm._s(_vm.matchedPerson))]),
          _vm._v(
            " from ticket number " +
              _vm._s(_vm.selectedRegNumber) +
              ", and link to the current profile.\n    "
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }