export const NEW_SURVEY = 'NEW SURVEY';
export const DUPLICATE_SURVEY = 'DUPLICATE SURVEY';

export const NEW_PAGE = 'NEW PAGE';

export const NEW_QUESTION = 'NEW QUESTION';
export const DUPLICATE_QUESTION = 'DUPLICATE QUESTION';

export const NEW_SUBMISSION = 'NEW SUBMISSION';
export const SAVE_SUBMISSION = 'SAVE SUBMISSION';
export const CLEAR_SURVEY_SUBMISSIONS = 'CLEAR SURVEY SUBMISSIONS';

export const NEW_RESPONSE = 'NEW RESPONSE';

export const SET_PREVIEW_MODE = 'SET PREVIEW MODE';

export const REDIR_SHOWN = 'REDIR SHOWN';
export const SHOW_REDIR = 'SHOW REDIR';
