var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid" },
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c(
              "b-form-group",
              {
                staticClass: "align-items-center",
                attrs: { "label-cols": "auto" },
                scopedSlots: _vm._u([
                  {
                    key: "label",
                    fn: function () {
                      return [
                        _vm._v("Release "),
                        _c("strong", [_vm._v("Draft")]),
                        _vm._v(" Schedule to Participants"),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              },
              [
                _vm._v(" "),
                _c("b-form-checkbox", {
                  attrs: {
                    switch: "",
                    disabled: _vm.localDraftSchedule,
                    id: "draft-schedule-checkbox",
                    "aria-describedby": "draft-schedule-date",
                  },
                  on: { change: _vm.openDraftConfirm },
                  model: {
                    value: _vm.localDraftSchedule,
                    callback: function ($$v) {
                      _vm.localDraftSchedule = $$v
                    },
                    expression: "localDraftSchedule",
                  },
                }),
                _vm._v(" "),
                _vm.localDraftSchedule
                  ? _c(
                      "span",
                      {
                        staticClass: "small text-muted",
                        attrs: { id: "draft-schedule-date" },
                      },
                      [_vm._v(_vm._s(_vm.draftScheduledAtText))]
                    )
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "b-form-group",
              {
                staticClass: "align-items-center",
                attrs: {
                  "label-cols": "auto",
                  label: "Release Firm Schedule to Participants",
                },
              },
              [
                _c("b-form-checkbox", {
                  attrs: {
                    id: "firm-schedule-checkbox",
                    switch: "",
                    disabled: !_vm.localDraftSchedule || _vm.localFirmSchedule,
                    inline: "",
                    "aria-describedby": "firm-schedule-date",
                  },
                  on: { change: _vm.openFirmConfirm },
                  model: {
                    value: _vm.localFirmSchedule,
                    callback: function ($$v) {
                      _vm.localFirmSchedule = $$v
                    },
                    expression: "localFirmSchedule",
                  },
                }),
                _vm._v(" "),
                _vm.localFirmSchedule
                  ? _c(
                      "span",
                      {
                        staticClass: "small text-muted",
                        attrs: { id: "firm-schedule-date" },
                      },
                      [_vm._v(_vm._s(_vm.firmScheduledAtText))]
                    )
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c("single-config-manager", {
              attrs: { parameterName: "show_join_links" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (ref) {
                    var onChange = ref.onChange
                    var config = ref.config
                    return [
                      _c(
                        "b-form-group",
                        {
                          staticClass: "align-items-center",
                          attrs: {
                            "label-cols": "auto",
                            label: "Show join links to participants",
                          },
                        },
                        [
                          _c("b-form-checkbox", {
                            attrs: {
                              id: "show-join-links-checkbox",
                              switch: "",
                              disabled: !_vm.localFirmSchedule,
                              inline: "",
                              "aria-describedby":
                                "show-join-links-date show-join-links-caveat",
                              value: "true",
                              "unchecked-value": "false",
                            },
                            on: { change: onChange },
                            model: {
                              value: config.parameter_value,
                              callback: function ($$v) {
                                _vm.$set(config, "parameter_value", $$v)
                              },
                              expression: "config.parameter_value",
                            },
                          }),
                          _vm._v(" "),
                          config.parameter_value === "true"
                            ? _c(
                                "span",
                                {
                                  staticClass: "small text-muted",
                                  attrs: { id: "show-join-links-date" },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.formatTime(config.updated_at))
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-block ml-2 small text-muted font-italic",
                              attrs: { id: "show-join-links-caveat" },
                            },
                            [
                              _vm._v("In order for this to take effect, you "),
                              _c(
                                "strong",
                                { staticStyle: { "font-size": "100%" } },
                                [_vm._v("must")]
                              ),
                              _vm._v(" reload Planorama."),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _vm.currentSettings.env !== "production"
              ? _c(
                  "div",
                  [
                    _c(
                      "b-button",
                      {
                        attrs: { variant: "danger" },
                        on: {
                          click: function ($event) {
                            return _vm.reset()
                          },
                        },
                      },
                      [_vm._v("Reset for Testing")]
                    ),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        "THIS DELETES THE SNAPSHOT AND YOU CAN'T EVER GET IT BACK"
                      ),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c("h5", [_vm._v("Publish schedule to public")]),
            _vm._v(" "),
            _c(
              "b-table-simple",
              {
                staticStyle: { width: "35rem" },
                attrs: { borderless: "", fixed: "", small: "" },
              },
              [
                _c(
                  "b-thead",
                  [
                    _c(
                      "b-tr",
                      [
                        _c(
                          "b-td",
                          {
                            staticClass: "text-center",
                            attrs: { colspan: "3" },
                          },
                          [
                            _c(
                              "b-button",
                              {
                                attrs: {
                                  variant: "primary",
                                  size: "sm",
                                  disabled: !_vm.canDiff,
                                },
                                on: { click: _vm.diff },
                              },
                              [_vm._v("Show difference")]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "b-td",
                          {
                            staticClass: "text-right",
                            attrs: { colspan: "13" },
                          },
                          [
                            _c("icon-button", {
                              staticClass: "mr-2",
                              attrs: { icon: "arrow-repeat" },
                              on: {
                                click: function ($event) {
                                  return _vm.fetchPublicationDates()
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "b-button",
                              {
                                directives: [
                                  {
                                    name: "b-modal",
                                    rawName: "v-b-modal.confirm-publish",
                                    modifiers: { "confirm-publish": true },
                                  },
                                ],
                                attrs: { variant: "primary", size: "sm" },
                              },
                              [_vm._v("Create a publish snapshot")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("b-table", {
              staticStyle: { width: "35rem" },
              attrs: {
                fields: [
                  {
                    key: "select_2",
                    tdClass: "text-center",
                    thClass: "text-center",
                    thAttr: { colspan: 3 },
                    tdAttr: { colspan: 3 },
                  },
                  {
                    key: "timestamp",
                    tdClass: "text-right",
                    thClass: "text-right",
                    thAttr: { colspan: 10 },
                    tdAttr: { colspan: 10 },
                  },
                  {
                    key: "sent_external",
                    tdClass: "text-center",
                    thClass: "text-center",
                    thAttr: { colspan: 3 },
                    tdAttr: { colspan: 3 },
                    label: "External",
                  },
                ],
                bordered: "",
                fixed: "",
                small: "",
                "sticky-header": "",
                items: _vm.pubSnapshots,
                busy: _vm.pubsLoading,
              },
              scopedSlots: _vm._u([
                {
                  key: "table-busy",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "d-flex justify-content-center" },
                        [_c("b-spinner", { attrs: { variant: "primary" } })],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "cell(select_2)",
                  fn: function (ref) {
                    var index = ref.index
                    return [
                      _c("b-form-checkbox", {
                        attrs: {
                          name: "pubs-diff",
                          disabled:
                            _vm.pubsDiffCount >= 2 && !_vm.pubsDiff[index],
                        },
                        model: {
                          value: _vm.pubsDiff[index],
                          callback: function ($$v) {
                            _vm.$set(_vm.pubsDiff, index, $$v)
                          },
                          expression: "pubsDiff[index]",
                        },
                      }),
                    ]
                  },
                },
                {
                  key: "cell(timestamp)",
                  fn: function (ref) {
                    var item = ref.item
                    var index = ref.index
                    return [
                      !index
                        ? _c("div", [_vm._v(_vm._s(item.timestamp))])
                        : _vm._e(),
                      _vm._v(" "),
                      index
                        ? _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "b-tooltip",
                                  rawName: "v-b-tooltip.html.right",
                                  value:
                                    "New Sessions: " +
                                    item.new_sessions +
                                    "<br />Dropped Sessions: " +
                                    item.dropped_sessions +
                                    "<br />Updated Sessions: " +
                                    item.updated_sessions +
                                    "<br />New Assignments: " +
                                    item.new_assignments +
                                    "<br />Dropped Assignments: " +
                                    item.dropped_assignments +
                                    "<br />Updated Assignments: " +
                                    item.updated_assignments,
                                  expression:
                                    "`New Sessions: ${item.new_sessions}<br />Dropped Sessions: ${item.dropped_sessions}<br />Updated Sessions: ${item.updated_sessions}<br />New Assignments: ${item.new_assignments}<br />Dropped Assignments: ${item.dropped_assignments}<br />Updated Assignments: ${item.updated_assignments}`",
                                  modifiers: { html: true, right: true },
                                },
                              ],
                            },
                            [_vm._v(_vm._s(item.timestamp))]
                          )
                        : _vm._e(),
                    ]
                  },
                },
                {
                  key: "cell(sent_external)",
                  fn: function (ref) {
                    var index = ref.index
                    var item = ref.item
                    return [
                      index
                        ? _c("b-form-checkbox", {
                            attrs: { switch: "", checked: item.sent_external },
                            on: {
                              change: function ($event) {
                                return _vm.patchSentExternal(item, $event)
                              },
                            },
                          })
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _vm.currentSettings.env !== "production"
              ? _c(
                  "div",
                  [
                    _c(
                      "b-button",
                      {
                        attrs: { variant: "danger" },
                        on: {
                          click: function ($event) {
                            return _vm.resetPubs()
                          },
                        },
                      },
                      [_vm._v("Reset Publish for Testing")]
                    ),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        "THIS DELETES ALL THE PUBLISHED DATA AND YOU CAN'T EVER GET IT BACK"
                      ),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "plano-modal",
        {
          attrs: { id: "confirm-draft-modal", "no-close-on-backdrop": "" },
          on: {
            cancel: function ($event) {
              return _vm.cancelDraft()
            },
            close: function ($event) {
              return _vm.cancelDraft()
            },
            ok: function ($event) {
              return _vm.confirmDraft()
            },
          },
          scopedSlots: _vm._u([
            {
              key: "modal-title",
              fn: function () {
                return [_vm._v("Publish Draft Schedule Confirmation")]
              },
              proxy: true,
            },
          ]),
        },
        [_vm._v("\n    " + _vm._s(_vm.SCHEDULE_DRAFT_CONFIRM_MESSAGE) + "\n  ")]
      ),
      _vm._v(" "),
      _c(
        "plano-modal",
        {
          attrs: { id: "confirm-firm-modal", "no-close-on-backdrop": "" },
          on: {
            cancel: function ($event) {
              return _vm.cancelFirm()
            },
            close: function ($event) {
              return _vm.cancelFirm()
            },
            ok: function ($event) {
              return _vm.confirmFirm()
            },
          },
          scopedSlots: _vm._u([
            {
              key: "modal-title",
              fn: function () {
                return [_vm._v("Publish Firm Schedule Confirmation")]
              },
              proxy: true,
            },
          ]),
        },
        [_vm._v("\n    " + _vm._s(_vm.SCHEDULE_FIRM_CONFIRM_MESSAGE) + "\n  ")]
      ),
      _vm._v(" "),
      _c(
        "plano-modal",
        {
          attrs: { id: "confirm-publish" },
          on: {
            ok: function ($event) {
              return _vm.publishdSchedule()
            },
          },
          scopedSlots: _vm._u([
            {
              key: "modal-title",
              fn: function () {
                return [_vm._v("Publish Schedule To Public Confirmation")]
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm._v(
            "\n    This will publish the schedule and make the current version of it available to external sources. This action is\n    irreversible and will bring the server down for a short time. Please double check that you wish to perform this action.\n  "
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }