var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("table-vue", {
        ref: "person-sync-table",
        attrs: {
          model: _vm.model,
          columns: _vm.columns,
          stateName: "person-sync-table-search-state",
          showControls: false,
          stickyHeader: "450px",
          showBottomControls: false,
        },
        scopedSlots: _vm._u([
          {
            key: "left-controls",
            fn: function (ref) {
              var total = ref.total
              return [
                _c("div", [
                  _c("div", [
                    _vm._v("Last completed full sync: " + _vm._s(_vm.lastSync)),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v("People with potential matches: "),
                    _c("strong", [_vm._v(_vm._s(total))]),
                  ]),
                ]),
                _vm._v(" "),
                _c("magical-reload", {
                  staticClass: "ml-2 align-self-start",
                  attrs: { reloadAction: _vm.reload },
                }),
              ]
            },
          },
          {
            key: "cell(primary_email)",
            fn: function (ref) {
              var item = ref.item
              return [
                item.primary_email
                  ? _c(
                      "tooltip-overflow",
                      { attrs: { title: item.primary_email.email } },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(item.primary_email.email) +
                            "\n        "
                        ),
                      ]
                    )
                  : _vm._e(),
              ]
            },
          },
          {
            key: "head(registration_sync_data)",
            fn: function (ref) {
              var label = ref.label
              return [
                _c("div", { staticClass: "d-flex justify-content-between" }, [
                  _c("span", [_vm._v(_vm._s(label))]),
                  _vm._v(" "),
                  _c("span", { staticClass: "mr-3 pr-3" }, [_vm._v("Actions")]),
                ]),
              ]
            },
          },
          {
            key: "cell(registration_sync_data)",
            fn: function (ref) {
              var item = ref.item
              return [
                _c(
                  "ol",
                  _vm._l(
                    item.registration_sync_data,
                    function (reg_data, _, index) {
                      return _c("li", { key: reg_data.id }, [
                        index !== 0
                          ? _c("div", {
                              staticClass: "w-75 my-3",
                              staticStyle: {
                                "border-bottom": "1px solid black",
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "d-flex justify-content-between" },
                          [
                            _c(
                              "div",
                              [
                                _c("display-sync-data", {
                                  attrs: { regData: reg_data },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex flex-column justify-content-center mr-3",
                              },
                              [
                                _c(
                                  "b-button",
                                  {
                                    attrs: { variant: "primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.assistedMatch(
                                          reg_data.reg_id,
                                          item.id
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("Match")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "mt-2",
                                    attrs: { variant: "primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.dismissMatch(
                                          reg_data.reg_id,
                                          item.id
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("Dismiss")]
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ])
                    }
                  ),
                  0
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }