var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("table-vue", {
    ref: "surveys-table",
    attrs: {
      defaultSortBy: "surveys.name",
      model: "survey",
      defaultFilter: _vm.defaultFilter,
      columns: _vm.columns,
    },
    on: { new: _vm.onNew },
    scopedSlots: _vm._u([
      {
        key: "cell(description)",
        fn: function (ref) {
          var item = ref.item
          return [
            _c("tooltip-overflow", { attrs: { title: item.description } }, [
              _vm._v(_vm._s(item.description)),
            ]),
          ]
        },
      },
      {
        key: "cell(publishedOn)",
        fn: function (ref) {
          var item = ref.item
          return [
            item.public
              ? _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "b-tooltip",
                        rawName: "v-b-tooltip",
                        value: { title: item.published_on },
                        expression: "{title: item.published_on}",
                      },
                    ],
                  },
                  [_vm._v(_vm._s(new Date(item.published_on).toLocaleString()))]
                )
              : _vm._e(),
          ]
        },
      },
      {
        key: "cell(updated_by)",
        fn: function (ref) {
          var item = ref.item
          return [
            _c(
              "tooltip-overflow",
              {
                attrs: {
                  title: item.updated_by ? item.updated_by.name : "?????",
                },
              },
              [_vm._v(_vm._s(item.updated_by ? item.updated_by.name : "?????"))]
            ),
          ]
        },
      },
      {
        key: "cell(preview)",
        fn: function (ref) {
          var item = ref.item
          return [
            _c(
              "a",
              { attrs: { href: _vm.previewLink(item), target: "_blank" } },
              [_vm._v("Preview")]
            ),
          ]
        },
      },
      {
        key: "cell(surveyLink)",
        fn: function (ref) {
          var item = ref.item
          return [
            !item.unassigned
              ? _c("small", [_c("i", [_vm._v("This survey is not anonymous")])])
              : _vm._e(),
            _vm._v(" "),
            item.unassigned
              ? _c(
                  "a",
                  { attrs: { href: _vm.surveyLink(item), target: "_blank" } },
                  [_vm._v(_vm._s(_vm.surveyLink(item)))]
                )
              : _vm._e(),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }