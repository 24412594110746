var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-overlay",
    { attrs: { show: _vm.loadingEmails, rounded: "sm" } },
    [
      !_vm.disabled
        ? _c(
            "div",
            { staticClass: "tag-input" },
            [
              _vm._l(_vm.valid_tags, function (tag, index) {
                return _c(
                  "div",
                  { key: tag, staticClass: "badge badge-secondary p-form-tag" },
                  [
                    _vm._v("\n      " + _vm._s(tag) + "\n      "),
                    _c(
                      "span",
                      {
                        staticClass: "p-form-tag-remove",
                        on: {
                          click: function ($event) {
                            return _vm.removeValidTag(index)
                          },
                        },
                      },
                      [_vm._v("x")]
                    ),
                  ]
                )
              }),
              _vm._v(" "),
              _vm._l(_vm.invalid_tags, function (tag, index) {
                return _c(
                  "div",
                  { key: tag, staticClass: "badge badge-danger p-form-tag" },
                  [
                    _vm._v("\n      " + _vm._s(tag) + "\n      "),
                    _c(
                      "span",
                      {
                        staticClass: "p-form-tag-remove",
                        on: {
                          click: function ($event) {
                            return _vm.removeInvalidTag(index)
                          },
                        },
                      },
                      [_vm._v("x")]
                    ),
                  ]
                )
              }),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.newTag,
                    expression: "newTag",
                  },
                ],
                staticClass: "p-form-new-tag",
                attrs: { type: "text", placeholder: "Enter email(s) ..." },
                domProps: { value: _vm.newTag },
                on: {
                  blur: _vm.addTags,
                  paste: _vm.pasteTags,
                  keydown: [
                    function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.addTags.apply(null, arguments)
                    },
                    function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        $event.keyCode !== 188
                      ) {
                        return null
                      }
                      $event.stopPropagation()
                      return _vm.addTags.apply(null, arguments)
                    },
                    function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "delete", [8, 46], $event.key, [
                          "Backspace",
                          "Delete",
                          "Del",
                        ])
                      ) {
                        return null
                      }
                      $event.stopPropagation()
                      return _vm.removeLastTag.apply(null, arguments)
                    },
                  ],
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.newTag = $event.target.value
                  },
                },
              }),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.disabled
        ? _c(
            "div",
            { staticClass: "tag-input" },
            [
              _vm._l(_vm.valid_tags, function (tag, index) {
                return _c(
                  "div",
                  { key: tag, staticClass: "badge badge-secondary p-form-tag" },
                  [_vm._v("\n      " + _vm._s(tag) + "\n    ")]
                )
              }),
              _vm._v(" "),
              _vm._l(_vm.invalid_tags, function (tag, index) {
                return _c(
                  "div",
                  { key: tag, staticClass: "badge badge-danger p-form-tag" },
                  [_vm._v("\n      " + _vm._s(tag) + "\n    ")]
                )
              }),
            ],
            2
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }