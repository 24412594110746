var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("bulk-edit-modal", {
        attrs: { title: "Bulk Edit Status", id: "bulk-edit-status" },
        on: { ok: _vm.onSaveMassEdit },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function () {
              return [
                _c(
                  "b-form",
                  [
                    _c("person-con-state-selector", {
                      model: {
                        value: _vm.selectedConState,
                        callback: function ($$v) {
                          _vm.selectedConState = $$v
                        },
                        expression: "selectedConState",
                      },
                    }),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "confirm-default",
            fn: function () {
              return [
                _c("p", [
                  _vm._v(
                    "\n        Please confirm that you want to change the\n        status of " +
                      _vm._s(_vm.editableIds.length) +
                      " " +
                      _vm._s(
                        _vm.editableIds.length == 1 ? "person" : "people"
                      ) +
                      " to '" +
                      _vm._s(_vm.PERSON_CON_STATE[_vm.selectedConState]) +
                      "'\n        "
                  ),
                  _vm.declinedRejected
                    ? _c("span", [
                        _vm._v(
                          "and they will be removed from the below sessions."
                        ),
                      ])
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("people-session-names", {
                  attrs: {
                    declinedRejected: _vm.declinedRejected,
                    ids: _vm.editableIds,
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._v(" "),
      _c(
        "modal-form",
        {
          ref: "add-person-modal",
          attrs: { title: "Add Person" },
          on: { save: _vm.onSave },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function (ref) {
                var ok = ref.ok
                var cancel = ref.cancel
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { variant: "link" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v("Cancel")]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-button",
                    {
                      attrs: { variant: "primary" },
                      on: {
                        click: function ($event) {
                          return ok()
                        },
                      },
                    },
                    [_vm._v("Save")]
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c("person-add", {
            ref: "add-person-form",
            attrs: { "show-buttons": false },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("table-vue", {
        ref: "people-table",
        attrs: {
          defaultSortBy: "people.name",
          model: _vm.model,
          columns: _vm.columns,
          selectMode: "multi",
          stateName: "people-table-search-state",
        },
        on: { new: _vm.onNew },
        scopedSlots: _vm._u([
          {
            key: "alternate-search-title",
            fn: function () {
              return [_vm._v("Seach by Email(s)")]
            },
            proxy: true,
          },
          {
            key: "alternate-search",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "d-flex" },
                  [
                    _c(
                      "b-form-group",
                      { staticClass: "w-100", attrs: { label: "Email(s)" } },
                      [
                        _c("b-form-input", {
                          attrs: { type: "text" },
                          model: {
                            value: _vm.searchEmails,
                            callback: function ($$v) {
                              _vm.searchEmails = $$v
                            },
                            expression: "searchEmails",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "d-flex" },
                  [
                    _c(
                      "b-button",
                      {
                        attrs: { variant: "primary" },
                        on: { click: _vm.onEmailSearch },
                      },
                      [_vm._v("Search")]
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "left-controls",
            fn: function (ref) {
              var editableIds = ref.editableIds
              return [
                _c(
                  "div",
                  [
                    _c(
                      "b-button",
                      {
                        attrs: {
                          variant: "primary",
                          disabled: editableIds.length == 0,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.onEditStates(editableIds)
                          },
                        },
                      },
                      [_vm._v("Edit Status(es)\n        ")]
                    ),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "cell(pronouns)",
            fn: function (ref) {
              var item = ref.item
              return [
                item.pronouns
                  ? _c(
                      "tooltip-overflow",
                      { attrs: { title: item.pronouns } },
                      [
                        _vm._v(
                          "\n        " + _vm._s(item.pronouns) + "\n      "
                        ),
                      ]
                    )
                  : _vm._e(),
              ]
            },
          },
          {
            key: "cell(primary_email)",
            fn: function (ref) {
              var item = ref.item
              return [
                item.primary_email
                  ? _c(
                      "tooltip-overflow",
                      { attrs: { title: item.primary_email.email } },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(item.primary_email.email) +
                            "\n      "
                        ),
                      ]
                    )
                  : _vm._e(),
              ]
            },
          },
          {
            key: "cell(comments)",
            fn: function (ref) {
              var item = ref.item
              return [
                _c("tooltip-overflow", { attrs: { title: item.comments } }, [
                  _vm._v("\n        " + _vm._s(item.comments) + "\n      "),
                ]),
              ]
            },
          },
          {
            key: "cell(current_sign_in_at)",
            fn: function (ref) {
              var item = ref.item
              return [
                item.current_sign_in_at
                  ? _c("span", [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.DateTime.fromISO(
                              item.current_sign_in_at
                            ).toFormat("DDD, t ZZZZ")
                          ) +
                          "\n      "
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                !item.current_sign_in_at
                  ? _c("span", { staticClass: "font-italic text-muted" }, [
                      _vm._v(_vm._s(_vm.PERSON_NEVER_LOGGED_IN)),
                    ])
                  : _vm._e(),
              ]
            },
          },
          {
            key: "cell(draft_approval)",
            fn: function (ref) {
              var item = ref.item
              return [
                _vm.draftSchedule
                  ? _c("div", [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.approved(
                              item.person_schedule_approvals,
                              "draft"
                            )
                          ) +
                          "\n      "
                      ),
                    ])
                  : _c("div", { staticClass: "text-muted text-center" }, [
                      _vm._v(" — "),
                    ]),
              ]
            },
          },
          {
            key: "cell(draft_comments)",
            fn: function (ref) {
              var item = ref.item
              return [
                _vm.draftSchedule
                  ? _c(
                      "div",
                      [
                        _c(
                          "tooltip-overflow-keep-newlines",
                          {
                            attrs: {
                              title: _vm.comments(
                                item.person_schedule_approvals,
                                "draft"
                              ),
                            },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm.comments(
                                    item.person_schedule_approvals,
                                    "draft"
                                  )
                                ) +
                                "\n        "
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  : _c("div", { staticClass: "text-muted text-center" }, [
                      _vm._v(" — "),
                    ]),
              ]
            },
          },
          {
            key: "cell(firm_approval)",
            fn: function (ref) {
              var item = ref.item
              return [
                _vm.firmSchedule
                  ? _c("div", [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.approved(item.person_schedule_approvals, "firm")
                          ) +
                          "\n      "
                      ),
                    ])
                  : _c("div", { staticClass: "text-muted text-center" }, [
                      _vm._v("—"),
                    ]),
              ]
            },
          },
          {
            key: "cell(firm_comments)",
            fn: function (ref) {
              var item = ref.item
              return [
                _vm.firmSchedule
                  ? _c(
                      "div",
                      [
                        _c(
                          "tooltip-overflow-keep-newlines",
                          {
                            attrs: {
                              title: _vm.comments(
                                item.person_schedule_approvals,
                                "firm"
                              ),
                            },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm.comments(
                                    item.person_schedule_approvals,
                                    "firm"
                                  )
                                ) +
                                "\n        "
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  : _c("div", { staticClass: "text-muted text-center" }, [
                      _vm._v(" — "),
                    ]),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }