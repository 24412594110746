var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "d-flex flex-column" }, [
    _c("div", { staticClass: "flex-grow-1 flex-shrink-1 mb-4" }, [
      _c("dl", [
        _c("dt", [
          _vm._v(
            "Maximum number of program items across the duration of the convention"
          ),
        ]),
        _vm._v(" "),
        _c("dd", { staticClass: "ml-2 font-italic" }, [
          _vm._v(_vm._s(_vm.limits[null] || "Not Set")),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "d-flex" }, [
      _c("div", { staticClass: "flex-grow-1 flex-shrink-1 flex-basis-50" }, [
        _c(
          "dl",
          [
            _c("dt", [_vm._v("Selected Times")]),
            _vm._v(" "),
            !_vm.availabilities.length && !_vm.availabilityLoading
              ? _c("dd", { staticClass: "ml-2 font-italic text-muted" }, [
                  _vm._v("None"),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "b-overlay",
              {
                staticStyle: { "min-height": "5rem" },
                attrs: {
                  show: _vm.availabilityLoading,
                  "spinner-variant": "primary",
                },
              },
              _vm._l(_vm.availabilities, function (avail) {
                return _c(
                  "dd",
                  { key: avail, staticClass: "ml-2 font-italic" },
                  [_vm._v(_vm._s(avail))]
                )
              }),
              0
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "flex-grow-1 flex-shrink-1 flex-basis-50" }, [
        _c(
          "dl",
          [
            _c("dt", [_vm._v("Maximum number of program items per day")]),
            _vm._v(" "),
            _c("dd", { staticClass: "ml-2 font-italic mb-4" }, [
              _c(
                "table",
                _vm._l(_vm.dayOptions, function (ref) {
                  var text = ref.text
                  var value = ref.value
                  return _c("tr", { key: text + "-" + value }, [
                    _c("td", [_vm._v(_vm._s(text) + " ")]),
                    _vm._v(" "),
                    _c("td", { staticClass: "pl-3" }, [
                      _vm._v(_vm._s(_vm.limits[value] || "Not Set")),
                    ]),
                  ])
                }),
                0
              ),
            ]),
            _vm._v(" "),
            _c("dt", [_vm._v("Do not schedule against these specific events")]),
            _vm._v(" "),
            _c(
              "b-overlay",
              {
                staticClass: "mb-4",
                staticStyle: { "min-height": "5rem" },
                attrs: {
                  show: _vm.exclusionsLoading,
                  "spinner-variant": "primary",
                },
              },
              [
                _vm._l(_vm.exclusions, function (exclusion) {
                  return _c(
                    "dd",
                    { key: exclusion.id, staticClass: "ml-2 font-italic" },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.exclusionsMap[exclusion.exclusion_id]) +
                          "\n          "
                      ),
                    ]
                  )
                }),
                _vm._v(" "),
                !_vm.exclusions.length
                  ? _c("dd", { staticClass: "ml-2 font-italic text-muted" }, [
                      _vm._v("None Selected"),
                    ])
                  : _vm._e(),
              ],
              2
            ),
            _vm._v(" "),
            _c("dt", [_vm._v("Other scheduling considerations")]),
            _vm._v(" "),
            _c("dd", { staticClass: "ml-2 font-italic keep-format" }, [
              _vm._v(_vm._s(_vm.selected.availability_notes)),
            ]),
            _vm._v(" "),
            !_vm.selected.availability_notes
              ? _c("dd", { staticClass: "ml-2 font-italic text-muted" }, [
                  _vm._v("Not Specified"),
                ])
              : _vm._e(),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }