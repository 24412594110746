var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "d-flex flex-row" }, [
    _c("div", {}, [
      _vm._v("\n    Availability"),
      _c("br"),
      _vm._v(" "),
      _c("small", { staticClass: "text-muted" }, [
        _vm._v(_vm._s(_vm.AVAILABILITY_DESCRIPTION)),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "d-flex flex-row" },
        _vm._l(_vm.days, function (day) {
          return _c("availability-time-picker", {
            key: day,
            ref: "day-" + day,
            refInFor: true,
            attrs: {
              day: day,
              firstDay: day === _vm.days[0],
              showScrollBar: day === _vm.days[_vm.days.length - 1],
              twelveHour: true,
              dayEvents: _vm.dayEvents,
              timezone: _vm.timezone,
            },
            on: {
              change: _vm.onChange,
              update: _vm.onUpdate,
              delete: _vm.onDelete,
            },
          })
        }),
        1
      ),
      _vm._v(" "),
      _c("small", { staticClass: "text-muted" }, [
        _vm._v("* times shown in " + _vm._s(_vm.display_timezone)),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "p-2 selected-availabilities" },
      [
        _c("p", [_vm._v("Selected Times")]),
        _vm._v(" "),
        _vm._l(_vm.sortedAvailabilities, function (avail) {
          return _c("div", { key: avail.id }, [
            _vm._v(
              "\n      " +
                _vm._s(_vm.formatLocaleDate(avail.start, { weekday: "long" })) +
                "\n      " +
                _vm._s(
                  _vm.formatLocaleDate(avail.start, _vm.DateTime.TIME_SIMPLE)
                ) +
                " -\n      " +
                _vm._s(
                  _vm.formatLocaleDate(avail.end, {
                    hour: "numeric",
                    minute: "2-digit",
                    timeZoneName: "short",
                  })
                ) +
                "\n    "
            ),
          ])
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }