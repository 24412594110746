var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "detail container" }, [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-12" },
        [
          _c(
            "b-button",
            { attrs: { disabled: _vm.editable }, on: { click: _vm.edit } },
            [_vm._v("Edit")]
          ),
          _vm._v(" "),
          _c(
            "b-button",
            { attrs: { disabled: !_vm.editable }, on: { click: _vm.save } },
            [_vm._v("Save")]
          ),
          _vm._v(" "),
          _c("h2", [_vm._v("Basic Information")]),
          _vm._v(" "),
          _c(
            "b-form-group",
            { attrs: { label: "Title" } },
            [
              _c("b-form-input", {
                attrs: { disabled: !_vm.editable },
                model: {
                  value: _vm.selected.title,
                  callback: function ($$v) {
                    _vm.$set(_vm.selected, "title", $$v)
                  },
                  expression: "selected.title",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-form-group",
            { attrs: { label: "Description" } },
            [
              _c("plano-editor", {
                attrs: {
                  id: "description-text",
                  type: "classic",
                  disabled: !_vm.editable,
                },
                model: {
                  value: _vm.selected.description,
                  callback: function ($$v) {
                    _vm.$set(_vm.selected, "description", $$v)
                  },
                  expression: "selected.description",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-form-checkbox",
            {
              attrs: { disabled: !_vm.editable },
              model: {
                value: _vm.selected.open_for_interest,
                callback: function ($$v) {
                  _vm.$set(_vm.selected, "open_for_interest", $$v)
                },
                expression: "selected.open_for_interest",
              },
            },
            [_vm._v("\n        Open for Interest\n      ")]
          ),
          _vm._v(" "),
          _c(
            "b-form-group",
            { attrs: { label: "Interest Instructions" } },
            [
              _c("plano-editor", {
                attrs: {
                  id: "interest-instructions-text",
                  type: "classic",
                  disabled: !_vm.editable,
                },
                model: {
                  value: _vm.selected.instructions_for_interest,
                  callback: function ($$v) {
                    _vm.$set(_vm.selected, "instructions_for_interest", $$v)
                  },
                  expression: "selected.instructions_for_interest",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-form-group",
            { attrs: { label: "Tags" } },
            [
              _c("model-tags", {
                attrs: {
                  taggable: true,
                  model: "tag",
                  field: "name",
                  fieldOnly: "true",
                  filter:
                    '{"op":"all","queries":[["taggings.taggable_type", "=", "Session"]]}',
                  disabled: !_vm.editable,
                },
                model: {
                  value: _vm.session_tags,
                  callback: function ($$v) {
                    _vm.session_tags = $$v
                  },
                  expression: "session_tags",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-form-group",
            { attrs: { label: "Areas" } },
            [
              _c("model-select", {
                attrs: {
                  model: "area",
                  field: "name",
                  multiple: true,
                  disabled: !_vm.editable,
                },
                model: {
                  value: _vm.session_areas,
                  callback: function ($$v) {
                    _vm.session_areas = $$v
                  },
                  expression: "session_areas",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }