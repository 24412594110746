var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pill-display" },
    [
      _c(
        "p",
        [
          _vm._v(_vm._s(_vm.label) + " "),
          _c("edit-button", {
            directives: [
              {
                name: "b-modal",
                rawName: "v-b-modal:[modalId]",
                arg: _vm.modalId,
              },
            ],
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm._l(_vm.value, function (item) {
        return _c("span", { key: item, class: _vm.clazz }, [
          _vm._v(_vm._s(_vm.formatter(item))),
        ])
      }),
      _vm._v(" "),
      !_vm.value.length
        ? _c("span", { staticClass: "text-muted font-italic" }, [
            _vm._v(_vm._s(_vm.SESSION_NO_TAGS(_vm.label))),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "edit-modal",
        {
          attrs: { id: _vm.modalId, title: _vm.modalTitle },
          on: {
            ok: function ($event) {
              return _vm.$emit("input", _vm.mutableValue)
            },
            hide: function ($event) {
              return _vm.clearValue()
            },
            show: function ($event) {
              return _vm.initValue()
            },
          },
        },
        [
          _c("b-form-select", {
            attrs: { options: _vm.modalOptions, multiple: true, size: "4" },
            model: {
              value: _vm.mutableValue,
              callback: function ($$v) {
                _vm.mutableValue = $$v
              },
              expression: "mutableValue",
            },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }